import React from "react";
import PageHelmet from "../PageHelmet";

import "./Main.css";
import GiveGift from "../../images/GiveGift.webp";

import MainReviews from "../MainReviews/MainReviews";
import MainSlider from "../MainSlider/MainSlider";
import MarketplaceLink from "../MarketplaceLink";
import Categories from "../Categories/Categories";
import ProductionRequestForm from "../ProductionRequestForm/ProductionRequestForm";
import ImageGrid from "../ImageGrid/ImageGrid";
import StepsSection from "../StepsSection/StepsSection";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";

const Main = ({
  headerHeight,
  handleGoGoal,
  handleGoPodarok,
  handleVkClick,
  handleTelegramClickFJ,
  handleGoTastes,
  handleGoStore,
  handleGoContractManufacturing,
}) => {
  return (
    <nav className="main" id="main" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title="Fit and Joy - Натуральные добавки для здоровья"
        description="Натуральные добавки Fit and Joy для красоты и здоровья. Создано в Японии, доступно в России."
        keywords="Fit and Joy, японские добавки, здоровье, витамины, натуральные продукты"
        image="https://japanformula.com/main-image.webp"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/about/`}
      />

      <MainSlider />

      <Categories />
      <div className="main__wrapper">
        <div className="main__block">
          <h2 className="main__subtitle">
            Достигайте своих целей с Fit and Joy!
          </h2>
          <p className="main__text">
            Выберите продукцию, которая поможет вам прийти к желаемым
            результатам – будь то энергия, стройность, красота или сила.
          </p>
          <button className="btn btn__blue" onClick={handleGoGoal}>
            Выбрать по цели
          </button>
        </div>

        <div className="main__block">
          <h2 className="main__subtitle">Ваш вкус – ваш выбор!</h2>
          <p className="main__text">
            Любите ягодные, шоколадные или нейтральные вкусы? У нас есть
            идеальный вариант для вас!
          </p>
          <button className="btn btn__blue" onClick={handleGoTastes}>
            Выбрать по вкусу
          </button>
        </div>

        <div className="main__block">
          <h2 className="main__subtitle">
            Присоединяйтесь к нашему сообществу!
          </h2>
          <p className="main__text">
            Получайте полезные советы, вдохновение и эксклюзивные предложения в
            наших соцсетях.
          </p>
          <ul className="main__list">
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                alt="Иконка телеграмм"
                onClick={handleTelegramClickFJ}
              />
            </li>
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                alt="Иконка VK"
                onClick={handleVkClick}
              />
            </li>
          </ul>
        </div>
        <div className="main__block">
          <div className="main__image-container">
            <img
              className="main__container-img"
              src={GiveGift}
              alt="Подарок от Fit and Joy"
            />

            <button className="main__btn" onClick={handleGoPodarok}>
              ПОЛУЧИТЬ ПОДАРОК
            </button>
          </div>
        </div>
        <div className="main__block">
          <h2 className="main__subtitle hover" onClick={handleGoStore}>
            Где купить?
          </h2>
          <p className="main__text">
            Продукция Fit and Joy доступна в ведущих магазинах и маркетплейсах.
            Найдите удобный способ покупки!
          </p>
          <div className="main__marketplace-list">
            {cardsMarketplaces.map((marketplace) => (
              <MarketplaceLink key={marketplace.name} href={marketplace.url}>
                <img
                  className="main__marketplace-item"
                  src={marketplace.img}
                  alt={marketplace.name}
                  title={marketplace.name}
                />
              </MarketplaceLink>
            ))}
          </div>
        </div>
        <div className="contract__container">
          <h1 className="tastes__title">Приглашаем к сотрудничеству</h1>
          <p className="contract__subtitle">Fit and Joy предлагает </p>
          <button
            className="faq__link"
            type="button"
            onClick={handleGoContractManufacturing}
          >
            услуги контрактного производства
          </button>

          <p className="contract__subtitle">
            {" "}
            БАДов, витаминов и продуктов спортивного питания.
          </p>

          <div className="contract__benefits">
            <h2 className="contract__section-title">Что мы предлагаем:</h2>
            <ul className="contract__list">
              <li>🔬 Современное сертифицированное производство</li>
              <li>📦 Упаковка под вашим брендом</li>
              <li>🧪 Разработка формул под ключ</li>
              <li>✅ Контроль качества на каждом этапе</li>
              <li>🚚 Быстрая доставка и логистика</li>
              <li>📈 Поддержка и консультации по выводу продукта на рынок</li>
            </ul>
          </div>
          <div className="contract__cta">
            <h2 className="tastes__title">Станьте нашим партнером</h2>
            <p>
              <br /> Мы открыты для сотрудничества с компаниями, стартапами и
              частными брендами.
              <br /> У вас уже есть идея или готовая формула? <br />
              Или только план?
              <br />
              <br />
              <b>Мы поможем вам в реализации.</b>
            </p>
            <p>
              {" "}
              <br />
              Оставьте заявку — и мы свяжемся с вами в ближайшее время.
              <br />
            </p>
            <ProductionRequestForm />
          </div>
        </div>
        <MainReviews />
        <StepsSection />
        <ImageGrid />
      </div>
    </nav>
  );
};

export default Main;
