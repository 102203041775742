import React, { useEffect, useState } from "react";
import PageHelmet from "../PageHelmet";
import "./FAQ.css";
import ImageGrid from "../ImageGrid/ImageGrid";

const FAQ = ({ headerHeight, handleTelegramClickSupport }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      category: "Общие вопросы",
      id: "general-questions",
      questions: [
        {
          question: "Есть ли в комплекте мерная ложечка?",
          answer: "Да, мерная ложка идет в комплекте.",
        },
        {
          question: "Где найти мерную ложку?",
          answer: "Она находится внутри банки со смесью.",
        },
        {
          question: "Обязательно всегда нужно отмерять мерной ложкой смесь?",
          answer: "Да, мы рекомендуем разводить смесь, используя мерную ложку.",
        },
        {
          question: "Какие сроки годности на продукцию в форме порошка?",
          answer:
            "Дата производства указана либо на дне баночки, либо на крышке, с наружной стороны",
        },
        {
          question:
            "Какой срок годности у продукта в сухой форме, когда я вскрою упаковку?",
          answer:
            "Срок годности нашего продукта, независимо открыт он или же закрыт, составляет 2 года с момента производства.",
        },
        {
          question:
            "Банка защищена от просыпания во время доставки? До меня дойдет товар целости?",
          answer:
            "Да, конечно. Банка упакована надежно, на самой банке установлена специальная наклейка для защиты от вскрытия, а также под крышкой банка запечатана в фольгу.",
        },
        {
          question:
            "Почему вы пишете, что в ложке 2,5 гр, когда в ней написано 5 гр?",
          answer:
            "В самой ложке расчерчено, сколько нужно порошка на 1,25гр / на 2,5гр / на 5гр.",
        },
        {
          question: "Какая страна является производителем данной продукции?",
          answer: "Товар производится в России.",
        },
        {
          question: "Что за вкус Роки Роуд?",
          answer: "Это такой популярный десерт в англоговорящем мире.",
        },
      ],
    },
    {
      category: "Пищевая продукция",
      id: "food-products",
      questions: [
        {
          question: "Помогут ли детокс-напитки похудеть?",
          answer:
            "Да, помогут. Но для максимального эффекта, рекомендуем заниматься спортом и вести активный образ жизни.",
        },
        {
          question: "Сколько углеводов в одной порции детокса?",
          answer: "В 5 гр продукта 3,6 гр углеводов",
        },
        {
          question: "Какой вкус самый кислый и менее сладкий в линейке детокс?",
          answer:
            "Рекомендуем попробовать вкус Ананас, он больше всего подходит к вашему запросу.",
        },

        {
          question: "Какая страна происхождения коллагена?",
          answer: "Страна происхождения коллагена — Россия",
        },
        {
          question: "Сколько коллагена в одной порции?",
          answer: "4000 мг коллагена в одной порции",
        },
        {
          question:
            "На поверхности появляются нерастворимые частицы зеленого цвета. Что это?",
          answer:
            "Нерастворимый зеленый (черный/синий) осадок это спирулина. На основе спирулины производят препараты, которые помогают от многих заболеваний, в том числе и способствуют похудению. Наш продукт входит в их число.",
        },
        {
          question: "Какая разница между детокс и слим-напитками?",
          answer:
            "Детокс это дренажный напиток направленный на выведение лишней жидкости из организма. Слим-эффект это протеиновый коктейль, им можно заменять прием пищи.",
        },
        {
          question:
            "Капсулы L-карнитина надо принимать каждый день или только в дни тренировок?",
          answer:
            "Рекомендуем принимать каждый день не зависимо от количества тренировок.",
        },
        {
          question:
            "Капсулы L-карнитина можно принимать только при занятии спортом?",
          answer:
            "Нет, вы можете принимать продукт без занятий спортом, но максимальный результат будет только при совмещении с тренировками.",
        },
        {
          question: "Можно ли разводить коллаген в молоке или кефире?",
          answer: "Да вы можете разводить коллаген в любой молочной продукции.",
        },
        {
          question:
            "Фитококтейль можно пить только женщинам или мужчинам тоже подойдёт?",
          answer:
            "Фитококтейль поможет сбросить вес как женскому организму, так и мужскому.",
        },

        {
          question: "Можно ли растворять порошок в кипятке?",
          answer:
            "Растворять напиток в кипятке мы не рекомендуем, хотя бы просто в теплой.",
        },
        {
          question: "Сколько баночек детокс-напитка нужно на один курс?",
          answer: "На месяц Вам потребуется 2 баночки.",
        },
        {
          question: "Можно ли давать детям детокс и слим напитки?",
          answer:
            "Возрастные ограничения продукта 3+. Также не рекомендуется детям младше 16 лет без консультации с врачом.",
        },
        {
          question: "Долго ли надо принимать коллаген?",
          answer:
            "Минимальный рекомендованный курс приема составляет 60-90 дней",
        },
        {
          question: "Нужно ли делать перерывы при употреблении коллагена?",
          answer: "Такой необходимости нет.",
        },
        {
          question: "Можно ли коллаген вместе с детоксом сочетать по очереди?",
          answer:
            "Да, можно, состав этих продуктов разный и нацелены они на разные функции в организме. Наш бренд специально разрабатывал продукты, которые полностью совместимы друг с другом",
        },
        {
          question: "Принимать детокс нужно до или после еды?",
          answer: "Вы можете принимать как до, так и после еды",
        },
        {
          question: "Какое КБЖУ у батончика со вкусом 'Двойной шоколад'?",
          answer:
            "На 40 гр (один батончик): Белки: 13,2 г/g Жиры: 3,8 г/g Углеводы: 4 г/g",
        },
        {
          question: "Какой состав у кондитерской глазури?",
          answer:
            "Состав кондитерской глазури: Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество.",
        },
        {
          question: "Какая калорийность у кокосового батончика на 100 гр?",
          answer:
            "В 100 гр 399 ккал. Калорийность может отличаться в зависимости от вкуса. Данное количество ккал соответствует вкусу 'Двойной шоколад'.",
        },
        {
          question:
            "Можно разводить суточную дозу витамина С в большем объеме воды?",
          answer:
            "Да конечно! Ничего страшного в этом нет, просто вкус напитка будет не таким насыщенным, но самое главное - витамин С, ваш организм получит в полном объеме.",
        },
        {
          question:
            "Какого типа и происхождения коллаген используется в продукции?",
          answer:
            "В продукции используется коллаген 1 и 3 типа, животного и морского происхождения.",
        },
        {
          question: "Сколько калорий в стакане-детокс напитка?",
          answer:
            "Пищевая энергетическая ценность в 1 порции (5гр продукта - 15 ккал). Однако уточняем, что смесь нужно размешать в 200гр жидкости. У готово продукта с учетом того, что вы мешаете с водой, калорийность составляет ~ 7ккал./100мл .",
        },

        {
          question: "Можно пить на курсе разные вкусы?",
          answer:
            "Да, конечно, какой вкус принимать - решаете только Вы, во всех вкусах содержатся все необходимые и полезные вещества.",
        },
        {
          question: "Если сахар в составе детокс-напитков?",
          answer: "Сахара в составе нет.",
        },
        {
          question: "Не несет ли себе вреда детокс-напиток?",
          answer: "Данный продукт полностью безопасен для организма.",
        },
        {
          question:
            "Используется ли молоко и молочные продукты в slim-напитках?",
          answer: "В данной линейке продукции используется сухое молоко.",
        },
        {
          question:
            "Ваш детокс-напиток не вымывает из организма витамины и минералы?",
          answer: "Нет, не вымывает.",
        },
        {
          question: "Какие вещества в составе Hair Vitamin преобладают?",
          answer:
            "В нашем продукте Hair Vitamin много полезных элементов, а именно биотин и коллаген, витамин E, D3, B3, C, B1, B6, и витаминно-минеральный премикс.",
        },
        {
          question:
            "Чем отличается ваша аскорбиновая кислота в порошке от таблетированной?",
          answer:
            "Аскорбиновая кислота в разведенном виде лучше усваивается организмом, чем таблетированная.",
        },
        {
          question: "В какое время надо пить аскорбиновую кислоту?",
          answer:
            "Аскорбиновую кислоту надо принимать 1 раз в день после еды, независимо от времени дня.",
        },
        {
          question: "Сколько миллиграмм карнитина на в капсуле?",
          answer: "В одной капсуле 425 мг L-карнитина.",
        },
      ],
    },
    {
      category: "Противопоказания и рекомендации по применению",
      id: "recommendations",
      questions: [
        {
          question: "Какие есть противопоказания?",
          answer:
            "Противопоказания к приему нашей продукции только индивидуальная непереносимость компонентов входящих в состав. Для получения более точного ответа на данный вопрос обратитесь к лечащему врачу.",
        },
        {
          question: "Можно ли принимать slim-эффект как перекус?",
          answer:
            "Да, можно, напиток дает насыщение, но в то же время содержит минимальное количество калорий.",
        },
        {
          question: "Какое возрастное ограничение для протеинового батончиков?",
          answer:
            "Возрастные ограничения продукта 3+. Также не рекомендуется детям младше 16 лет без консультации с врачом.",
        },
        {
          question: "Есть ли побочные эффекты от пищевых добавок в капсулах?",
          answer:
            "У нашего продукта нет противопоказаний, за исключением индивидуальной непереносимости компонентов, входящих в состав.",
        },
        {
          question: "Можно ли увеличить рекомендуемую дозу капсул L-карнитина?",
          answer:
            "Рекомендуемая норма приема 1 капсула в день, но вы можете увеличить количество принимаемого продукта, проконсультировавшись со специалистом, отвечающим за составление вашего спортивного питания",
        },
        {
          question: "Возможно ли употребление продукции во время беременности?",
          answer:
            "Для употребления нашей продукции в период беременности и лактации рекомендуем обратиться к вашему лечащему врачу. Однако уточняем, что продукт прошел лабораторные испытания и полностью безопасен.",
        },
        {
          question: "Как долго разводится коллаген?",
          answer:
            "Коллаген разводится очень быстро, для лучшего размешивания рекомендуем использовать капучинатор или шейкер",
        },
        {
          question:
            "Какое количество витамина С в одной порции порошкообразного коллагена?",
          answer: "100 мг витамина C в одной порции.",
        },
        {
          question: "Можно ли принимать капсулы L-карнитина натощак?",
          answer: "Лучше принимать во время или после еды.",
        },
        {
          question: "Какой курс детокс-напитка?",
          answer:
            "Минимальный рекомендованный курс приема составляет 60-90 дней.",
        },
        {
          question:
            "Ничего страшного, что я сначала пила по одному разу в день, теперь начала по два?",
          answer:
            "Все нормально, вы не вышли за пределы нормы, принимать дренажный напиток следует 1-2 раза в первой половине дня.",
        },
        {
          question: "В период ГВ можно пить дренажные напитки?",
          answer:
            "Составлением рациона во время беременности и грудного вскармливания занимается специализированный врач или диетолог. Однако уточняем, что продукт прошел лабораторные испытания и полностью безопасен.",
        },
      ],
    },
    {
      category: "Контрактное производство",
      id: "contract-manufacturing",
      questions: [
        {
          question: "Что такое контрактное производство?",
          answer:
            "Это услуга по созданию продукции под вашей торговой маркой. Мы берём на себя полный цикл: от рецептуры до упаковки.",
        },
        {
          question: "Как начать сотрудничество по контрактному производству?",
          answerJSX: (
            <>
              Просто перейдите на{" "}
              <a
                href="/kontraktnoe-proizvodstvo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>страницу контрактного производства</b>
              </a>{" "}
              и оставьте заявку. Мы свяжемся с вами в ближайшее время.
            </>
          ),
        },
        {
          question: "Можно ли выпустить продукт по моему рецепту?",
          answer:
            "Да, мы можем использовать как вашу рецептуру, так и предложить собственные наработки с доказанной эффективностью.",
        },
        {
          question: "Есть ли минимальный объем заказа?",
          answer:
            "Минимальный объем обсуждается индивидуально и зависит от типа продукции. Мы подходим гибко к каждому клиенту.",
        },
      ],
    },
    {
      category: "Сотрудничество с блогерами",
      id: "influencer-collab",
      questions: [
        {
          question: "Как стать амбассадором Fit and Joy?",
          answer:
            "Если вы ведёте активный блог и ваша аудитория интересуется темой здоровья и питания — оставьте заявку, и мы свяжемся с вами!",
        },
        {
          question: "Предоставляете ли вы продукцию на обзор?",
          answer:
            "Да, мы сотрудничаем с блогерами на бартерной основе, а также предлагаем индивидуальные условия — всё обсуждается лично.",
        },
        {
          question: "Какой формат сотрудничества возможен?",
          answer:
            "Обзоры продукции, stories, reels, прямые эфиры, розыгрыши — мы всегда готовы к креативным форматам и новым идеям.",
        },
        {
          question: "Куда написать по вопросам сотрудничества?",
          answerJSX: (
            <>
              Напишите в{" "}
              <a
                href="https://t.me/loveourclients"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b> Telegram</b>
              </a>{" "}
              или отправьте{" "}
              <a href="mailto:info@usafitandjoy.com">
                {" "}
                📩 <b> письмо</b>
              </a>{" "}
              с темой 'Сотрудничество блог'
            </>
          ),
        },
      ],
    },
    {
      category: "Доставка",
      id: "delivery",
      questions: [
        {
          question: "Как приобрести вашу продукцию?",
          answerText:
            "Нашу продукцию можно приобрести на маркетплейсах. Список платформ доступен на странице 'Где купить'.",
          answerJSX: (
            <>
              Нашу продукцию можно приобрести на маркетплейсах. Список платформ
              доступен на главной странице и странице{" "}
              <a
                href="https://usafitandjoy.com/buy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Где купить</b>
              </a>
              .
            </>
          ),
        },
      ],
    },
  ];

  return (
    <section
      className="faq"
      id="faq"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="FAQ — Вопросы и ответы о продукции Fit and Joy"
        description="Часто задаваемые вопросы о спортивном питании, БАДах и продукции Fit and Joy. Узнайте о составе, способах приема и особенностях товаров."
        keywords="FAQ, вопросы и ответы, спортивное питание, БАДы, детокс, протеин, Fit and Joy"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/faq`}
      />

      <div className="faq__wrapper">
        <div className="faq__container">
          <h1 className="faq__title">Часто задаваемые вопросы</h1>
          <p className="faq__text">
            Мы собрали ответы на самые популярные вопросы. Если не нашли нужную
            информацию,
            <button className="faq__link" onClick={handleTelegramClickSupport}>
              свяжитесь с нами
            </button>
          </p>

          {faqData.map((category, catIndex) => (
            <div key={catIndex} className="faq__category">
              <h2 id={category.id} className="faq__category-title faq__title">
                {category.category}
              </h2>
              {category.questions.map((item, index) => {
                const isActive = activeIndex === `${catIndex}-${index}`;
                return (
                  <div key={index} className="faq__item">
                    <button
                      className={`faq__question ${isActive ? "active" : ""}`}
                      onClick={() => toggleAccordion(`${catIndex}-${index}`)}
                    >
                      {item.question}
                      <span
                        className={`faq__arrow ${isActive ? "open" : ""}`}
                      ></span>
                    </button>
                    <div className={`faq__answer ${isActive ? "open" : ""}`}>
                      <p>{item.answerJSX || item.answer}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <ImageGrid />
    </section>
  );
};

export default FAQ;
