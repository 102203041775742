import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../PageHelmet";
import { articlesProducts } from "../../utils/articlesProducts";
import useHighlight from "../../hooks/useHighlight";
import "./Products.css";
import Slider3 from "../../images/MainSlider3.webp";

const articles = Object.keys(articlesProducts).map((key) => ({
  id: key,
  title: articlesProducts[key].title,
  text: articlesProducts[key].text,
  img: articlesProducts[key].img,
}));

const Products = ({ headerHeight }) => {
  useHighlight();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="products"
      id="products"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Продукты Fit and Joy | Спортивное питание, витамины, коллаген"
        description="Вся линейка продукции Fit and Joy: протеиновые коктейли, батончики, витамины, коллаген, жиросжигатели и детокс-средства. Только натуральные ингредиенты и проверенное качество."
        keywords="спортивное питание, БАДы, витамины, протеин, коллаген, жиросжигатели, детокс"
        image={Slider3}
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/products/`}
      />

      <div className="products__wrapper">
        <h1 className="products__title">Наша продукция</h1>
        <p className="products__subtitle">
          Вся линейка продукции Fit and Joy от спортивного питания до витаминных
          комплексов – это уникальные составы на основе натуральных компонентов,
          максимально органических и полезных для вашего здоровья.
        </p>

        <ul className="products__container">
          {articles.map((article) => (
            <li className="products__card" key={article.id}>
              <Link
                to={`/products/${article.id}/`}
                className="products__card-link"
              >
                <img
                  className="products__card-img"
                  src={`${process.env.PUBLIC_URL}/images/articles/${article.img}`}
                  alt={article.title}
                />
                <h2 className="products__card-title">{article.title}</h2>

                <span className="products__card-text">{article.text}</span>

                <p className="products__card-subtitle">
                  подробнее про {article.title}
                </p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Products;
