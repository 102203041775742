import React, { useState } from "react";
import "./BMICalculator.css";

const BMICalculator = () => {
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("male");
  const [activity, setActivity] = useState("sedentary");
  const [bmi, setBMI] = useState(null);
  const [category, setCategory] = useState("");
  const [errors, setErrors] = useState({});

  const activityMultipliers = {
    sedentary: 1.0,
    light: 0.95,
    moderate: 0.9,
    active: 0.85,
    "very-active": 0.8,
  };

  const validateInputs = () => {
    let newErrors = {};
    const weightValue = parseFloat(weight);
    const heightValue = parseFloat(height);
    const ageValue = parseInt(age);

    if (!ageValue || ageValue < 10 || ageValue > 120) {
      newErrors.age =
        "Этот калькулятор рассчитан для людей от 10 лет. Пожалуйста, введите возраст в пределах от 10 до 120 лет.";
    }
    if (!weightValue || weightValue < 40 || weightValue > 300) {
      newErrors.weight =
        "Для корректного расчета укажите вес в пределах от 40 до 300 кг";
    }
    if (!heightValue || heightValue < 110 || heightValue > 250) {
      newErrors.height =
        "Для корректного расчета укажите рост в пределах от 110 до 250 см.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculateBMI = () => {
    if (!validateInputs()) return;

    const heightInMeters = height / 100;
    let bmiValue =
      (weight / (heightInMeters * heightInMeters)) *
      activityMultipliers[activity];

    if (gender === "female") {
      bmiValue *= 0.95;
    }

    bmiValue = bmiValue.toFixed(1);
    setBMI(bmiValue);

    let categoryText = "";
    if (bmiValue < 18.5) categoryText = "Недостаточный вес";
    else if (bmiValue < 24.9) categoryText = "Здоровый вес";
    else if (bmiValue < 29.9) categoryText = "Избыточный вес";
    else categoryText = "Ожирение";

    setCategory(categoryText);
  };

  return (
    <div className="bmi-wrapper">
      <h2 className="blog__title">Проверь свой BMI-индекс</h2>
      <p className="products__subtitle">
        Калькулятор BMI позволит быстро рассчитать ваш Индекс Массы Тела. Для
        расчета вам необходимо знать лишь ваш рост и вес. По полученному
        результату вы сможете оценить, насколько ваш вес соответствует норме
      </p>
      <div className="bmi-content">
        <div className="bmi-description">
          <h2 className="bmi-description__title">Калькулятор BMI-индекса</h2>
          <p
            className="bmi-description__text
            "
          >
            ИМТ- Индекс массы тела (англ. BMI - body mass index) это величина,
            применяемая для оценки степени соответствия роста и массы человека и
            оценки массы тела (масса тела может быть нормальной, недостаточной,
            избыточной (ожирение)).
          </p>
        </div>
        <div className="bmi-container">
          <div className="bmi-inputs">
            <label>
              Пол:
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="male">Мужской</option>
                <option value="female">Женский</option>
              </select>
            </label>
            <label>
              Возраст (лет):
              <input
                type="number"
                value={age}
                min="10"
                max="120"
                onChange={(e) => setAge(e.target.value)}
              />
              {errors.age && <p className="error-text">{errors.age}</p>}
            </label>
            <label>
              Вес (кг):
              <input
                type="number"
                value={weight}
                min="20"
                max="300"
                onChange={(e) => setWeight(e.target.value)}
              />
              {errors.weight && <p className="error-text">{errors.weight}</p>}
            </label>
            <label>
              Рост (см):
              <input
                type="number"
                value={height}
                min="50"
                max="250"
                onChange={(e) => setHeight(e.target.value)}
              />
              {errors.height && <p className="error-text">{errors.height}</p>}
            </label>
            <label>
              Уровень активности:
              <select
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
              >
                <option value="sedentary">Сидячий образ жизни</option>
                <option value="light">Легкий</option>
                <option value="moderate">Средний</option>
                <option value="active">Активный</option>
                <option value="very-active">Очень активный</option>
              </select>
            </label>
          </div>
        </div>
        <div className="bmi-result">
          <button className="btn bmi-result__btn" onClick={calculateBMI}>
            Рассчитать
          </button>
          {bmi && (
            <p>
              Ваш BMI: {bmi} ({category})
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BMICalculator;
