export const proteinBarsProducts = {
    'coconut-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Кокосовый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Оригинальный кокос в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес.</p>
                <br />
                <p> Кокосовый батончик FIT AND JOY - это не только продукт правильного и здорового питания, но еще и отличный десерт, который заменит вредные сладости и не даст сорваться с диеты. </p>
            </>
        ),
        price: '700',
        tasteEng: 'coconut',
        taste: 'Оригинальный кокос',
        energyValue: '162',
        nutritionalValueProteins: '2.8',
        nutritionalValueFats: '11.2',
        nutritionalValueCarbohydrates: '18.9',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление', 'энергия', 'поддержание красоты и здоровья', 'стройность', 'укрепление иммунитета', 'быстрый перекус'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: ['CPBC1.webp', 'CPBC2.webp', 'CPBC3.webp', 'CPB.webp'],
        img: 'CPBC1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p> </>
    },
    'chocolate-coconut-bar': {
        categoryName: "протеиновые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик  Двойной шоколад в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.</p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'chocolate',
        taste: 'Двойной шоколад',
        energyValue: '160',
        nutritionalValueProteins: '3.2',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '19.5',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'энергия и выносливость', 'поддержание красоты и здоровья', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBCh1.webp', 'CPBCh2.webp', 'CPBCh3.webp', 'CPB.webp'],
        img: 'CPBCh1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>
    },
    'mango-coconut-bar': {
        categoryName: "протеиновые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Манго в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.</p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'mango',
        taste: 'Манго',
        energyValue: '159',
        nutritionalValueProteins: '2.7',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '19.5',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'энергия и выносливость', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBM1.webp', 'CPBM2.webp', 'CPBM3.webp', 'CPB.webp'],
        img: 'CPBM1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>
    },
    'strawberry-kiwi-coconut-bar': {
        categoryName: "протеиновые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Клубника-киви в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.  </p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '790',
        tasteEng: 'strawberry-kiwi',
        taste: 'Клубника-киви',
        energyValue: '146',
        nutritionalValueProteins: '2.6',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '20.1',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'энергия и выносливость', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBSK1.webp', 'CPBSK2.webp', 'CPBSK3.webp', 'CPB.webp'],
        img: 'CPBSK1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>


    },
    'caramel-goji-berry-bars': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик низкокалорийный, без сахара, с экстрактами ягод годжи и гуараны',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик для похудения Соленая карамель в шоколадной глазури FIT AND JOY — это вкусный и полезный перекус без сахара!</p>
                <p>Это наша новая премиальная линейка батончиков с экстрактами ягод годжи и гуараны. С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. </p>
                <p>Наша линейка вкусов: Лесные ягоды, Соленая карамель и арахис, Ореховый микс, Лимон-лайм, а также набор из ассорти вкусов. В коробке 16 штук, вес 1-го батончика 40 грамм. Допускается небольшое отклонение ±5 грамм.</p>
            </>
        ),
        price: '820',
        tasteEng: 'caramel',
        taste: 'Соленая карамель',
        energyValue: '93',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '16',
        textPurpose: 'Энергия, контроль веса, восстановление, поддержка иммунитета',
        purposes: ['энергия', 'контроль веса', 'восстановление после нагрузок', 'стройность'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['GBCrml1.webp', 'GBCrml2.webp', 'GBCrml3.webp', 'PB.webp'],
        img: 'GBCrml1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети до 3 лет:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. 3–6 лет:</b> ограниченно и только под контролем взрослых.
                    → Возможны небольшие порции в виде редкого угощения, но основной белок должен поступать из цельных продуктов (молоко, мясо, яйца)</p>
            </>
        )
    },
    'limon-goji-berry-bars': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик низкокалорийный, без сахара, с экстрактами ягод годжи и гуараны',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик для похудения Лимон-лайм в шоколадной глазури FIT AND JOY — это вкусный и полезный перекус без сахара!</p>
                <p>Это наша новая премиальная линейка батончиков с экстрактами ягод годжи и гуараны. С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. </p>
                <p>Наша линейка вкусов: Лесные ягоды, Соленая карамель и арахис, Ореховый микс, Лимон-лайм, а также набор из ассорти вкусов. В коробке 16 штук, вес 1-го батончика 40 грамм. Допускается небольшое отклонение ±5 грамм.</p>
            </>
        ),
        price: '780',
        tasteEng: 'limon',
        taste: 'Лимон-лайм',
        energyValue: '81',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '2',
        nutritionalValueCarbohydrates: '17',
        textPurpose: 'Энергия, контроль веса, восстановление, поддержка иммунитета',
        purposes: ['энергия', 'контроль веса', 'восстановление после нагрузок', 'для женщин', 'здоровье кожи, волос, ногтей'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['GBLL1.webp', 'GBLL2.webp', 'GBLL3.webp', 'PB.webp'],
        img: 'GBLL1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети до 3 лет:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. 3–6 лет:</b> ограниченно и только под контролем взрослых.
                    → Возможны небольшие порции в виде редкого угощения, но основной белок должен поступать из цельных продуктов (молоко, мясо, яйца)</p>
            </>
        )
    },
    'nut-goji-berry-bars': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик низкокалорийный, без сахара, с экстрактами ягод годжи и гуараны',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик для похудения Ореховый микс в шоколадной глазури FIT AND JOY — это вкусный и полезный перекус без сахара!</p>
                <p>Это наша новая премиальная линейка батончиков с экстрактами ягод годжи и гуараны. С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес.</p>
                <p>Наша линейка вкусов: Лесные ягоды, Соленая карамель и арахис, Ореховый микс, Лимон-лайм, а также набор из ассорти вкусов. В коробке 16 штук, вес 1-го батончика 40 грамм. Допускается небольшое отклонение ±5 грамм.</p>
            </>
        ),
        price: '780',
        tasteEng: 'nut',
        taste: 'Ореховый микс',
        energyValue: '93',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '45',
        textPurpose: 'Энергия, контроль веса, восстановление, поддержка иммунитета',
        purposes: ['энергия', 'контроль веса', 'восстановление после нагрузок', 'поддержка иммунитета', 'быстрый перекус'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['GBNut1.webp', 'GBNut2.webp', 'GBNut3.webp', 'PB.webp'],
        img: 'GBNut1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети до 3 лет:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. 3–6 лет:</b> ограниченно и только под контролем взрослых.
                    → Возможны небольшие порции в виде редкого угощения, но основной белок должен поступать из цельных продуктов (молоко, мясо, яйца)</p>
            </>
        )
    },
    'wild-berries-goji-berry-bars': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик низкокалорийный, без сахара, с экстрактами ягод годжи и гуараны',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик для похудения Лесные ягоды в шоколадной глазури FIT AND JOY — это вкусный и полезный перекус без сахара!</p>
                <p>Это наша новая премиальная линейка батончиков с экстрактами ягод годжи и гуараны. С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес.</p>
                <p>Наша линейка вкусов: Лесные ягоды, Соленая карамель и арахис, Ореховый микс, Лимон-лайм, а также набор из ассорти вкусов. В коробке 16 штук, вес 1-го батончика 40 грамм. Допускается небольшое отклонение ±5 грамм.</p>
            </>
        ),
        price: '780',
        tasteEng: 'wild-berries',
        taste: 'Лесные ягоды',
        energyValue: '93',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '16',
        textPurpose: 'Энергия, контроль веса, восстановление, поддержка иммунитета',
        purposes: ['стройность', 'восстановление после нагрузок', 'поддержка иммунитета'],
        composition: 'Изолят соевого белка, сироп олигофруктозы, глазурь кондитерская (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), арахис жареный, молочная сыворотка, сорбитол, кокосовое масло, вода, изолят сывороточного белка, глицерин, лимонная кислота, натуральные ароматизаторы, лецитин, сорбат калия, стевия, аскорбиновая кислота, экстракт ягод годжи, экстракт гуараны.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['GBWB1.webp', 'GBWB2.webp', 'GBWB3.webp', 'PB.webp'],
        img: 'GBWB1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети до 3 лет:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. 3–6 лет:</b> ограниченно и только под контролем взрослых.
                    → Возможны небольшие порции в виде редкого угощения, но основной белок должен поступать из цельных продуктов (молоко, мясо, яйца)</p>
            </>
        )
    },
    'peanut-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Ореховый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик с арахисом в шоколаде от FIT AND JOY — это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает поддерживать форму и восполнять энергию.</p>
                <p>Побалуйте себя шоколадными сладостями без вреда для здоровья!</p>
            </>
        ),
        price: '900',
        tasteEng: 'pea',
        taste: 'Арахис',
        energyValue: '128.7',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.2',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Энергия, восстановление, поддержка физической активности',
        purposes: ['энергия', 'восстановление', 'поддержка физической активности'],
        composition: 'Изолят соевого белка, арахис жареный, мальтодекстрин, рис экструдированный, изюм, патока карамельная, вода, какао-порошок, кондитерская глазурь (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['NPBPeanut1.webp', 'NPBPeanut2.webp', 'NPBPeanut3.webp', 'NPB.webp'],
        img: 'NPBPeanut1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. дети до 14 лет:</b> разрешены в умеренных количествах.
                    В этом возрасте растущий организм требует больше белка, особенно при занятиях спортом. Протеиновые батончики могут быть хорошим дополнением к рациону.
                </p>
            </>
        )
    },
    'walnut-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Ореховый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик с Грецким орехом в шоколаде от FIT AND JOY — это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает поддерживать форму и восполнять энергию.</p>
                <p>Побалуйте себя шоколадными сладостями без вреда для здоровья!</p>
            </>
        ),
        price: '900',
        tasteEng: 'wal',
        taste: 'Грецкий орех',
        energyValue: '128.7',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.2',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Энергия, восстановление, поддержка физической активности',
        purposes: ['для мужчин', 'восстановление', 'поддержка физической активности'],
        composition: 'Изолят соевого белка, арахис жареный, мальтодекстрин, рис экструдированный, изюм, патока карамельная, вода, какао-порошок, кондитерская глазурь (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['NPBWalnut1.webp', 'NPBWalnut2.webp', 'NPBWalnut3.webp', 'NPB.webp'],
        img: 'NPBWalnut1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. дети до 14 лет:</b> разрешены в умеренных количествах.
                </p>
            </>
        )
    },
    'chocolate-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Двойной шоколад от FIT AND JOY — это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик низкоуглеводным и высокобелковым, что помогает контролировать вес и восполнять энергию.</p>
                <p>Protein бар — это не только полезный низкокалорийный ПП-десерт, но и отличный белковый перекус после тренировки. Побалуйте себя шоколадными сладостями без вреда для здоровья.</p>
            </>
        ),
        price: '790',
        tasteEng: 'chocolate',
        taste: 'Двойной шоколад',
        energyValue: '130',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.8',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Стимуляция метаболизма, поддержка силовых тренировок, контроль аппетита',
        purposes: ['метаболизм', 'поддержка мышц', 'контроль аппетита', 'для мужчин'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, патока, вода, какао-порошок, кондитерская глазурь (какао-порошок, какао-масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBCh1.webp', 'PBCh2.webp', 'PBCh3.webp', 'NPB.webp'],
        img: 'PBCh1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит молочные компоненты, какао и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. Детям до 14 лет:</b> Разрешено при активном образе жизни, но не более 1 батончика в день.</p>
            </>
        )
    },
    'rocky-road-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Печенье Роки Роад от FIT AND JOY — это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик низкоуглеводным и высокобелковым, что помогает контролировать вес и восполнять энергию.</p>
                <p>Protein бар — это не только полезный низкокалорийный ПП-десерт, но и отличный белковый перекус после тренировки. Побалуйте себя шоколадными сладостями без вреда для здоровья.</p>
            </>
        ),
        price: '810',
        tasteEng: 'chocolate rocky-road',
        taste: 'Печенье Роки Роад',
        energyValue: '101',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '3.2',
        nutritionalValueCarbohydrates: '4.8',
        textPurpose: 'Стимуляция метаболизма, поддержка силовых тренировок, контроль аппетита',
        purposes: ['метаболизм', 'поддержка мышц', 'контроль аппетита', 'для мужчин'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, патока, вода, какао-порошок, кондитерская глазурь (какао-порошок, какао-масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBCRR1.webp', 'PBCRR2.webp', 'PBCRR3.webp', 'NPB.webp'],
        img: 'PBCRR1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит молочные компоненты, какао и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. Детям до 14 лет:</b> Разрешено при активном образе жизни, но не более 1 батончика в день.</p>
            </>
        )
    },
    'mango-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Манго от FIT AND JOY — это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик низкоуглеводным и высокобелковым, что помогает контролировать вес и восполнять энергию.</p>
                <p>Protein бар — это не только полезный низкокалорийный ПП-десерт, но и отличный белковый перекус после тренировки. Побалуйте себя шоколадными сладостями без вреда для здоровья.</p>
            </>
        ),
        price: '800',
        tasteEng: 'mango',
        taste: 'Манго',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Стимуляция метаболизма, поддержка силовых тренировок, контроль аппетита',
        purposes: ['метаболизм', 'поддержка мышц', 'контроль аппетита', 'для женщин'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, патока, вода, какао-порошок, кондитерская глазурь (какао-порошок, какао-масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBM1.webp', 'PBM2.webp', 'PBM3.webp', 'NPB.webp'],
        img: 'PBM1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит молочные компоненты, какао и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. Детям до 14 лет:</b> Разрешено при активном образе жизни, но не более 1 батончика в день.</p>
            </>
        )
    },
    'strawberry-kiwi-protein-bar': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Клубника-киви от FIT AND JOY — это диетическое питание без глютена для тех, кто занимается спортом и следит за своей фигурой.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик низкоуглеводным и высокобелковым, что помогает контролировать вес и восполнять энергию.</p>
                <p>Protein бар — это не только полезный низкокалорийный ПП-десерт, но и отличный белковый перекус после тренировки. Побалуйте себя шоколадными сладостями без вреда для здоровья.</p>
            </>
        ),
        price: '800',
        tasteEng: 'strawberry-kiwi',
        taste: 'Клубника-киви',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Стимуляция метаболизма, поддержка силовых тренировок, контроль аппетита',
        purposes: ['метаболизм', 'поддержка мышц', 'контроль аппетита', 'выносливость', 'быстрый перекус'],
        composition: 'Изолят соевого белка, водорастворимое кукурузное волокно, концентрат сывороточного белка, изолят молочного белка, патока, вода, какао-порошок, кондитерская глазурь (какао-порошок, какао-масло, эквивалент масла какао), соевый лецитин (эмульгатор), ароматизатор (ванилин), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBSK1.webp', 'PBSK2.webp', 'PBSK3.webp', 'NPB.webp'],
        img: 'PBSK1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит молочные компоненты, какао и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. Детям до 14 лет:</b> Разрешено при активном образе жизни, но не более 1 батончика в день.</p>
            </>
        )
    },
    'mango-protein-bar-no-glaze': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без глазури, без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Манговый сорбет без глазури от FIT AND JOY — это низкокалорийный продукт без глютена, предназначенный для людей, ведущих активный образ жизни.</p>
                <p>Этот батончик сочетает в себе высокое содержание белка и минимальное количество углеводов, что делает его идеальным перекусом для тех, кто следит за фигурой.</p>
                <p>Отлично подходит для поддержания энергии во время тренировок и в течение дня.</p>
            </>
        ),
        price: '820',
        tasteEng: 'mango',
        taste: 'Манговый сорбет',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Легкость и активность, поддержка энергии, контроль углеводов',
        purposes: ['поддержка энергии', 'легкость в питании', 'контроль углеводов', 'восстановление после нагрузки'],
        composition: 'Водорастворимое волокно кукурузы, глицерин, пищевые ароматизаторы, лецитин, пищевой краситель, стевиозид, регулятор кислотности (лимонная кислота), сорбат калия, аскорбиновая кислота, мальтодекстрин, изолят соевого белка, вода, патока, сорбитол, кокосовое масло.',
        text: 'Без антибиотиков, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBWGMsorbet1.webp', 'PBWGMsorbet2.webp', 'PBWGMsorbet3.webp', 'PBWGS.webp'],
        img: 'PBWGMsorbet1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит соевый белок и кокосовое масло, что может вызвать аллергию.</p>
                <p><b>2. Людям с заболеваниями ЖКТ:</b> Высокое содержание клетчатки и подсластителей может вызвать дискомфорт.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется консультация с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>4. Дети до 16 лет:</b> Разрешено в умеренных количествах, под контролем взрослых.</p>
            </>
        )
    },
    'nut-protein-bar-no-glaze': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без глазури, без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Шоколадно-Ореховый тарт без глазури от FIT AND JOY — это низкокалорийный продукт без глютена, предназначенный для людей, ведущих активный образ жизни.</p>
                <p>Этот батончик сочетает в себе высокое содержание белка и минимальное количество углеводов, что делает его идеальным перекусом для тех, кто следит за фигурой.</p>
                <p>Отлично подходит для поддержания энергии во время тренировок и в течение дня.</p>
            </>
        ),
        price: '810',
        tasteEng: 'chocolate, nut',
        taste: 'Шоколадно-Ореховый тарт',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Легкость и активность, поддержка энергии, контроль углеводов',
        purposes: ['поддержка энергии', 'легкость в питании', 'контроль углеводов', 'восстановление после нагрузки'],
        composition: 'Водорастворимое волокно кукурузы, глицерин, пищевые ароматизаторы, лецитин, пищевой краситель, стевиозид, регулятор кислотности (лимонная кислота), сорбат калия, аскорбиновая кислота, мальтодекстрин, изолят соевого белка, вода, патока, сорбитол, кокосовое масло.',
        text: 'Без антибиотиков, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBWGNutP1.webp', 'PBWGNutP2.webp', 'PBWGNutP3.webp', 'PBWGS.webp'],
        img: 'PBWGNutP1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит соевый белок и кокосовое масло, что может вызвать аллергию.</p>
                <p><b>2. Людям с заболеваниями ЖКТ:</b> Высокое содержание клетчатки и подсластителей может вызвать дискомфорт.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется консультация с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>4. Дети до 16 лет:</b> Разрешено в умеренных количествах, под контролем взрослых.</p>
            </>
        )
    },
    'raspberry-protein-bar-no-glaze': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без глазури, без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Малиновый чизкейк без глазури от FIT AND JOY — это низкокалорийный продукт без глютена, предназначенный для людей, ведущих активный образ жизни.</p>
                <p>Этот батончик сочетает в себе высокое содержание белка и минимальное количество углеводов, что делает его идеальным перекусом для тех, кто следит за фигурой.</p>
                <p>Отлично подходит для поддержания энергии во время тренировок и в течение дня.</p>
            </>
        ),
        price: '830',
        tasteEng: 'raspberry',
        taste: 'Малиновый чизкейк',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Легкость и активность, поддержка энергии, контроль углеводов',
        purposes: ['поддержка энергии', 'легкость в питании', 'контроль углеводов', 'восстановление после нагрузки', 'быстрый перекус'],
        composition: 'Водорастворимое волокно кукурузы, глицерин, пищевые ароматизаторы, лецитин, пищевой краситель, стевиозид, регулятор кислотности (лимонная кислота), сорбат калия, аскорбиновая кислота, мальтодекстрин, изолят соевого белка, вода, патока, сорбитол, кокосовое масло.',
        text: 'Без антибиотиков, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBWGRaspberry1.webp', 'PBWGRaspberry2.webp', 'PBWGRaspberry3.webp', 'PBWGS.webp'],
        img: 'PBWGRaspberry1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит соевый белок и кокосовое масло, что может вызвать аллергию.</p>
                <p><b>2. Людям с заболеваниями ЖКТ:</b> Высокое содержание клетчатки и подсластителей может вызвать дискомфорт.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется консультация с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>4. Дети до 16 лет:</b> Разрешено в умеренных количествах, под контролем взрослых.</p>
            </>
        )
    },
    'strawberry-protein-bar-no-glaze': {
        categoryName: "протеиновые батончики",
        title: 'Протеиновый батончик без глазури, без сахара. Клубничный милкшейк',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик Клубничный милкшейк без глазури от FIT AND JOY — это низкокалорийный продукт без глютена, предназначенный для людей, ведущих активный образ жизни.</p>
                <p>Этот батончик сочетает в себе высокое содержание белка и минимальное количество углеводов, что делает его идеальным перекусом для тех, кто следит за фигурой.</p>
                <p>Отлично подходит для поддержания энергии во время тренировок и в течение дня.</p>
            </>
        ),
        price: '840',
        tasteEng: 'strawberry',
        taste: 'Клубника',
        energyValue: '70.4',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '1.2',
        nutritionalValueCarbohydrates: '2.8',
        textPurpose: 'Легкость и активность, поддержка энергии, контроль углеводов',
        purposes: ['поддержка энергии', 'легкость в питании', 'контроль углеводов', 'выносливость'],
        composition: 'Водорастворимое волокно кукурузы, глицерин, пищевые ароматизаторы, лецитин, пищевой краситель, стевиозид, регулятор кислотности (лимонная кислота), сорбат калия, аскорбиновая кислота, мальтодекстрин, изолят соевого белка, вода, патока, сорбитол, кокосовое масло.',
        text: 'Без антибиотиков, без ГМО, без глютена',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в прохладном темном месте',
        gender: 'Женский, Мужской',
        images: ['PBWGSCream1.webp', 'PBWGSCream2.webp', 'PBWGSCream3.webp', 'PBWGS.webp'],
        img: 'PBWGSCream1.webp',
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит соевый белок и кокосовое масло, что может вызвать аллергию.</p>
                <p><b>2. Людям с заболеваниями ЖКТ:</b> Высокое содержание клетчатки и подсластителей может вызвать дискомфорт.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется консультация с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>4. Дети до 16 лет:</b> Разрешено в умеренных количествах, под контролем взрослых.</p>
            </>
        )
    },
}