import React, { useEffect } from "react";
import "./AboutUs.css";

import ProductionRequestForm from "../ProductionRequestForm/ProductionRequestForm";

import GiveGift from "../../images/GiveGift.webp";

const AboutUs = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleGoPodarok,
  handleTelegramClickFJ,
  handleVkClick,
  handleGoContractManufacturing,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="about-us"
      id="about-us"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="about-us__wrapper">
        <div className="about-us__container">
          <h1 className="about-us__title">
            Fit and Joy — натуральное спортивное питание и БАДы для здоровья и
            активной жизни
          </h1>

          <div className="main-title__text"></div>
        </div>

        <div className="about-us__button-block">
          <button
            className="btn__blue btn about-us__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="about-us__btn btn__blue btn"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>

        <h2 className="about-us__subtitle">Кто мы?</h2>
        <p className="about-us__text">
          Компания <strong>Fit and Joy</strong> — ваш надежный партнер в мире
          здорового питания и спортивного образа жизни. Мы производим{" "}
          <strong>натуральное спортивное питание</strong>, которое помогает
          поддерживать баланс питательных веществ, укреплять здоровье и
          достигать лучших результатов в спорте.
        </p>

        <h2 className="about-us__subtitle">Наша миссия</h2>
        <p className="about-us__text">
          Мы создаем продукты, которые{" "}
          <strong>сочетают пользу и отличный вкус</strong>. Вся наша продукция
          производится с использованием натуральных ингредиентов и не содержит
          сахара, глютена, ГМО и искусственных консервантов. Наша цель — сделать
          здоровое питание удобным и доступным для каждого.
        </p>

        <h2 className="about-us__subtitle">Что мы предлагаем?</h2>

        <h3 className="about-us__topic">Протеиновые супы</h3>
        <p className="about-us__text">
          Наши <strong>протеиновые супы</strong> — это сбалансированный источник
          белка, который легко усваивается организмом. Они помогают поддерживать
          энергичность в течение дня и подходят как для спортсменов, так и для
          всех, кто следит за своим питанием.
        </p>

        <h3 className="about-us__topic">Протеиновые батончики</h3>
        <p className="about-us__text">
          <strong>Протеиновые батончики</strong> — это идеальный перекус для
          активных людей. Они богаты белком, обеспечивают чувство сытости и
          помогают восполнить энергию без вредных сахаров.
        </p>

        <h3 className="about-us__topic">Протеиновые коктейли</h3>
        <p className="about-us__text">
          <strong>Протеиновые коктейли</strong> Fit and Joy — это вкусный и
          полезный способ обеспечить организм качественным белком. Они подходят
          как для восстановления после тренировок, так и в качестве полезного
          перекуса.
        </p>

        <h3 className="about-us__topic">Детокс-программы</h3>
        <p className="about-us__text">
          Наши <strong>детокс-продукты</strong> помогают мягко очищать организм
          от токсинов, поддерживают работу пищеварительной системы и улучшают
          самочувствие. Они содержат натуральные компоненты, способствующие
          детоксикации и оздоровлению организма.
        </p>

        <h3 className="about-us__topic">Витамины и минералы</h3>
        <p className="about-us__text">
          Мы предлагаем <strong>комплексы витаминов и минералов,</strong>{" "}
          разработанные для поддержания здоровья, иммунитета и жизненной
          энергии. Наши продукты помогают восполнить нехватку питательных
          веществ и обеспечивают организм всем необходимым для активной жизни.
        </p>

        <h3 className="about-us__topic">Жиросжигатели</h3>
        <p className="about-us__text">
          <strong>Жиросжигатели Fit and Joy</strong>— это эффективные комплексы
          для поддержки метаболизма и ускорения процесса похудения. Они помогают
          организму использовать жир в качестве энергии и способствуют
          достижению спортивных и фитнес-целей.
        </p>

        <h3 className="about-us__topic">Коллаген</h3>
        <p className="about-us__text">
          <strong>Коллаген</strong> необходим для поддержания красоты и здоровья
          кожи, волос, ногтей и суставов. Наши продукты с коллагеном содержат
          легкоусвояемые формы белка, которые помогают сохранить молодость и
          укрепить соединительные ткани. Все наши продукты разработаны с заботой
          о вашем здоровье и содержат только натуральные компоненты, без сахара,
          глютена, ГМО и искусственных консервантов. Fit and Joy — это ваш выбор
          для здоровой и активной жизни!
        </p>

        <h2 className="about-us__subtitle">Почему выбирают Fit and Joy?</h2>
        <ul className="about-us__list">
          <li>
            <strong>Качественные ингредиенты:</strong> наша продукция не
            содержит искусственных добавок, ГМО и консервантов.
          </li>
          <li>
            <strong>Широкий ассортимент:</strong> продукты для разных целей – от
            набора массы до детокса.
          </li>
          <li>
            <strong>Профессиональная поддержка:</strong> наши специалисты всегда
            готовы помочь с выбором продукции.
          </li>
          <li>
            <strong>Высокий уровень сервиса:</strong> удобный процесс заказа,
            быстрая доставка и внимательное отношение к клиентам.
          </li>
        </ul>

        <h2 className="about-us__subtitle">Наши ценности</h2>
        <ul className="about-us__list">
          <li>
            <strong>Здоровье:</strong> мы создаем продукты, которые способствуют
            активной и долгой жизни.
          </li>
          <li>
            <strong>Честность:</strong> наша продукция соответствует заявленным
            характеристикам – никаких скрытых ингредиентов.
          </li>
          <li>
            <strong>Клиентоориентированность:</strong> каждый клиент для нас
            важен, и мы стремимся сделать его опыт покупки приятным.
          </li>
          <li>
            <strong>Разнообразие:</strong> широкий выбор продукции для разных
            целей.
          </li>
        </ul>

        <h2 className="about-us__subtitle">
          Спасибо, что выбираете Fit and Joy!
        </h2>
        <p className="about-us__text">
          Мы благодарим вас за доверие! Fit and Joy – это не просто бренд, а
          сообщество людей, которые стремятся к здоровому и активному образу
          жизни. Мы рады быть частью вашего пути к лучшему самочувствию и
          большим достижениям!
        </p>

        <div className="main__block">
          <div className="main__image-container">
            <img
              className="main__container-img"
              src={GiveGift}
              alt="Подарок от Fit and Joy"
            />

            <button className="main__btn" onClick={handleGoPodarok}>
              ПОЛУЧИТЬ ПОДАРОК
            </button>
          </div>
        </div>
        <div className="main__block">
          <h2 className="main__subtitle">
            Присоединяйтесь к нашему сообществу!
          </h2>
          <p className="main__text">
            Получайте полезные советы, вдохновение и эксклюзивные предложения в
            наших соцсетях.
          </p>
          <ul className="main__list">
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                alt="Иконка телеграмм"
                onClick={handleTelegramClickFJ}
              />
            </li>
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                alt="Иконка VK"
                onClick={handleVkClick}
              />
            </li>
          </ul>
        </div>
        <div className="contract__container">
          <h1 className="tastes__title">Приглашаем к сотрудничеству</h1>
          <p className="contract__subtitle">Fit and Joy предлагает </p>
          <button
            className="faq__link"
            type="button"
            onClick={handleGoContractManufacturing}
          >
            услуги контрактного производства
          </button>

          <p className="contract__subtitle">
            {" "}
            БАДов, витаминов и продуктов спортивного питания.
          </p>

          <div className="contract__benefits">
            <h2 className="contract__section-title">Что мы предлагаем:</h2>
            <ul className="contract__list">
              <li>🔬 Современное сертифицированное производство</li>
              <li>📦 Упаковка под вашим брендом</li>
              <li>🧪 Разработка формул под ключ</li>
              <li>✅ Контроль качества на каждом этапе</li>
              <li>🚚 Быстрая доставка и логистика</li>
              <li>📈 Поддержка и консультации по выводу продукта на рынок</li>
            </ul>
          </div>
          <div className="contract__cta">
            <h2 className="tastes__title">Станьте нашим партнером</h2>
            <p>
              <br /> Мы открыты для сотрудничества с компаниями, стартапами и
              частными брендами.
              <br /> У вас уже есть идея или готовая формула? <br />
              Или только план?
              <br />
              <br />
              <b>Мы поможем вам в реализации.</b>
            </p>
            <p>
              {" "}
              <br />
              Оставьте заявку — и мы свяжемся с вами в ближайшее время.
              <br />
            </p>
            <ProductionRequestForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
