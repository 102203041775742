import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "./Header.css";

import NavTab from "../NavTab/NavTab";
import Menu from "../Menu/Menu";
import SearchBar from "../SearchBar/SearchBar";

const Header = ({
  isMobile,
  handleGoMain,
  headerHeight,
  handleGoPodarok,
  handleVkClick,
  handleTelegramClickFJ,
}) => {
  const location = useLocation();

  // const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  // const handleBurgerButtonClick = () => {
  //   setIsBurgerOpen((prev) => !prev);
  // };
  return (
    //

    <header className="header">
      <>
        <button
          className="btn btn__violet header__btn"
          onClick={handleGoPodarok}
        >
          🎁 ПОДАРОК от FIT AND JOY 🎁
        </button>

        <div className="header__wrapper">
          <div className="header__logo" onClick={handleGoMain}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.webp`}
              alt="логотип сайта Fit and Joy"
            />
          </div>

          {isMobile ? (
            <Menu isMobile={isMobile} />
          ) : (
            <NavTab isMobile={isMobile} headerHeight={headerHeight} />
          )}
          <SearchBar />
          <ul className="header__list">
            <li className="header__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                alt="Иконка телеграмм"
                onClick={handleTelegramClickFJ}
              />
            </li>
            <li className="header__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                alt="Иконка VK"
                onClick={handleVkClick}
              />
            </li>
          </ul>
        </div>
      </>
    </header>
  );
};

export default Header;
