export const coconutBarsProducts = {
    'coconut-protein-bar': {
        categoryName: "кокосовые батончики",
        title: 'Кокосовый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Оригинальный кокос в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес.</p>
                <br />
                <p> Кокосовый батончик FIT AND JOY - это не только продукт правильного и здорового питания, но еще и отличный десерт, который заменит вредные сладости и не даст сорваться с диеты. </p>
            </>
        ),
        price: '700',
        tasteEng: 'koconut',
        taste: 'Оригинальный кокос',
        energyValue: '162',
        nutritionalValueProteins: '2.8',
        nutritionalValueFats: '11.2',
        nutritionalValueCarbohydrates: '18.9',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'выносливость', 'стройность', 'энергия', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без сахара',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: ['CPBC1.webp', 'CPBC2.webp', 'CPBC3.webp', 'CPB.webp'],
        img: 'CPBC1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p> </>
    },
    'chocolate-coconut-bar': {
        categoryName: "кокосовые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик  Двойной шоколад в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.</p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'chocolate coconut',
        taste: 'Двойной шоколад',
        energyValue: '160',
        nutritionalValueProteins: '3.2',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '19.5',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'выносливость', 'стройность', 'энергия', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBCh1.webp', 'CPBCh2.webp', 'CPBCh3.webp', 'CPB.webp'],
        img: 'CPBCh1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>
    },
    'mango-coconut-bar': {
        categoryName: "кокосовые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Манго в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.</p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '800 ',
        tasteEng: 'mango coconut',
        taste: 'Манго',
        energyValue: '159',
        nutritionalValueProteins: '2.7',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '19.5',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'стройность', 'энергия', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBM1.webp', 'CPBM2.webp', 'CPBM3.webp', 'CPB.webp',],
        img: 'CPBM1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>
    },
    'strawberry-kiwi-coconut-bar': {
        categoryName: "кокосовые батончики",
        title: 'Кокосовый протеиновый батончик без сахара.',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Кокосовый батончик Клубника-киви в шоколадной глазури FIT AND JOY - это Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес. Батончик идеален и для спортсменов, так как он помогает в наборе мышечной массы и дает большой заряд бодрости и энергии.  </p>
                <br />
                <p>Кокосовые батончики FIT AND JOY есть со вкусами: клубника-киви, двойной шоколад, кокос, манго, а также набор из ассорти вкусов.</p>
            </>
        ),
        price: '790',
        tasteEng: 'strawberry coconut',
        taste: 'Клубника киви',
        energyValue: '146',
        nutritionalValueProteins: '2.6',
        nutritionalValueFats: '11',
        nutritionalValueCarbohydrates: '20.1',
        textPurpose: 'Выносливость, повышение энергии, поддержание красоты и здоровья, набор мышечной массы, контроль веса, укрепление иммунитета, восстановление после нагрузок.',
        purposes: ['восстановление мышц', 'энергия', 'поддержание красоты и здоровья', 'контроль веса', 'укрепление иммунитета'],
        composition: 'Кокосовая стружка, мальтодекстрин, сироп олигофруктозы, глазурь кондитерская (Какао-порошок, заменитель какао-масла нетемперируемый лауринового типа, сухое обезжиренное молоко, подсластители — эритрит и сахара природного происхождения, ароматизатор, эмульгаторы, вкусоароматическое вещество «Ванилин»), сорбитол, водорастворимое волокно кукурузы, сушеное манго, глицерин, ароматизатор, инулин, лимонная кислота, соль поваренная, сорбат калия, стевия, сукралоза, аскорбиновая кислота.',
        text: 'без ГМО, без глютена, без жиров',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, Не подвергать воздействию прямого солнечного света',
        gender: 'Женский, Мужской',
        images: ['CPBSK1.webp', 'CPBSK2.webp', 'CPBSK3.webp', 'CPB.webp'],
        img: 'CPBSK1.webp',
        contraindications: <>
            <p><b>1. Аллергические реакции:</b> Продукт содержит кокос, молочные компоненты (сухое обезжиренное молоко) и может содержать следы глютена, что может вызвать аллергию.</p>
            <p><b>2. Непереносимость подсластителей:</b> Содержит эритрит, сорбитол, стевию и сукралозу, которые могут вызвать дискомфорт в желудке или слабительный эффект при чрезмерном употреблении.</p>
            <p><b>3. Людям с хроническими заболеваниями:</b> Пациентам с диабетом, заболеваниями почек и печени рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p><b>4. Беременные и кормящие женщины:</b> Следует проконсультироваться с врачом перед употреблением.</p>
            <p><b>5. Маленькие дети:</b> Не рекомендуется детям младше 3 лет из-за сложности переваривания компонентов.</p>
        </>
    },
}