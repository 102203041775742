import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    cardNumber: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [fileProduct, setFileProduct] = useState([]);
  const [fileOrder, setFileOrder] = useState([]);
  const [fileReview, setFileReview] = useState([]);
  const [fileSubscription, setFileSubscription] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileErrors, setFileErrors] = useState({
    fileProduct: "",
    fileOrder: "",
    fileReview: "",
    fileSubscription: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });

    // Удаляем ошибку при изменении соответствующего поля
    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleFileChange = (e, setFileState, fileType) => {
    const files = Array.from(e.target.files);
    const maxSize = 25 * 1024 * 1024; // 25 MB

    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [fileType]: "Ошибка: размер файлов превышает 25MB.",
      }));
      setFileState(files);
      return;
    }

    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [fileType]: "",
    }));

    setFileState(files);
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "Имя обязательно для заполнения";
    } else if (formData.firstName.length < 2) {
      newErrors.firstName = "Имя должно содержать минимум 2 символа";
    } else if (formData.firstName.length > 50) {
      newErrors.firstName = "Имя должно содержать не более 50 символов";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Фамилия обязательно для заполнения";
    } else if (formData.lastName.length < 2) {
      newErrors.lastName = "Фамилия должна содержать минимум 2 символа";
    } else if (formData.lastName.length > 50) {
      newErrors.lastName = "Фамилия должна содержать не более 50 символов";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Телефон обязателен для заполнения";
    } else if (!/^\+?\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = "Телефон введен некорректно";
    }

    if (formData.cardNumber && !/^\d{16}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = "Номер карты должен содержать 16 цифр";
    }

    if (!formData.agree) {
      newErrors.agree = "Вы должны согласиться с правилами и условиями";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const data = new FormData();
      setIsSubmitting(true);

      for (const key in formData) {
        data.append(key, formData[key]);
      }

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      try {
        await delay(2000);

        fileProduct.forEach((file) => data.append("fileProduct[]", file));
        fileOrder.forEach((file) => data.append("fileOrder[]", file));
        fileReview.forEach((file) => data.append("fileReview[]", file));
        fileSubscription.forEach((file) =>
          data.append("fileSubscription[]", file)
        );

        const response = await fetch("https://usafitandjoy.com/sendMail.php", {
          // const response = await fetch("http://localhost:5000/send", {
          method: "POST",
          body: data,
        });

        if (!response.ok) throw new Error(`Ошибка HTTP: ${response.status}`);

        const result = await response.text();

        setSubmissionStatus("✅ Форма успешно отправлена!");

        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          cardNumber: "",
          agree: false,
        });
        setFileProduct([]);
        setFileOrder([]);
        setFileReview([]);
        setFileSubscription([]);
      } catch (error) {
        console.error("Ошибка при отправке формы:", error);
        setSubmissionStatus("❌ Ошибка при отправке формы.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <section className="contact-form">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Имя"
          />
          {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>
        <div>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Фамилия"
          />
          {errors.lastName && <p className="error">{errors.lastName}</p>}
        </div>

        <div>
          <PhoneInput
            country={"ru"}
            value={formData.phone}
            onChange={(phone) => setFormData({ ...formData, phone })}
            placeholder="Телефон"
            inputStyle={{
              width: "100%",
              paddingLeft: "58px",
              borderRadius: "0",
              fontSize: "0.7em",
            }}
            containerStyle={{ width: "100%" }}
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
        <div>
          <input
            type="text"
            name="cardNumber"
            value={formData.cardNumber}
            onChange={handleChange}
            placeholder="Номер карты"
          />
          {errors.cardNumber && <p className="error">{errors.cardNumber}</p>}
        </div>

        <div className="contact-form__block">
          <label className="contact-form__block-text" htmlFor="fileProduct">
            Фотография купленного товара
          </label>
          <input
            type="file"
            name="fileProduct[]"
            id="fileProduct"
            multiple
            onChange={(e) => handleFileChange(e, setFileProduct, "fileProduct")}
          />
          <label htmlFor="fileProduct" className="file-label">
            Загрузить фотографию товара
          </label>
          <p className={fileErrors.fileProduct ? "error" : "file-info"}>
            {fileErrors.fileProduct
              ? fileErrors.fileProduct
              : `Выбранные файлы: ${fileProduct
                  .map((file) => file.name)
                  .join(", ")}`}
          </p>
        </div>

        <div className="contact-form__block">
          <label className="contact-form__block-text" htmlFor="fileOrder">
            Скриншот покупки (заказа) из ЛИЧНОГО кабинета
          </label>
          <input
            type="file"
            name="fileOrder[]"
            id="fileOrder"
            multiple
            onChange={(e) => handleFileChange(e, setFileOrder, "fileOrder")}
          />
          <label htmlFor="fileOrder" className="file-label">
            Загрузить скриншот покупки
          </label>
          <p className={fileErrors.fileOrder ? "error" : "file-info"}>
            {fileErrors.fileOrder
              ? fileErrors.fileOrder
              : `Выбранные файлы: ${fileOrder
                  .map((file) => file.name)
                  .join(", ")}`}
          </p>
        </div>

        <div className="contact-form__block">
          <label className="contact-form__block-text" htmlFor="fileReview">
            Скриншот отзыва из ЛИЧНОГО кабинета
          </label>
          <input
            type="file"
            name="fileReview[]"
            id="fileReview"
            multiple
            onChange={(e) => handleFileChange(e, setFileReview, "fileReview")}
          />
          <label htmlFor="fileReview" className="file-label">
            Загрузить скриншот отзыва
          </label>
          <p className={fileErrors.fileReview ? "error" : "file-info"}>
            {fileErrors.fileReview
              ? fileErrors.fileReview
              : `Выбранные файлы: ${fileReview
                  .map((file) => file.name)
                  .join(", ")}`}
          </p>
        </div>

        <div className="contact-form__block">
          <label
            className="contact-form__block-text"
            htmlFor="fileSubscription"
          >
            Скриншот вашей подписки на любую из наших соцсетей
          </label>
          <input
            type="file"
            name="fileSubscription[]"
            id="fileSubscription"
            multiple
            onChange={(e) =>
              handleFileChange(e, setFileSubscription, "fileSubscription")
            }
          />
          <label htmlFor="fileSubscription" className="file-label">
            Загрузить скриншот подписки
          </label>
          <p className={fileErrors.fileSubscription ? "error" : "file-info"}>
            {fileErrors.fileSubscription
              ? fileErrors.fileSubscription
              : `Выбранные файлы: ${fileSubscription
                  .map((file) => file.name)
                  .join(", ")}`}
          </p>
        </div>

        <div className="checkbox-container">
          <input
            className="contact-form__checkbox-box"
            type="checkbox"
            name="agree"
            checked={formData.agree}
            onChange={handleChange}
          />
          <p className="contact-form__checkbox-text">
            <label>
              Отправляя форму вы соглашаетесь с{" "}
              <Link to="/terms-and-conditions/">правилами и условиями </Link>
            </label>
          </p>
          {errors.agree && (
            <p className="contact-form__checkbox-error">{errors.agree}</p>
          )}
        </div>

        <button className="btn btn__blue" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "⏳ Отправка..." : "Отправить"}
        </button>
      </form>
      {submissionStatus && (
        <p className="submission-status">{submissionStatus}</p>
      )}
    </section>
  );
};

export default ContactForm;
