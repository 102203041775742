import React from "react";
import "./StepsSection.css";

const steps = [
  {
    number: "1",
    title: "Тренировки",
    image: "/images/1Step.webp",
    description: "Начните путь к телу мечты",
  },
  {
    number: "2",
    title: "Настойчивость",
    image: "/images/2Step.webp",
    description: "Сделайте тренировки неотъемлемой частью Вашей жизни",
  },
  {
    number: "3",
    title: "Правильное питание",
    image: "/images/3Step.webp",
    description: "Помогайте организму с помощью FIT AND JOY",
  },
  {
    number: "4",
    title: "Результат",
    image: "/images/4Step.webp",
    description: "Наслаждайтесь прекрасными результатами и телом мечты!",
  },
];

const StepsSection = () => {
  return (
    <section className="steps-section">
      <h2 className="tastes__title">Перестань желать. Начни делать!</h2>
      <p className="steps-quote">
        «Если вы хотите достичь совершенства, вы должны с этой секунды
        прекратить заниматься чем-то другим» - Томас Дж. Уотсон
      </p>

      <div className="steps-container">
        {steps.map((step, index) => (
          <div className="step-item" key={index}>
            <h3 className="step-title">{step.title}</h3>
            <img className="step-image" src={step.image} alt={step.title} />
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StepsSection;
