import React from "react";
import "./Categories.css";
import { categoriesData } from "../../utils/categoriesData"; 

const Categories = () => {
  return (
    <div className="categories">
      {categoriesData.map((category, index) => (
        <a key={index} href={category.link} className="category-link">
          <div className="category-card">
            <span className="category-icon">{category.icon}</span>
            <h3 className="category-title">{category.title}</h3>
            <p className="category-description">{category.description}</p>         
          </div>
        </a>
      ))}
    </div>
  );
};

export default Categories;
