import slimness from "../images/slimness.webp";
import calmness from "../images/calmness.webp";
import detox from "../images/detoxGoal.webp";
import endurance from "../images/endurance.webp";
import energy from "../images/energy.webp";
import hair from "../images/hair.webp";
import man from "../images/man.webp";
import women from "../images/women.webp";
import health from "../images/health.webp";
import recovery from "../images/recovery.webp";
import snack from "../images/snack.webp";

export const cardsGoal = [
  {
    img: slimness,
    alt: "Ваша цель стройность",
    goal: "стройность",
    purpose: "slimness",
    description: (
      <>
        <p>
          Достичь стройности - это не просто путь к красоте, но и к здоровью и уверенности в себе.
          Воспользуйтесь нашими продуктами, чтобы шаг за шагом приближаться к своей цели и наслаждаться каждым моментом этого пути!
        </p>
      </>
    ),
  },
  {
    img: man,
    alt: "Продукты для мужчин",
    goal: "для мужчин",
    purpose: "man",
    description: (
      <>
        <p>
          Специально подобранные продукты для мужчин помогут поддерживать силу, энергию и спортивную форму.
          Улучшайте свои результаты и достигайте новых высот!
        </p>
      </>
    ),
  },
  {
    img: snack, // изображение для раздела
    alt: "Быстрый и вкусный перекус",
    goal: "быстрый перекус",
    purpose: "quick-snack",

    description: (
      <>
        <p>
          Быстрый и вкусный перекус – это не только удобно, но и полезно!
          Продукты Fit and Joy помогут вам утолить голод в течение дня,
          обеспечивая организм необходимыми питательными веществами без вреда для фигуры.
        </p>
        <p>
          Протеиновые супы – отличный вариант для сытного перекуса, который легко приготовить
          за пару минут. Они богаты белком, витаминами и обладают насыщенным вкусом.
        </p>
        <p>
          Включите в свой рацион удобные и полезные перекусы, которые поддержат вашу активность
          и дадут энергию на целый день!
        </p>
      </>
    ),
  },
  {
    img: calmness,
    alt: "Продукты для спокойствия",
    goal: "спокойствие",
    purpose: "calmness",
    description: (
      <>
        <p>
          Сохраните внутренний баланс и спокойствие благодаря продуктам, которые способствуют расслаблению и снятию стресса.
          Ваше психоэмоциональное здоровье - наш приоритет.
        </p>
      </>
    ),
  },
  {
    img: hair,
    alt: "Здоровье кожи, волос и ногтей",
    goal: "здоровье кожи, волос, ногтей",
    purpose: "hair",
    description: (
      <>
        <p>
          Красота начинается изнутри! Наши продукты помогут поддерживать здоровье кожи, волос и ногтей,
          насыщая организм необходимыми витаминами и минералами.
        </p>
      </>
    ),
  },
  {
    img: detox,
    alt: "Продукты для детокса",
    goal: "детокс",
    purpose: "detox",
    description: (
      <>
        <p>
          Очищение организма - залог хорошего самочувствия и энергии.
          Выбирайте детокс-программы, чтобы восстановить баланс и улучшить общее состояние здоровья.
        </p>
      </>
    ),
  },
  {
    img: endurance,
    alt: "Выносливость",
    goal: "выносливость",
    purpose: "endurance",
    description: (
      <>
        <p>
          Улучшите свою выносливость и физическую подготовку с помощью продуктов, разработанных для спортсменов и активных людей.
          Больше энергии, меньше усталости!
        </p>
      </>
    ),
  },
  {
    img: energy,
    alt: "Продукты для энергии",
    goal: "энергия",
    purpose: "energy",
    description: (
      <>
        <p>
          Почувствуйте прилив сил и бодрости! Наши продукты помогут вам быть активными в течение дня и восполнять запасы энергии.
        </p>
      </>
    ),
  },
  {
    img: women,
    alt: "Продукты для женщин",
    goal: "для женщин",
    purpose: "women",
    description: (
      <>
        <p>
          Поддержите женское здоровье и красоту с помощью специально разработанных продуктов, насыщенных полезными микроэлементами.
        </p>
      </>
    ),
  },
  {
    img: health,
    alt: "Здоровье и самочувствие",
    goal: "здоровье",
    purpose: "health",
    description: (
      <>
        <p>
          В этом разделе представлены продукты, которые помогут вам поддерживать здоровье и улучшить самочувствие.
          Мы собрали решения для укрепления иммунитета, нормализации работы ЖКТ, здоровья суставов, сердца, зрения, борьбы со стрессом и многого другого.
        </p>
        <p>
          Выберите продукты, соответствующие вашим потребностям, и заботьтесь о своем организме каждый день!
        </p>
      </>
    ),
  },
  {
    img: recovery,
    alt: "Продукты для восстановления",
    goal: "восстановление",
    purpose: "recovery",

    description: (
      <>
        <p>
          Восстановление – ключ к здоровью, активности и хорошему самочувствию.
          Специально подобранные продукты Fit and Joy помогут вам быстрее
          восстанавливать силы, поддерживать баланс питательных веществ в организме
          и улучшать общее самочувствие.
        </p>
        <p>
          Независимо от того, восстанавливаетесь ли вы после интенсивных тренировок,
          стрессовых нагрузок или просто хотите поддерживать свой организм в тонусе –
          у нас есть подходящие решения.
        </p>
        <p>
          Включайте в свой рацион продукты, богатые белком, коллагеном, витаминами
          и антиоксидантами для лучшего восстановления и поддержания здоровья!
        </p>
      </>
    ),
  },

];
