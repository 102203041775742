import React from "react";
import PageHelmet from "../PageHelmet";
import "./About.css";
import AboutUs from "../AboutUs/AboutUs";
import ImageGrid from "../ImageGrid/ImageGrid";

const About = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleGoPodarok,
  handleTelegramClickFJ,
  handleVkClick,
  handleGoContractManufacturing,
}) => {
  return (
    <nav className="about" id="about">
      <PageHelmet
        title="О компании Fit and Joy — натуральное спортивное питание и БАДы"
        description="Fit and Joy предлагает натуральное спортивное питание, протеиновые коктейли, батончики и супы. Высокое качество, широкий ассортимент и удобный сервис."
        keywords="спортивное питание, БАДы, протеиновые батончики, протеиновые коктейли, протеиновые супы, здоровье, фитнес"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/about/`}
      />

      <AboutUs
        headerHeight={headerHeight}
        handleGoMain={handleGoMain}
        handleGoStore={handleGoStore}
        handleGoPodarok={handleGoPodarok}
        handleTelegramClickFJ={handleTelegramClickFJ}
        handleVkClick={handleVkClick}
        handleGoContractManufacturing={handleGoContractManufacturing}
      />
      <ImageGrid />
    </nav>
  );
};

export default About;
