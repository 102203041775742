import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cardsGoal } from "../../utils/cardsGoal";

import PageHelmet from "../PageHelmet";
import StepsSection from "../StepsSection/StepsSection";

import goal from "../../images/goal.webp";

const Goal = ({ headerHeight, handleGoBack, handleGoMain }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSelectGoal = (purpose) => {
    navigate(`/goals/${purpose}/`);
  };

  return (
    <section className="tastes" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title="Выберите свою цель | Fit and Joy"
        description="Подберите продукты Fit and Joy для достижения вашей цели: стройность, детокс, выносливость, красота кожи и волос, энергия и другие."
        keywords="здоровье, спортивное питание, цели, стройность, детокс, энергия, фитнес, БАДы, витамины, протеин"
        image={goal}
        canonicalUrl="https://usafitandjoy.com/goals/"
      />

      <div className="tastes__wrapper">
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={goal}
              alt="продукты для цели Fit and Joy"
            />
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoBack}
          >
            Назад
          </button>
        </div>
        <div className="tastes__text">
          <h1 className="tastes__title">
            Выберите идеальные продукты Fit and Joy для вашей цели!
          </h1>
          <p className="tastes__content">
            Ваша цель — ваше вдохновение! Поддержите организм и достигайте
            лучших результатов с продуктами, созданными специально для ваших
            потребностей
            <br />
            Независимо от того, хотите ли вы повысить выносливость, улучшить
            фигуру, провести детокс или позаботиться о здоровье кожи и волос — у
            нас есть решение для вас!
            <br /> <br />
            🔹 Стройность — лёгкость и комфорт в вашем теле.
            <br />
            🔹 Детокс — очищение и восстановление организма.
            <br />
            🔹 Для мужчин — поддержка мужского здоровья и силы.
            <br />
            🔹 Для женщин — забота о женском организме.
            <br />
            🔹 Спокойствие — гармония и расслабление в ритме дня.
            <br />
            🔹 Кожа и волосы — естественная красота и сияние.
            <br />
            🔹 Выносливость — увеличьте свою физическую и умственную
            устойчивость, добивайтесь большего без усталости.
            <br />
            🔹 Энергия — бодрость и активность в течение дня, максимум сил для
            новых свершений.
            <br /> <br />✨ Сделайте шаг к своей цели — выберите продукт,
            который вам подходит!
          </p>
        </div>

        <ul className="tastes__container">
          {cardsGoal.map((data, index) => (
            <li key={index} className="tastes__slide">
              <img
                className="tastes__slide-img"
                src={data.img}
                alt={`Продукты для ${data.name}`}
                title={`Продукты для ${data.nameRus}`}
                onClick={() => handleSelectGoal(data.purpose)}
              />
            </li>
          ))}
        </ul>
        <p className="tastes__instruction">
          Нажмите на изображение , чтобы увидеть все продукты Fit and Joy для
          вашей цели.
        </p>
        <StepsSection />
      </div>
    </section>
  );
};

export default Goal;
