export const vitaminsProducts = {
    'l-carnitine': {
        categoryName: "витамины и минералы",
        title: 'L-Карнитин в капсулах',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>L-Карнитин – это аминокислота, которая играет ключевую роль в метаболизме жиров, превращая их в энергию. Поддерживает выносливость, способствует жиросжиганию и помогает восстановлению после физических нагрузок.</p>
                <p>Подходит для спортсменов и людей, ведущих активный образ жизни.</p>
            </>
        ),
        price: '750',
        taste: 'Без вкуса',
        textPurpose: 'Сжигание жира, Повышение выносливости, Восстановление',
        purposes: ['энергия', 'жиросжигание', 'стройность', 'поддержка метаболизма'],
        composition: 'L-карнитина тартрат, желатиновая капсула (загуститель желатин, вода, краситель пищевой), носитель мальтодекстрин.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['carnitine1.webp', 'carnitine2.webp', 'carnitine3.webp', 'carnitine4.webp'],
        img: 'carnitine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях сердечно-сосудистой системы.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 2 капсулы 1 раз в день, запивая водой.</p>
                <p>Рекомендуемый курс приема: 1-2 месяца.</p>
            </>
        ),
    },

    'l-carnitine-liquid': {
        categoryName: "витамины и минералы",
        title: 'L-Карнитин жидкий, без сахара',
        productType: 'Пищевая добавка. Жидкость.',
        description: (
            <>
                <p>Жидкий L-Карнитин со вкусом яблока– удобная форма для быстрого усвоения. Способствует ускоренному сжиганию жира и увеличению выносливости во время тренировок.</p>
                <p>Идеально подходит для тех, кто хочет быстро привести себя в форму.</p>
            </>
        ),
        price: '1350',
        taste: 'Яблоко',
        textPurpose: 'Сжигание жира, Повышение выносливости, Восстановление',
        purposes: ['энергия', 'ускоренный метаболизм', 'жиросжигание', 'стройность'],
        composition: 'L-карнитина тартрат, вода, натуральные ароматизаторы, лимонная кислота.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в холодильнике после вскрытия.',
        gender: 'Подходит для мужчин и женщин',
        images: ['carnitineLiquid1.webp', 'carnitineLiquid2.webp', 'carnitineLiquid3.webp', 'carnitineLiquid4.webp'],
        img: 'carnitineLiquid1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при гипертонии и сердечно-сосудистых заболеваниях.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>20 мл продукта растворить в 50 — 100 мл воды по вкусу
                    <br />
                    принимать 1-3 порции день за 20 30 минут до физической нагрузки
                    <br />
                    допускается принимать продукт неразбавленным
                    <br />в комплекте мерный стаканчик: одна порция— 2 мерных стаканчика</p>
                <br />
                <p>Перед употреблением встряхнуть.</p>
                <br />
                <p>Хранить в холодильнике после вскрытия.</p>
                <br />
            </>
        ),
    },

    'magnesium-b6': {
        categoryName: "витамины и минералы",
        title: 'Магний B6 – для нервной системы и мышц',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Магний B6 помогает поддерживать нормальную работу нервной системы, снижает стресс и усталость. Улучшает сон, укрепляет сердечно-сосудистую систему.</p>
            </>
        ),
        price: '700',
        taste: 'Без вкуса',
        textPurpose: 'Снятие стресса, Здоровье сердца, Восстановление нервной системы',
        purposes: ['поддержка нервной системы', 'снижение усталости', 'улучшение сна', 'здоровье'],
        composition: 'Магний, мальтодекстрин, витамин В6, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В сухом месте, при температуре до 25°C.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Mgn1.webp', 'Mgn2.webp', 'Mgn3.webp'],
        img: 'Mgn1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях почек.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле в день во время еды.</p>
                <p>Курс – 1 месяц, при необходимости повторить.</p>
            </>
        ),
    },
    'zma': {
        categoryName: "витамины и минералы",
        title: 'ZMA – Минеральный комплекс для спортсменов',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>ZMA – это комплекс цинка, магния и витамина B6, который помогает улучшить сон, повысить уровень тестостерона и ускорить восстановление мышц.</p>
            </>
        ),
        price: '800',
        taste: 'Без вкуса',
        textPurpose: 'Восстановление, Поддержка мышц, Повышение тестостерона',
        purposes: ['улучшение сна', 'ускорение восстановления', 'повышение уровня тестостерона', 'выносливость'],
        composition: 'Мальтодекстрин, L-аспарагиновая кислота, магния цитрат, цинка цитрат, витамин В6, желатиновая капсула (загуститель желатин, вода, краситель пищевой). ',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в оригинальной упаковке, избегая попадания влаги.',
        gender: 'Мужской',
        images: ['ZMA1.webp', 'ZMA2.webp', 'ZMA3.webp'],
        img: 'ZMA1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях печени и почек.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды .</p>
                <p>Рекомендуемый курс – 6 месяцев.</p>
            </>
        ),
    },
    'calcium-d3': {
        categoryName: "витамины и минералы",
        title: 'Кальций D3 – Здоровье костей и суставов',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Кальций D3 – это сочетание кальция и витамина D3, необходимое для здоровья костей, зубов и суставов. Улучшает усвоение кальция в организме и предотвращает его дефицит.</p>
            </>
        ),
        price: '750',
        taste: 'Без вкуса',
        textPurpose: 'Здоровье костей, Зубов, Поддержка суставов',
        purposes: ['укрепление костей', 'предотвращение остеопороза', 'поддержка суставов', 'здоровье кожи, волос, ногтей', 'здоровье'],
        composition: 'Кальция карбонат, мальтодекстрин, витамин D3, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Calcium1.webp', 'Calcium2.webp', 'Calcium3.webp'],
        img: 'Calcium1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при гиперкальциемии.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 6 месяцев, при необходимости повторить.</p>
            </>
        ),
    },
    'vision-vitamins': {
        categoryName: "витамины и минералы",
        title: 'Витамины для зрения – Поддержка глаз и защита сетчатки',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Комплекс витаминов для зрения с экстрактом черники, витаминами A, C, E, лютеином и рутином. Защищает глаза от усталости и негативного воздействия окружающей среды.</p>
            </>
        ),
        price: '800',
        taste: 'Без вкуса',
        textPurpose: 'Здоровье глаз, Защита сетчатки, Снижение усталости глаз',
        purposes: ['улучшение остроты зрения', 'защита от экранного напряжения', 'поддержка глазных сосудов', 'здоровье'],
        composition: 'Мальтодекстрин, экстракт черники, витамин С (L-аскорбиновая кислота), витамин Р (рутин), витамин Е (D-α-токоферол), цитрат цинка, лютеин, витамин В6 (пиридоксина гидрохлорид), витамин В1 (тиамина гидрохлорид), витамин В2 (рибофлавин), витамин А (ретинол), селенит натрия, хрома пиколинат, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом темном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['vision.webp', 'vision2.webp', 'vision3.webp', 'vision4.webp'],
        img: 'vision.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при аллергии на чернику.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 6 месяцев. При необходимости прием можно повторить</p>
            </>
        ),
    },
    'vitamins-men': {
        categoryName: "витамины и минералы",
        title: 'Витаминный комплекс для мужчин – Энергия и сила',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Комплекс витаминов и минералов, разработанный специально для мужского организма. Улучшает обмен веществ, повышает уровень энергии и поддерживает иммунитет.</p>
            </>
        ),
        price: '900',
        taste: 'Без вкуса',
        textPurpose: 'Энергия, Поддержка здоровья, Восстановление',
        purposes: ['энергия', 'для мужчин', 'укрепление иммунитета', 'повышение работоспособности', 'защита клеток от повреждений'],
        composition: 'Мальтодекстрин, карбонат кальция, витамин С (L-аскорбиновая кислота), магния цитрат, витамин В3 (ниацин), диоксид кремния, витамин Е (D-альфатокоферола ацетат), цитрат цинка, фумарат железа, витамин В5 (пантотеновая кислота), витамин В6 (пиридоксина гидрохлорид), альфа липоевая кислота, витамин В2 (рибофлавин), титамин В1 (тиамина гидрохлорид), экстракт пажитника, гинкго билоба растительный сухой экстракт, чай зеленый растительный сухой экстракт, экстракт виноградных косточек сухой, глюконат марганца, витамин А (ретинола ацетат), глюконат меди, экстракт черного перца, витамин В9 (фолиевая кислота), лютеин, порошок чеснока, йодид калия, витамин В12 (цианокобаламин), витамин K1 (филлохинон), селенит натрия, хрома пиколинат, витамин D3 (холекальциферол), желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'Хранить в оригинальной упаковке, избегая влаги.',
        gender: 'Мужской',
        images: ['men1.webp', 'men2.webp', 'men3.webp'],
        img: 'men1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях печени.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле в день во время еды.</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
    'vitamins-women': {
        categoryName: "витамины и минералы",
        title: 'Витаминный комплекс для женщин – Красота и здоровье',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Комплекс витаминов для женщин, включающий необходимые минералы, антиоксиданты и экстракты растений для поддержания здоровья, красоты и гормонального баланса.</p>
            </>
        ),
        price: '850',
        taste: 'Без вкуса',
        textPurpose: 'Здоровье кожи, волос и ногтей, Поддержка гормонального фона',
        purposes: ['для женщин', 'улучшение состояния кожи и волос', 'повышение энергии', 'укрепление иммунитета'],
        composition: 'Кальция карбонат, витамин С (L-аскорбиновая кислота), магния цитрат, мальтодекстрин, диоксид кремния, экстракт ромашки, фумарат железа, витамин В3 (никотинамид), витамин Е (D-альфатокоферола ацетат), цитрат цинка, пантотеновая кислота, клевер растительный сухой экстракт (5:1), экстракт корня макки сухой), экстракт дудника (дягель), дикий ямс растительный сухой экстракт, витамин Е (D-альфатокоферола ацетат), лютеин, гиалуроновая кислота, глюконат марганца, витамин В1 (пиридоксина гидрохлорид), витамин В2 (рибофлавин), альфа липоевая кислота, витамин А (ретинол ацетат), глюконат меди, витамин В9 (фолиевая кислота), витамин В12 (цианокобаламин), йодид калия, витамин К1 (филлохинон), селенит натрия, хрома пиколинат, витамин D3 (холекальциферол), желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в защищенном от солнца месте.',
        gender: 'Женский',
        images: ['women1.webp', 'women2.webp', 'women3.webp', 'women4.webp'],
        img: 'women1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>При беременности и кормлении грудью перед применением проконсультироваться с врачом.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 6 месяцев.</p>
            </>
        ),
    },
    'iodine': {
        categoryName: "витамины и минералы",
        title: 'Йод – Поддержка щитовидной железы',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Йод – важнейший микроэлемент, необходимый для нормальной работы щитовидной железы и поддержания гормонального баланса.</p>
            </>
        ),
        price: '650',
        taste: 'Без вкуса',
        textPurpose: 'Здоровье щитовидной железы, Поддержка гормонального фона',
        purposes: ['нормализация обмена веществ', 'поддержка эндокринной системы', 'здоровье'],
        composition: 'Мальтодекстрин, калия йодид, железа фумарат, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в оригинальной упаковке.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Iodine1.webp', 'Iodine2.webp', 'Iodine3.webp'],
        img: 'Iodine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день после еды.</p>
                <p>Курс приёма 6 месяцев</p>
            </>
        ),
    },
    'glycine': {
        categoryName: "витамины и минералы",
        title: 'Глицин – Поддержка нервной системы',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Глицин от Fit and Joy – это натуральная аминокислота, которая помогает снять стресс, улучшает сон и поддерживает умственную активность.</p>
                <p>Идеально подходит для людей с высоким уровнем стресса, студентов и тех, кто работает в условиях умственного напряжения.</p>
            </>
        ),
        price: '600',
        taste: 'Без вкуса',
        textPurpose: 'Снижение стресса, Улучшение сна, Поддержка мозга',
        purposes: ['снижение тревожности', 'улучшение концентрации', 'поддержка памяти', 'для женщин', 'для мужчин', 'здоровье', 'спокойствие'],
        composition: 'Глицин, мальтодекстрин, желатиновая капсула (загуститель желатин, вода, краситель пищевой). ',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в сухом месте при температуре до 25°C.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Glycine1.webp', 'Glycine2.webp', 'Glycine3.webp'],
        img: 'Glycine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при гипотонии (пониженном давлении).</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день после еды.</p>
                <p>Курс приема 6 месяцев </p>
            </>
        ),
    },
    'liver-vitamins': {
        categoryName: "витамины и минералы",
        title: 'Витамины для печени – Очищение и защита',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Комплекс для печени от Fit and Joy – это сочетание растительных экстрактов, которые помогают очищению печени и защите клеток от токсинов.</p>
                <p>Рекомендуется для людей, ведущих активный образ жизни, а также при повышенной нагрузке на печень.</p>
            </>
        ),
        price: '850',
        taste: 'Без вкуса',
        textPurpose: 'Детокс, Очищение печени, Поддержка ЖКТ',
        purposes: ['восстановление клеток печени', 'поддержка пищеварения', 'выведение токсинов', 'здоровье'],
        composition: 'растительные экстракты (расторопши, ромашки, корня одуванчика, листа артишока, куркумы, имбиря, черного перца), мальтодекстрин, клетчатка соевая, магния стеарат, кремния диоксид, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['liver1.webp', 'liver2.webp', 'liver3.webp'],
        img: 'liver1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при желчнокаменной болезни.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 6 месяцев.</p>
            </>
        ),
    },
    'hangover-vitamins': {
        categoryName: "витамины и минералы",
        title: 'Витамины от похмелья – Восстановление после алкоголя',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Fit and Joy разработал комплекс с натуральными экстрактами для быстрого восстановления организма после употребления алкоголя.</p>
                <p>Помогает уменьшить симптомы похмелья, ускоряет вывод токсинов и поддерживает баланс витаминов.</p>
            </>
        ),
        price: '700',
        taste: 'Без вкуса',
        textPurpose: 'Детокс, Восстановление, Снятие похмельного синдрома',
        purposes: ['выведение токсинов', 'восстановление водно-солевого баланса', 'поддержка печени', 'здоровье', 'детокс'],
        composition: 'Экстракты растительные (чабреца, ромашки, мяты, душицы, пустырника), мальтодекстрин, янтарная кислота, витамин С, витамин В3, витамин В5, витамин В6, витамин В2, витамин В1, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в оригинальной упаковке.',
        gender: 'Подходит для мужчин и женщин',
        images: ['hangovers1.webp', 'hangovers2.webp', 'hangovers3.webp'],
        img: 'hangovers1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при хронических заболеваниях печени.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Заблаговременная подготовка организма: по одной капсуле 3 раза в день; за два часа до застолья и на следующий день после</p>
                <p>Экстренная подготовка организма: 2 капсулы после еды; за 3 часа до застолья и на следующий день повторить прием утром и вечером</p>
            </>
        ),
    },
    'menopause-vitamins': {
        categoryName: "витамины и минералы",
        title: 'Витамины при климаксе – Гормональный баланс и поддержка женского здоровья',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Fit and Joy разработал натуральный комплекс с экстрактами растений для поддержки женщин в период менопаузы.</p>
                <p>Снимает симптомы климакса, улучшает эмоциональное состояние и поддерживает гормональный баланс.</p>
            </>
        ),
        price: '900',
        taste: 'Без вкуса',
        textPurpose: 'Гормональный баланс, Поддержка нервной системы, Укрепление костей',
        purposes: ['снижение приливов', 'улучшение самочувствия', 'баланс гормонов', 'для женщин'],
        composition: 'Мальтодекстрин, экстракты растительные (дикого ямса, дудника, корня маки, ананаса, клевера лугового), магния цитрат, витамин В3, витамин Е, цинка цитрат, витамин В5, марганца глюконат, витамин В6, меди глюконат, витамин В2, витамин В9, натрия селенит, желатиновая капсула (загуститель желатин, вода, краситель пищевой)..',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Женский',
        images: ['menopause1.webp', 'menopause2.webp', 'menopause3.webp'],
        img: 'menopause1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Перед применением проконсультируйтесь с врачом.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день после еды.</p>
                <p>Курс – 6 месяцев.</p>
            </>
        ),
    },
    'hair-vitamins': {
        categoryName: "витамины и минералы",
        title: 'Витамины для волос и ногтей – Сила и красота',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Витамины для волос от Fit and Joy – это уникальная формула, разработанная для питания, укрепления и ускорения роста волос.</p>
                <p>Комплекс содержит коллаген, витамины и экстракты растений, которые поддерживают здоровье кожи головы и предотвращают выпадение волос.</p>
            </>
        ),
        price: '950',
        taste: 'Без вкуса',
        textPurpose: 'Красота и здоровье, Укрепление волос, Защита от выпадения',
        purposes: ['улучшение структуры волос', 'ускорение роста', 'питание волосяных фолликулов', 'здоровье кожи, волос, ногтей'],
        composition: 'Глюкозамина сульфат, мальтодекстрин, экстракт сои, витамин С, экстракт ананаса, коллаген говяжий, витамин Е, витамин В3, витамин В5, витамин В6, витамин В2, витамин В9, витамин Д3, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Женский',
        images: ['hair1.webp', 'hair2.webp', 'hair3.webp'],
        img: 'hair1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Перед применением рекомендуется консультация специалиста.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день после еды.</p>
                <p>Курс – 6 месяцев.</p>
            </>
        ),
    },
    'gaba': {
        categoryName: "витамины и минералы",
        title: 'GABA – Спокойствие и крепкий сон',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>GABA от Fit and Joy – это источник гамма-аминомасляной кислоты, которая способствует расслаблению, улучшает качество сна и помогает справляться со стрессом.</p>
                <p>Идеально подходит для людей с высоким уровнем тревожности или проблемами со сном.</p>
            </>
        ),
        price: '800',
        taste: 'Без вкуса',
        textPurpose: 'Снятие стресса, Улучшение сна, Поддержка нервной системы',
        purposes: ['снижение тревожности', 'улучшение засыпания', 'повышение устойчивости к стрессу', 'здоровье', 'спокойствие'],
        composition: 'Гамма-аминомасляная кислота, мальтодекстрин, глицин, витамин В6, желатиновая капсула (загуститель желатин, вода, краситель пищевой)',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в темном и сухом месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['GABA1.webp', 'GABA2.webp', 'GABA3.webp'],
        img: 'GABA1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях нервной системы.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день.</p>
                <p>Курс – 6 месяцев.</p>
                <p>При необходимости прием можно повторить</p>
            </>
        ),
    },
    '5htp': {
        categoryName: "витамины и минералы",
        title: '5-HTP – Натуральная поддержка настроения',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Fit and Joy предлагает 5-HTP – аминокислоту, которая способствует выработке серотонина, улучшает настроение и помогает бороться с депрессией.</p>
                <p>Идеально для людей, испытывающих стресс и эмоциональные перегрузки.</p>
            </>
        ),
        price: '850',
        taste: 'Без вкуса',
        textPurpose: 'Поддержка нервной системы, Стабилизация настроения, Улучшение сна',
        purposes: ['повышение уровня серотонина', 'уменьшение тревожности', 'регуляция сна', 'спокойствие'],
        composition: 'Мальтодекстрин, 5-НТР (5-гидрокситриптофан) из семян Griffonia, магния цитрат, витамин В6, желатиновая капсула (загуститель желатин, вода, краситель пищевой)',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'Хранить в оригинальной упаковке, избегая влаги.',
        gender: 'Подходит для мужчин и женщин',
        images: ['HTP1.webp', 'HTP2.webp', 'HTP3.webp'],
        img: 'HTP1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при приеме антидепрессантов.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день.</p>
                <p>Курс – 6 месяцев.</p>
            </>
        ),
    },
    'chaga': {
        categoryName: "витамины и минералы",
        title: 'Чага – Натуральный иммуномодулятор',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Чага от Fit and Joy – это природный источник антиоксидантов, который укрепляет иммунитет, защищает организм от вирусов и помогает при хронических воспалениях.</p>
                <p>Подходит для поддержания общего здоровья и профилактики простудных заболеваний.</p>
            </>
        ),
        price: '750',
        taste: 'Без вкуса',
        textPurpose: 'Иммунная защита, Поддержка ЖКТ, Антиоксидантная защита',
        purposes: ['укрепление иммунитета', 'снятие воспалений', 'поддержка пищеварения', 'здоровье'],
        composition: 'Экстракт чаги натуральной березовой, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Chaga1.webp', 'Chaga2.webp', 'Chaga3.webp', 'Chaga4.webp'],
        img: 'Chaga1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Осторожно при аутоиммунных заболеваниях.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день во время еды.</p>
                <p>Курс – 1 месяц.</p>
                <p>При необходимости приём можно повторить</p>
            </>
        ),
    },
    'cordyceps': {
        categoryName: "витамины и минералы",
        title: 'Кордицепс – Энергия и выносливость',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Кордицепс от Fit and Joy – это мощный природный адаптоген, который повышает уровень энергии, выносливость и иммунитет.</p>
                <p>Идеально подходит для спортсменов и активных людей.</p>
            </>
        ),
        price: '870',
        taste: 'Без вкуса',
        textPurpose: 'Энергия, Восстановление, Повышение иммунитета',
        purposes: ['поддержка физической активности', 'повышение работоспособности', 'здоровье', 'энергия'],
        composition: 'Мицелий кордицепса милитарис (cordyceps militaris) - 100%, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Cordyceps1.webp', 'Cordyceps2.webp', 'Cordyceps3.webp'],
        img: 'Cordyceps1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 2 капсулы 2 раза в день во время еды</p>
                <p>Курс приёма один месяц</p>
                <p>При необходимости прием можно повторить</p>
            </>
        ),
    },
    'hericium': {
        categoryName: "витамины и минералы",
        title: 'Ежовик – Натуральная поддержка мозга и нервной системы',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Ежовик гребенчатый от Fit and Joy – это мощный адаптоген, который улучшает память, концентрацию и поддерживает здоровье нервной системы.</p>
                <p>Идеален для людей с высоким умственным напряжением и тех, кто хочет повысить когнитивные способности.</p>
            </>
        ),
        price: '950',
        taste: 'Без вкуса',
        textPurpose: 'Поддержка мозга, Концентрация, Улучшение памяти',
        purposes: ['улучшение когнитивных функций', 'защита нервных клеток', 'повышение концентрации', 'здоровье'],
        composition: 'Мицелий ежовика гребенчатого 100%, желатиновая капсула (загуститель желатин, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Hedgehog1.webp', 'Hedgehog2.webp', 'Hedgehog3.webp', 'Hedgehog4.webp'],
        img: 'Hedgehog1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Осторожно при аутоиммунных заболеваниях.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 3 капсулы в 1 половине дня за 30 минут до приёма пищи</p>
                <p>Курс – 1,5-2 месяца.</p>
            </>
        ),
    },
    'fat-burner': {
        categoryName: "витамины и минералы",
        title: 'Жиросжигатель в капсулах – Активное снижение веса',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Жиросжигатель от Fit and Joy – это мощный комплекс, который помогает ускорить обмен веществ и повысить эффективность тренировок.</p>
                <p>Подходит для тех, кто хочет быстрее добиться стройного тела.</p>
            </>
        ),
        price: '890',
        taste: 'Без вкуса',
        textPurpose: 'Снижение веса, Ускорение метаболизма, Повышение энергии',
        purposes: ['жиросжигание', 'стройность', 'ускорение обмена веществ', 'повышение выносливости'],
        composition: 'Л-карнитин, стеарат магния, мальтодекстрин,кальций карбонат, аскорбиновая кислота,; экстракт ананаса, экстракт гуараны, экстракт зеленого чая; экстракт гарцинии, альфа-липоевая кислота,; желатиновая капсула (желатин, загуститель, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от света месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['FatCapsules1.webp', 'FatCapsules2.webp', 'FatCapsules3.webp'],
        img: 'FatCapsules1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при сердечно-сосудистых заболеваниях.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Взрослым по одной капсуле 3 раза в день во время еды</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
    'taurine': {
        categoryName: "витамины и минералы",
        title: 'Таурин – Энергия и поддержка работы мозга',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Таурин от Fit and Joy – это аминокислота, которая поддерживает здоровье сердечно-сосудистой системы, улучшает концентрацию и повышает выносливость.</p>
                <p>Идеально подходит для спортсменов и людей с активным образом жизни.</p>
            </>
        ),
        price: '800',
        taste: 'Без вкуса',
        textPurpose: 'Энергия, Поддержка сердца, Улучшение концентрации',
        purposes: ['увеличение выносливости', 'поддержка нервной системы', 'снижение усталости', 'энергия', 'здоровье'],
        composition: 'Таурин, желатиновая капсула желатин, загуститель, вода, краситель пищевой',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом месте, при температуре не выше 25°C.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Taurine1.webp', 'Taurine2.webp', 'Taurine3.webp'],
        img: 'Taurine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Осторожно при гипертонии.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день во время еды.</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
    'collagen': {
        categoryName: "витамины и минералы",
        title: 'Коллаген – Красота кожи, суставов и волос',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Коллаген от Fit and Joy – это источник гидролизованного белка, который поддерживает эластичность кожи, укрепляет волосы и ногти, а также улучшает здоровье суставов.</p>
                <p>Подходит для тех, кто хочет продлить молодость и сохранить подвижность суставов.</p>
            </>
        ),
        price: '990',
        taste: 'Без вкуса',
        textPurpose: 'Красота и здоровье, Поддержка суставов, Укрепление волос',
        purposes: ['улучшение состояния кожи', 'укрепление ногтей и волос', 'поддержка суставов', 'здоровье кожи, волос, ногтей', 'для женщин'],
        composition: 'Гидролизованный говяжий коллаген I и III типа, аскорбиновая кислота, мальтодекстрин, желатиновая капсула (загуститель желатин, вода, краситель пищевой). ',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Collagen1.webp', 'Collagen2.webp', 'Collagen3.webp'],
        img: 'Collagen1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при аллергии на белок.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 2 капсулы 3 раза в день во время еды</p>
                <p>Курс – 1 месяца.</p>
                <p>При необходимости прием можно повторить</p>
            </>
        ),
    },
    'marine-collagen': {
        categoryName: "витамины и минералы",
        title: 'Коллаген морской БАД – Красота и молодость',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Морской коллаген от Fit and Joy – это мощная поддержка для кожи, суставов и волос. Улучшает эластичность кожи, уменьшает морщины и укрепляет ногти.</p>
                <p>Содержит гиалуроновую кислоту и витамин C для максимального усвоения и действия.</p>
            </>
        ),
        price: '820',
        taste: 'Без вкуса',
        textPurpose: 'Омоложение, Укрепление суставов, Красота кожи',
        purposes: ['поддержка эластичности кожи', 'укрепление волос и ногтей', 'здоровье суставов', 'для женщин', 'здоровье кожи, волос, ногтей'],
        composition: 'Гидролизированный морской коллаген II типа, гиалуроновая кислота, аскорбиновая кислота, желатиновая капсула (загуститель желатин, вода, краситель пищевой диоксид титана).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CollagenMarine1.webp', 'CollagenMarine2.webp', 'CollagenMarine3.webp', 'CollagenMarine4.webp'],
        img: 'CollagenMarine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при аллергии на морепродукты.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
    'burdock': {
        categoryName: "витамины и минералы",
        title: 'Лопух – Очищение и поддержка печени',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Лопух от Fit and Joy – это природный детокс-компонент, который способствует очищению организма, поддерживает здоровье печени и улучшает пищеварение.</p>
                <p>Благодаря натуральным экстрактам помогает мягко выводить токсины и поддерживать нормальный обмен веществ.</p>
            </>
        ),
        price: '750',
        taste: 'Без вкуса',
        textPurpose: 'Детокс, Поддержка печени, Улучшение пищеварения',
        purposes: ['выведение токсинов', 'укрепление печени', 'поддержка ЖКТ', 'здоровье', 'детокс'],
        composition: 'Экстракт корня лопуха 100%, желатиновая капсула.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от света месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Burdock1.webp', 'Burdock2.webp', 'Burdock3.webp', 'Burdock4.webp'],
        img: 'Burdock1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при желчнокаменной болезни.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Взрослым по одной капсуле 3 раза в день во время еды.
                    <br />
                    При увеличении порции до 2 капсул 3 раза в день во время еды эффективность препарата усиливается</p>
                <p>Курс – 1,5-2 месяца.</p>
            </>
        ),
    },
    'chanterelles': {
        categoryName: "витамины и минералы",
        title: 'Лисички – Натуральная защита от паразитов',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Лисички от Fit and Joy – это природный источник бета-глюканов, которые помогают укрепить иммунитет и защищают организм от паразитов.</p>
                <p>Эффективный натуральный антипаразитарный комплекс без побочных эффектов.</p>
            </>
        ),
        price: '890',
        taste: 'Без вкуса',
        textPurpose: 'Антипаразитарная защита, Иммунная поддержка, Очищение организма',
        purposes: ['укрепление иммунитета', 'натуральная защита от паразитов', 'поддержка ЖКТ', 'детокс', 'здоровье'],
        composition: 'Лисички грибы, экстракт корня осины, экстракт пижмы, гвоздика, желатиновая капсула (загуститель желатин, вода, краситель пищевой)',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в оригинальной упаковке, избегая попадания влаги.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Chanterelles1.webp', 'Chanterelles2.webp', 'Chanterelles3.webp'],
        img: 'Chanterelles1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях ЖКТ в стадии обострения.</p>
                <p>Минимальный возраст от 18 лет</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 2 раза в день во время еды.</p>
                <p>Курс – 1,5-2 месяца.</p>
            </>
        ),
    },
};
