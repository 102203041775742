import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BaseHelmet from "./BaseHelmet";

const siteUrl = process.env.REACT_APP_SITE_URL || "http://localhost:3000";
const logoUrl = process.env.REACT_APP_LOGO_URL || `${siteUrl}/images/logo.webp`;

const PageHelmet = ({
  title = "Fit and Joy — натуральное спортивное питание и БАДы",
  description = "Спортивное питание и БАДы для здоровья и активного образа жизни.",
  keywords = "спортивное питание, БАДы, протеин, витамины, здоровье, фитнес",
  image = logoUrl,
  canonicalUrl,
  structuredData,
}) => {
  const location = useLocation();
  const canonical =
    canonicalUrl || `${siteUrl}${location.pathname.replace(/\/?$/, "/")}`;

  const defaultStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Fit and Joy",
    description:
      "Fit and Joy — ваш надежный партнер в мире здорового питания и спортивного образа жизни.",
    url: siteUrl,
    logo: logoUrl,
    sameAs: [
      "https://www.instagram.com/usafitandjoy",
      "https://t.me/fitandjoy",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer service",
      areaServed: "RU",
      availableLanguage: "Russian",
      url: "https://t.me/loveourclients",
    },
  };

  return (
    <>
      <BaseHelmet />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={canonical} />
        <meta property="og:type" content="website" />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData || defaultStructuredData)}
          </script>
        )}
      </Helmet>
    </>
  );
};

export default PageHelmet;
