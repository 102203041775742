export const nutBarsProducts = {
    'peanut-protein-bar': {
        categoryName: "ореховые батончики",
        title: 'Ореховый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик с арахисом в шоколаде от FIT AND JOY — это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает поддерживать форму и восполнять энергию.</p>
                <p>Побалуйте себя шоколадными сладостями без вреда для здоровья!</p>
            </>
        ),
        price: '900',
        tasteEng: 'peanut',
        taste: 'Арахис',
        energyValue: '128.7',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.2',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Энергия, восстановление, поддержка физической активности',
        purposes: ['энергия', 'восстановление', 'поддержка физической активности','быстрый перекус'],
        composition: 'Изолят соевого белка, арахис жареный, мальтодекстрин, рис экструдированный, изюм, патока карамельная, вода, какао-порошок, кондитерская глазурь (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        img: 'NPBPeanut1.webp', 
        images: ['NPBPeanut1.webp', 'NPBPeanut2.webp', 'NPBPeanut3.webp'],
   
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. дети до 14 лет:</b> разрешены в умеренных количествах.
                    В этом возрасте растущий организм требует больше белка, особенно при занятиях спортом. Протеиновые батончики могут быть хорошим дополнением к рациону.
                </p>
            </>
        )
    },
    'walnut-protein-bar': {
        categoryName: "ореховые батончики",
        title: 'Ореховый протеиновый батончик без сахара',
        productType: 'Протеиновый батончик',
        description: (
            <>
                <p>Спортивный батончик с Грецким орехом в шоколаде от FIT AND JOY — это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.</p>
                <p>В составе этой вкусняшки идеальное соотношение КБЖУ, низкое количество углеводов и высокое содержание белка за счет наличия протеина. Все это делает наш батончик очень полезным, низкоуглеводным и высокобелковым, что помогает поддерживать форму и восполнять энергию.</p>
                <p>Побалуйте себя шоколадными сладостями без вреда для здоровья!</p>
            </>
        ),
        price: '900',
        tasteEng: 'walnut',
        taste: 'Грецкий орех',
        energyValue: '128.7',
        nutritionalValueProteins: '13.2',
        nutritionalValueFats: '6.2',
        nutritionalValueCarbohydrates: '4',
        textPurpose: 'Энергия, восстановление, поддержка физической активности',
        purposes: ['энергия', 'восстановление', 'для мужчин','здоровье кожи, волос, ногтей','быстрый перекус'],
        composition: 'Изолят соевого белка, арахис жареный, мальтодекстрин, рис экструдированный, изюм, патока карамельная, вода, какао-порошок, кондитерская глазурь (подсластитель мальтит, заменитель масла какао нетемперируемый лауринового типа, какао-порошок, эмульгатор лецитин, ароматизатор), кокосовое масло, сорбитол, глицерин, лимонная кислота, натуральные ароматизаторы, сорбат калия, стевиозид, лецитин, аскорбиновая кислота.',
        text: 'Без глютена, без сахара, без ГМО',
        country: 'Россия',
        expirationDate: '12',
        storageConditions: 'В защищенном от солнца помещении, в оригинальной упаковке, в прохладном темном месте',
        gender: 'Женский, Мужской',
        img: 'NPBWalnut1.webp', 
        images: ['NPBWalnut1.webp', 'NPBWalnut2.webp', 'NPBWalnut3.webp'],
    
        contraindications: (
            <>
                <p><b>1. Аллергические реакции:</b> Продукт содержит арахис, молочные компоненты и соевый белок, что может вызвать аллергию.</p>
                <p><b>2. Людям с диабетом:</b> Несмотря на отсутствие сахара, продукт содержит подсластители, которые могут повлиять на уровень глюкозы.</p>
                <p><b>3. Беременные и кормящие женщины:</b> Перед употреблением рекомендуется проконсультироваться с врачом.</p>
                <p><b>4. Маленькие дети:</b> Не рекомендуется детям младше 3 лет.</p>
                <p><b>5. дети до 14 лет:</b> разрешены в умеренных количествах.
                </p>
            </>
        )
    },
}