import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Tastes.css";
import { cardsTastes } from "../../utils/cardsTastes";

import PageHelmet from "../PageHelmet";

const Tastes = ({ headerHeight, handleGoBack, handleGoMain }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSlideClick = (taste) => {
    navigate(`/tastes/${taste}`);
  };

  return (
    <section className="tastes" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title="Выберите свой вкус | Fit and Joy"
        description="Найдите идеальный вкус среди протеиновых коктейлей, батончиков, коллагена и витаминов от Fit and Joy."
        keywords="вкусы, спортивное питание, БАДы, протеиновые батончики, протеиновые коктейли, витамины, коллаген, жиросжигатели, детокс"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/tastes/`}
        image={`https://usafitandjoy.com/images/tastes/tastes.webp`}
      />

      <div className="tastes__wrapper">
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={`${process.env.PUBLIC_URL}/images/tastes/tastes.webp`}
              alt="вкусы продуктов Fit and Joy"
            />
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoBack}
          >
            Назад
          </button>
        </div>
        <div className="tastes__text">
          <h1 className="tastes__title">
            Найдите свой идеальный вкус с Fit and Joy!
          </h1>
          <p className="tastes__content">
            Мы знаем, как важно не только питаться правильно, но и получать
            удовольствие от еды.
            <br />
            Поэтому мы создали широкий ассортимент протеиновых продуктов,
            витаминов, минералов, коллагена, жиросжигателей и детокс-средств в
            самых разнообразных вкусах.
            <br /> <br />
            Любите насыщенный шоколад?
            <br />
            Или предпочитаете свежие фруктовые сочетания — манго, киви,
            клубнику, лесные ягоды?
            <br />
            А может, хотите попробовать что-то необычное, как лотерея-вкус, где
            каждый раз — сюрприз?
            <br /> <br />
            Выбирайте продукты, которые соответствуют вашим предпочтениям, и
            наслаждайтесь вкусом здоровья!
          </p>
        </div>

        <ul className="tastes__container">
          {cardsTastes.map((data, index) => (
            <li key={index} className="tastes__slide">
              <img
                className="tastes__slide-img"
                src={`${process.env.PUBLIC_URL}/images/tastes/${data.img}`}
                alt={`Вкус ${data.name}`}
                title={`Попробуйте вкус ${data.nameRus}`}
                onClick={() => handleSlideClick(data.name)}
              />
            </li>
          ))}
        </ul>
        <p className="tastes__instruction">
          Нажмите на изображение фрукта, чтобы увидеть все продукты Fit and Joy
          с этим вкусом.
        </p>
      </div>
    </section>
  );
};

export default Tastes;
