import { useState } from "react";
import Fuse from "fuse.js";
import { useSearchData } from "../utils/searchData";

const options = {
  keys: ["title", "content"],
  includeMatches: true,
  threshold: 0.3,
  minMatchCharLength: 2,
  findAllMatches: true,
  ignoreLocation: true,
};

const useSearch = () => {

  const { searchData } = useSearchData();
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    localStorage.setItem("searchQuery", query);

    if (!query || query.trim().length < 2) {
      setSearchResults([]);
      return;
    }
    const fuse = new Fuse(searchData, options);
    const results = fuse.search(query).map(({ item }) => ({
      ...item,
      snippet: getHighlightedSnippet(item.content, query),
    }));

    localStorage.setItem("searchQuery", query);
    setSearchResults(results);
  };


  return { searchResults, handleSearch };
};

const getHighlightedSnippet = (text, query, snippetLength = 100) => {
  if (!text || !query) return "";

  const regex = new RegExp(`(${query})`, "gi");
  const matchIndex = text.toLowerCase().indexOf(query.toLowerCase());

  if (matchIndex === -1) {
    return text.length > snippetLength ? text.slice(0, snippetLength) + "..." : text;
  }

  let snippetStart = Math.max(0, matchIndex - snippetLength / 2);
  let snippetEnd = Math.min(text.length, matchIndex + query.length + snippetLength / 2);

  let snippet = text.substring(snippetStart, snippetEnd);
  if (snippetStart > 0) snippet = "..." + snippet;
  if (snippetEnd < text.length) snippet = snippet + "...";

  return snippet.replace(regex, '<span class="highlighted">$1</span>');
};

export default useSearch;
