import React from "react";
import { Link } from "react-router-dom";
import "./CategoriesBlog.css";
import { categoriesDataBlog } from "../../utils/categoriesDataBlog";

const CategoriesBlog = () => {
  return (
    <div className="blog-categories">
      {categoriesDataBlog.map((category, index) => (
        <div key={index} className="blog-category__card">
          <span className="blog-category__icon">{category.icon}</span>
          <h3 className="blog-category__title">{category.title}</h3>
          <p className="blog-category__description">{category.description}</p>

          <Link to={category.link} className="category-link">
            Читать статьи про {category.title.toLowerCase()} ➝
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CategoriesBlog;
