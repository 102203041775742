import React from "react";

import PageHelmet from "../PageHelmet";

import "./Blog.css";
import CategoriesBlog from "../CategoriesBlog/CategoriesBlog";
import ImageGrid from "../ImageGrid/ImageGrid";
import BMICalculator from "../BMICalculator/BMICalculator";

const Blog = ({ headerHeight, handleGoMain, handleGoBack }) => {
  return (
    <section className="blog" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title="Блог Fit and Joy | Все о здоровье, питании и тренировках"
        description="Читайте полезные статьи о здоровом образе жизни, правильном питании, тренировках и спортивных добавках."
        keywords="здоровый образ жизни, тренировки, спорт, питание, витамины, протеин"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/blog/`}
        image={`https://usafitandjoy.com/images/blog.webp`}
      />

      <div className="blog__wrapper">
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={`${process.env.PUBLIC_URL}/images/blog.webp`}
              alt="продукция jf"
            />
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoBack}
          >
            Назад
          </button>
        </div>
        <h1 className="blog__title">Добро пожаловать в блог Fit and Joy!</h1>
        <p className="blog__subtitle">
          Здесь мы рассказываем про здоровье, питание и тренировки.
        </p>
        <CategoriesBlog />
        <BMICalculator />
        <ImageGrid />
      </div>
    </section>
  );
};
export default Blog;
