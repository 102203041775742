import React from "react";
import { Helmet } from "react-helmet-async";

const siteUrl = process.env.REACT_APP_SITE_URL || "http://localhost:3000/";
const logoUrl =
  process.env.REACT_APP_LOGO_URL || "http://localhost:3000/images/logo.webp";

const BaseHelmet = () => (
  <Helmet>
    <meta name="author" content="Fit and Joy" />
    <meta
      name="copyright"
      content={`Copyright © ${new Date().getFullYear()} Fit and Joy`}
    />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="ru_RU" />
    <meta property="og:image" content={logoUrl} />
    <meta property="og:url" content={siteUrl} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="robots" content="index, follow" />
    <meta name="theme-color" content="#FFFFFF" />
    <link rel="alternate" hrefLang="ru" href={siteUrl} />

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Fit and Joy",
        url: siteUrl,
        logo: logoUrl,
        sameAs: [
          "https://www.instagram.com/usafitandjoy",
          "https://t.me/fitandjoy",
        ],
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "customer service",
          areaServed: "RU",
          availableLanguage: "Russian",
          url: "https://t.me/loveourclients",
        },
        copyrightYear: new Date().getFullYear(),
        publisher: {
          "@type": "Organization",
          name: "Fit and Joy",
          logo: logoUrl,
        },
      })}
    </script>
  </Helmet>
);

export default BaseHelmet;
