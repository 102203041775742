import React, { useState } from "react";
import "./ProductionRequestForm.css";

const ProductionRequestForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    contactMethod: "Телефон",
    phone: "+7",
    comment: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Имя обязательно для заполнения";
    } else if (formData.name.length < 2) {
      newErrors.name = "Имя должно содержать минимум 2 символа";
    } else if (formData.name.length > 50) {
      newErrors.name = "Имя должно содержать не более 50 символов";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Телефон обязателен для заполнения";
    } else if (!/^\+7\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Введите номер в формате +7XXXXXXXXXX";
    }

    if (!formData.agree) {
      newErrors.agree = "Вы должны согласиться с правилами и условиями";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (name === "phone") {
      const digits = value.replace(/\D/g, "");

      const last10 = digits.slice(-10);

      newValue = "+7" + last10;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const data = new FormData();
        data.append("name", formData.name);
        data.append("contactMethod", formData.contactMethod);
        data.append("phone", formData.phone);
        data.append("comment", formData.comment);

        const response = await fetch("https://usafitandjoy.com/sendMail.php", {
          // const response = await fetch("http://localhost:5000/send", {
          method: "POST",
          body: data,
        });

        if (!response.ok) throw new Error(`Ошибка HTTP: ${response.status}`);

        const result = await response.text();
        // console.log("Результат:", result);
        setSubmissionStatus(
          "✅Заявка отправлена! Мы скоро с вами свяжемся. 🎉 "
        );
        setSubmitted(true);

        setFormData({
          name: "",
          contactMethod: "Телефон",
          phone: "+7",
          comment: "",
          agree: false,
        });
      } catch (error) {
        // console.error("Ошибка при отправке формы:", error);
        setSubmissionStatus("❌ Ошибка при отправке формы. Попробуйте позже.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="request-form" onSubmit={handleSubmit}>
      <h3>Оставьте заявку</h3>

      <label>
        Имя:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={errors.name ? "input-error" : ""}
        />
        {errors.name && <div className="error-text">{errors.name}</div>}
      </label>

      <label>
        Предпочтительный способ связи:
        <select
          name="contactMethod"
          value={formData.contactMethod}
          onChange={handleChange}
        >
          <option value="Телефон">Телефон</option>
          <option value="Telegram">Telegram</option>
          <option value="WhatsApp">WhatsApp</option>
        </select>
      </label>

      <label>
        Номер телефона:
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className={errors.phone ? "input-error" : ""}
        />
        {errors.phone && <div className="error-text">{errors.phone}</div>}
      </label>

      <label>
        Комментарий (необязательно):
        <textarea
          name="comment"
          rows="3"
          placeholder="Напишите, если есть пожелания..."
          value={formData.comment}
          onChange={handleChange}
        />
      </label>

      <label className="checkbox-label">
        <input
          type="checkbox"
          name="agree"
          checked={formData.agree}
          onChange={handleChange}
        />
        Я согласен с{" "}
        <a href="/privacy-policy/" target="_blank" rel="noreferrer">
          политикой конфиденциальности
        </a>
      </label>

      {errors.agree && <div className="error-text">{errors.agree}</div>}

      {!submitted ? (
        <button className="btn btn__blue" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "⏳ Отправка..." : "Отправить"}
        </button>
      ) : null}

      {submissionStatus && (
        <p
          className={
            submissionStatus.startsWith("✅")
              ? "success-message"
              : "error-text__status"
          }
        >
          {submissionStatus}
        </p>
      )}
    </form>
  );
};

export default ProductionRequestForm;
