import React from "react";
import "./PodarokStepper.css";

const PodarokStepper = ({
  handleTelegramClickJF,
  handleVkClick,
  handleTelegramClickSupport,
  handleGoStore,
  scrollToForm,
}) => {
  const steps = [
    {
      title: "Покупка",
      icon: "🛒",
      description: (
        <>
          Приобретите нашу продукцию на{" "}
          <span className="clickable" onClick={handleGoStore}>
            маркетплейсах
          </span>
        </>
      ),
    },
    {
      title: "Отзыв",
      icon: "✍️",
      description: "Напишите честный отзыв на платформе покупки.",
    },
    {
      title: "Подписка",
      icon: "📢",
      description: (
        <>
          Подпишитесь на наш{" "}
          <span className="clickable" onClick={handleTelegramClickJF}>
            Telegram
          </span>{" "}
          и{" "}
          <span className="clickable" onClick={handleVkClick}>
            ВКонтакте
          </span>
          .
        </>
      ),
    },
    {
      title: "Скриншоты",
      icon: "📷",
      description: (
        <>
          Отправьте скриншоты через{" "}
          <span className="clickable" onClick={scrollToForm}>
            форму
          </span>{" "}
          или в{" "}
          <span className="clickable" onClick={handleTelegramClickSupport}>
            Telegram
          </span>
          .
        </>
      ),
    },
  ];

  return (
    <div className="podarok-stepper">
      {steps.map((step, index) => (
        <div key={index} className={`podarok-stepper__step ${index === 0 ? "active" : ""}`}>
          <div className="podarok-stepper__step-icon">{step.icon}</div>
          <div className="podarok-stepper__step-title">{step.title}</div>
          <div className="podarok-stepper__step-description">{step.description}</div>
          {index < steps.length - 1 && <div className="podarok-stepper__step-line"></div>}
        </div>
      ))}
    </div>
  );
};

export default PodarokStepper;
