import React from "react";
import { useParams } from "react-router-dom";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import PageHelmet from "../PageHelmet";
import ArticleList from "../ArticleList/ArticleList";
import { articlesData } from "../../utils/articlesData";

const CategoryBlog = ({ headerHeight, handleGoBlog }) => {
  const { category } = useParams();
  const categoryArticles = articlesData[category];

  return (
    <>
      <PageHelmet
        title={`${
          categoryArticles
            ? categoryArticles.categoryName
            : "Категория не найдена"
        } | Блог Fit and Joy`}
        description={
          categoryArticles
            ? `Читайте статьи по теме "${categoryArticles.categoryName}" в блоге Fit and Joy.`
            : "Страница не найдена"
        }
        keywords={
          categoryArticles
            ? `${categoryArticles.categoryName}, здоровье, питание, спорт, фитнес`
            : ""
        }
        canonicalUrl={`https://usafitandjoy.com/${category}/`}
      />

      <div style={{ textAlign: "center", padding: "20px" }}>
        {categoryArticles ? (
          <ArticleList
            category={category}
            headerHeight={headerHeight}
            handleGoBlog={handleGoBlog}
          />
        ) : (
          <NotFoundPage
            headerHeight={headerHeight}
            handleGoMain={handleGoBlog}
            handleGoBack={() => window.history.back()}
          />
        )}
      </div>
    </>
  );
};

export default CategoryBlog;
