export const collagenProducts = {
    'collagen-mango': {
        title: 'Колаген Порошок для волос, кожи, ногтей и суставов Japan Formula, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген от Fit and Joy - </p>
            </>
        ),
        tasteEng: 'mango',
        taste: 'Манго',
        price: '650',
        textPurpose: 'для похудения, поддержания диеты и утоления голода; БАД',
        composition: 'Коллаген говяжий, молочная сыворотка (подтворожная), сухое молоко 1.5%, молочная сыворотка (подсырная), мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, витамин С, стевиозид, витамин В3, пищевой краситель, витамин Е, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3, гиалуроновая кислота.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'для женщин', 'энергия'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: ['CMango1.webp', 'CMango2.webp', 'CMango3.webp'],
        img: 'CMango1.webp',
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Манго" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-pineapple': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок для здоровья кожи, волос и суставов от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Ананас" от Fit and Joy – это уникальная формула, которая поддерживает здоровье кожи, волос и суставов.</p>
                <p>Содержит витамин C и гиалуроновую кислоту, способствующие омоложению и укреплению соединительных тканей.</p>
            </>
        ),
        price: '650',
        tasteEng: 'pineapple',
        taste: 'Ананас',
        textPurpose: 'Красота и здоровье, Поддержка суставов, Омоложение',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, мальтодекстрин, кукурузный крахмал, экстракт ананаса, витамин C, стевиозид, гиалуроновая кислота, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'поддержка суставов', 'укрепление ногтей', 'для женщин', 'здоровье кожи, волос, ногтей', 'энергия'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CPineapple1.webp', 'CPineapple2.webp', 'CPineapple3.webp'],
        img: 'CPineapple1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Аллергия на ананас или белки молока.</p>
                <p>Не рекомендуется при заболеваниях почек.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешать 1 чайную ложку (5 г) в 200 мл воды, сока или молока.</p>
                <p>Принимать 1 раз в день.</p>
            </>
        ),
    },
    'collagen-frappuccino': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – для молодости кожи и суставов от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Фрапуччино" от Fit and Joy – это идеальный выбор для любителей кофе!</p>
                <p>Содержит кофеин и антиоксиданты, поддерживающие упругость кожи, а также защищает суставы от возрастных изменений.</p>
            </>
        ),
        price: '650',
        tasteEng: 'coffee frappuccino',
        taste: 'Мокка Фрапуччино',
        textPurpose: 'Поддержка суставов, Укрепление волос, Энергия',
        composition: 'Коллаген говяжий, сухое молоко, молочная сыворотка, кофе натуральный, витамин C, гиалуроновая кислота, стевиозид, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['поддержка кожи', 'укрепление суставов', 'увлажнение кожи', 'для женщин', 'здоровье кожи, волос, ногтей', 'энергия'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['СFrapuccino1.webp', 'СFrapuccino2.webp', 'СFrapuccino3.webp'],
        img: 'СFrapuccino1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость кофеина.</p>
                <p>Не рекомендуется при гипертонии.</p>
                <p>Аллергия на молочные продукты.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Добавить 1 чайную ложку (5 г) в молоко, воду или растительное молоко.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-berries': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – для здоровья волос и ногтей от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Ягоды" от Fit and Joy – это комплекс с натуральными экстрактами черники, малины и клюквы.</p>
                <p>Обогащен витаминами для защиты клеток от старения.</p>
            </>
        ),
        price: '650',
        tasteEng: 'berries',
        taste: 'Лесные ягоды',
        textPurpose: 'Антиоксидантная защита, Омоложение, Поддержка суставов',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, мальтодекстрин, экстракт ягод (черника, малина, клюква), витамин C, стевиозид.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['укрепление волос', 'защита кожи от старения', 'поддержка иммунитета', 'для мужчин', 'энергия', 'здоровье'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CBerries1.webp', 'CBerries2.webp', 'CBerries3.webp'],
        img: 'CBerries1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость ягод.</p>
                <p>Осторожно при хронических заболеваниях ЖКТ.</p>
                <p>Не рекомендуется при сахарном диабете.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Развести 1 чайную ложку (5 г) в 200 мл воды или сока.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-cherry': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – Укрепление волос и суставов от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Вишня" от Fit and Joy – это витаминный комплекс с натуральным экстрактом вишни, поддерживающий упругость кожи и здоровье суставов.</p>
                <p>Обогащен антиоксидантами и гиалуроновой кислотой для максимального увлажнения и защиты кожи.</p>
            </>
        ),
        price: '600',
        tasteEng: 'cherry',
        taste: 'Вишня Черешня',
        textPurpose: 'Омоложение, Антиоксидантная защита, Укрепление суставов',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, экстракт вишни, витамин C, гиалуроновая кислота, стевиозид, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['увлажнение кожи', 'поддержка суставов', 'укрепление волос и ногтей', 'для мужчин', 'энергия', 'детокс'
        ],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CCherry1.webp', 'CCherry2.webp', 'CCherry3.webp'],
        img: 'CCherry1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость вишни.</p>
                <p>Не рекомендуется при гастрите с повышенной кислотностью.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Развести 1 чайную ложку (5 г) в воде, соке или молоке.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-chocolate': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – Красота и поддержка суставов от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Шоколад" от Fit and Joy – это вкусное и полезное решение для укрепления волос, ногтей и суставов.</p>
                <p>Благодаря натуральному какао-порошку и гиалуроновой кислоте способствует упругости кожи и защите соединительных тканей.</p>
            </>
        ),
        price: '580',
        tasteEng: 'chocolate',
        taste: 'Молочный Шоколад',
        textPurpose: 'Поддержка суставов, Красота кожи, Восстановление тканей',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, натуральное какао, витамин C, гиалуроновая кислота, стевиозид.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['укрепление суставов', 'поддержка эластичности кожи', 'восстановление тканей', 'здоровье кожи, волос, ногтей', 'энергия'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CChocolate1.webp', 'CChocolate2.webp', 'CChocolate3.webp'],
        img: 'CChocolate1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость какао.</p>
                <p>Аллергия на молочные продукты.</p>
                <p>Не рекомендуется при заболеваниях ЖКТ в стадии обострения.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Развести 1 чайную ложку (5 г) в стакане молока или воды.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-matcha': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – Омоложение и антиоксидантная защита от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Матча" от Fit and Joy – это сочетание традиционного японского зелёного чая матча и высококачественного коллагена.</p>
                <p>Обогащен антиоксидантами, которые помогают защитить клетки от старения и укрепляют иммунитет.</p>
            </>
        ),
        price: '650',
        tasteEng: 'matcha',
        taste: 'Матча Латте',
        textPurpose: 'Антиоксидантная защита, Омоложение, Восстановление',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, порошок матча, витамин C, гиалуроновая кислота, стевиозид.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['омоложение', 'поддержка иммунитета', 'защита клеток', 'здоровье кожи, волос, ногтей', 'энергия', 'для мужчин'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CMatch1.webp', 'CMatch2.webp', 'CMatch3.webp'],
        img: 'CMatch1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Содержит кофеин – не рекомендуется при гипертонии.</p>
                <p>Осторожно при заболеваниях ЖКТ.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Развести 1 чайную ложку (5 г) в 200 мл воды или молока.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-strawberry-kiwi': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген Порошок – Увлажнение и защита кожи от Fit and Joy, с витамином С',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген "Клубника-Киви" от Fit and Joy – это фруктовый витаминный комплекс для молодости кожи, здоровья волос и ногтей.</p>
                <p>Экстракты клубники и киви обогащены витамином C, который усиливает выработку коллагена в организме.</p>
            </>
        ),
        price: '650',
        tasteEng: 'strawberry-kiwi',
        taste: 'Клубника-Киви',
        textPurpose: 'Увлажнение кожи, Укрепление ногтей, Поддержка суставов',
        composition: 'Коллаген говяжий, молочная сыворотка, сухое молоко, экстракт клубники, экстракт киви, витамин C, гиалуроновая кислота, стевиозид.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['поддержка молодости кожи', 'укрепление волос и ногтей', 'здоровье суставов', 'для женщин', 'здоровье кожи, волос, ногтей', 'здоровье'],
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CStrawberryKiwi1.webp', 'CStrawberryKiwi2.webp', 'CStrawberryKiwi3.webp'],
        img: 'CStrawberryKiwi1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость клубники и киви.</p>
                <p>Осторожно при аллергии на фрукты.</p>
                <p>Не рекомендуется при гастрите с повышенной кислотностью.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Развести 1 чайную ложку (5 г) в 200 мл воды или йогурта.</p>
                <p>Перемешать и выпить.</p>
            </>
        ),
    },
    'collagen-complex': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген – Красота кожи, суставов и волос',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Коллаген от Fit and Joy – это источник гидролизованного белка, который поддерживает эластичность кожи, укрепляет волосы и ногти, а также улучшает здоровье суставов.</p>
                <p>Подходит для тех, кто хочет продлить молодость и сохранить подвижность суставов.</p>
            </>
        ),
        price: '990',
        taste: 'Без вкуса',
        textPurpose: 'Красота и здоровье, Поддержка суставов, Укрепление волос',
        purposes: ['улучшение состояния кожи', 'укрепление ногтей и волос', 'поддержка суставов'],
        composition: 'Гидролизованный говяжий коллаген I и III типа, аскорбиновая кислота, мальтодекстрин, желатиновая капсула (загуститель желатин, вода, краситель пищевой). ',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['Collagen1.webp', 'Collagen2.webp', 'Collagen3.webp'],
        img: 'Collagen1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при аллергии на белок.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 2 капсулы 3 раза в день во время еды</p>
                <p>Курс – 1 месяца.</p>
                <p>При необходимости прием можно повторить</p>
            </>
        ),
    },
    'marine-collagen': {
        categoryName: "коллагеновые комплексы",
        title: 'Коллаген морской – Красота и молодость',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Морской коллаген от Fit and Joy – это мощная поддержка для кожи, суставов и волос. Улучшает эластичность кожи, уменьшает морщины и укрепляет ногти.</p>
                <p>Содержит гиалуроновую кислоту и витамин C для максимального усвоения и действия.</p>
            </>
        ),
        price: '820',
        taste: 'Без вкуса',
        textPurpose: 'Омоложение, Укрепление суставов, Красота кожи',
        purposes: ['поддержка эластичности кожи', 'укрепление волос и ногтей', 'здоровье суставов'],
        composition: 'Гидролизированный морской коллаген II типа, гиалуроновая кислота, аскорбиновая кислота, желатиновая капсула (загуститель желатин, вода, краситель пищевой диоксид титана).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в сухом прохладном месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['CollagenMarine1.webp', 'CollagenMarine2.webp', 'CollagenMarine3.webp', 'CollagenMarine4.webp'],
        img: 'CollagenMarine1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при аллергии на морепродукты.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Принимать по 1 капсуле 3 раза в день во время еды.</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
};


