import React from "react";

import NavTab from "../NavTab/NavTab";

const BurgerMenu = ({ handleBurgerButtonClick, isMobile }) => {
  return (
    <section className="burger-menu">
      <NavTab
        handleBurgerButtonClick={handleBurgerButtonClick}
        isMobile={isMobile}
      />
    </section>
  );
};
export default BurgerMenu;
