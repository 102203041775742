import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import MarketplaceLink from "../MarketplaceLink";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";
import productDatabases from "../../utils/productDatabases";

import "./ProductDetail.css";
import arrowBig from "../../images/arrowBig.webp";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const ProductDetail = ({ headerHeight, handleGoProducts }) => {
  const { productId, productKey } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [expandedSection, setExpandedSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (
    !productDatabases[productId] ||
    !productDatabases[productId][productKey]
  ) {
    return (
      <NotFoundPage
        headerHeight={headerHeight}
        handleGoMain={handleGoProducts}
        handleGoBack={() => window.history.back()}
      />
    );
  }

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const hasEnergyValueSection = [
    "protein-bars",
    "coconut-bars",
    "nut-bars",
  ].includes(productId);

  useEffect(() => {
    const products = productDatabases[productId];
    const selectedProduct = products ? products[productKey] : null;
    setProduct(selectedProduct);
  }, [productId, productKey]);

  useEffect(() => {
    if (product) {
      setCurrentImageIndex(0);
    }
  }, [product]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    if (!product || !product.images || product.images.length === 0) return;
    const timer = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % product.images.length
      );
    }, 3000);

    return () => clearInterval(timer);
  }, [product]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleIndicatorClick = (index) => {
    setCurrentImageIndex(index);
  };

  const toggleSection = (section) => {
    setExpandedSection((prevSection) =>
      prevSection === section ? null : section
    );
  };

  if (!product) {
    return <div>Товар не найден</div>;
  }

  const isTasteAvailable =
    product?.tasteEng &&
    Object.values(productDatabases)
      .flatMap((db) => Object.values(db))
      .some(
        (prod) =>
          prod?.tasteEng &&
          prod.tasteEng
            .toLowerCase()
            .split(", ")
            .includes(product.tasteEng.toLowerCase())
      );

  return (
    <section
      className="product-detail"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title={`${product.title} – Купить ${product.categoryName} | Fit and Joy`}
        description={`Купить ${product.title} со вкусом ${product.taste}. ${product.description}`}
        keywords={`${product.title}, ${product.categoryName}, ${product.taste}, БАДы, протеин, спортивное питание, витамины`}
        canonicalUrl={`https://usafitandjoy.com/products/${productId}/${productKey}/`}
        image={`https://usafitandjoy.com/images/products/${product.img}`}
      />

      <div className="product-detail__wrapper">
        <div className="btn-group">
          <button
            className="btn__blue btn product-detail__btn"
            type="button"
            onClick={handleGoProducts}
          >
            Все продукты
          </button>

          {product.categoryName && (
            <Link
              to={`/products/${productId}`}
              className="btn__violet btn product-detail__btn"
            >
              Все {product.categoryName}
            </Link>
          )}
          {isTasteAvailable && (
            <Link
              to={`/tastes/${product.tasteEng.toLowerCase()}`}
              className="btn__blue btn product-detail__btn"
            >
              Все продукты со вкусом {product.taste}
            </Link>
          )}
        </div>
        <div className="product-detail__block-text">
          <h1 className="product-detail__title">{product.title}</h1>
          <div className="product-detail__subtitle">{product.taste}</div>
        </div>

        <div className="product-detail__block-content">
          <div className="product-detail__img-wrapper">
            <div className="product-detail__img-container">
              <img
                className="product-detail__arrow product-detail__left-arrow hover"
                src={arrowBig}
                alt="Влево"
                onClick={handlePrevImage}
              />

              <img
                src={`${process.env.PUBLIC_URL}/images/products/${product.images[currentImageIndex]}`}
                alt={product.title}
                title="Нажми, чтобы рассмотреть"
                className="product-detail__img"
                onClick={() =>
                  openModal(
                    `${process.env.PUBLIC_URL}/images/products/${product.images[currentImageIndex]}`
                  )
                }
                style={{ cursor: "zoom-in" }}
              />

              <img
                className="product-detail__arrow hover"
                src={arrowBig}
                alt="Вправо"
                onClick={handleNextImage}
              />
            </div>

            <div className="product-detail__indicators">
              {product.images.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${
                    currentImageIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleIndicatorClick(index)}
                ></span>
              ))}
            </div>
          </div>
          {isModalOpen && (
            <div className="modal-overlay" onClick={closeModal}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="modal-close" onClick={closeModal}>
                  &times;
                </span>
                <img
                  src={selectedImage}
                  alt={product.title}
                  className="modal-img"
                />
              </div>
            </div>
          )}

          <div className="product-detail__info">
            <div className="product-detail__section">
              <button
                className={`product-detail__section-header ${
                  expandedSection === "description" ? "active" : ""
                }`}
                onClick={() => toggleSection("description")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Описание</h3>
                <div className="icon-right"></div>
              </button>
              {expandedSection === "description" && (
                <div className="product-detail__section-content">
                  {product.description}
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className={`product-detail__section-header ${
                  expandedSection === "characteristics" ? "active" : ""
                }`}
                onClick={() => toggleSection("characteristics")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">
                  Характеристики
                </h3>
                <div className="icon-right"></div>
              </button>
              {expandedSection === "characteristics" && (
                <div className="product-detail__section-content">
                  <div>
                    <h5 className="bold-text">Вкус: </h5> {product.taste}
                  </div>
                  <div>
                    <h5 className="bold-text">Форма выпуска: </h5>{" "}
                    {product.productType}
                  </div>
                  <div>
                    <h5 className="bold-text">Подходит для: </h5>{" "}
                    {product.textPurpose}
                  </div>
                  <div>
                    <h5 className="bold-text">Изготовлено в: </h5>{" "}
                    {product.country}
                  </div>
                  <div>
                    <h5 className="bold-text">Для кого: </h5> {product.gender}
                  </div>
                  <div>
                    <h5 className="bold-text">Срок годности в месяцах: </h5>
                    {product.expirationDate}
                  </div>
                  <div>
                    <h5 className="regular-text">Не является лекарством</h5>
                  </div>
                </div>
              )}
            </div>

            {hasEnergyValueSection ? (
              <div className="product-detail__section">
                <button
                  className={`product-detail__section-header ${
                    expandedSection === "energyValue" ? "active" : ""
                  }`}
                  onClick={() => toggleSection("energyValue")}
                  title="Нажми, чтобы узнать подробнее"
                >
                  <h3 className="product-detail__section-title">
                    Пищевая ценность
                  </h3>
                  <div className="icon-right"></div>
                </button>
                {expandedSection === "energyValue" && (
                  <div className="product-detail__section-content">
                    <div>
                      <h5 className="bold-text">
                        Энергетическая ценность на 100 г:{" "}
                      </h5>
                      {product.energyValue} ккал
                    </div>
                    <div>
                      <h5 className="bold-text">Белок: грамм в 1 порции: </h5>
                      {product.nutritionalValueProteins}
                    </div>
                    <div>
                      <h5 className="bold-text">Жиры: грамм в 1 порции: </h5>
                      {product.nutritionalValueFats}
                    </div>
                    <div>
                      <h5 className="bold-text">
                        Углеводы: грамм в 1 порции:{" "}
                      </h5>{" "}
                      {product.nutritionalValueCarbohydrates}
                    </div>
                    <br />
                    <div>
                      <h5 className="bold-text">Особенности: </h5>
                      {product.text}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="product-detail__section">
                <button
                  className={`product-detail__section-header ${
                    expandedSection === "contentMethod" ? "active" : ""
                  }`}
                  onClick={() => toggleSection("contentMethod")}
                  title="Нажми, чтобы узнать подробнее"
                >
                  <h3 className="product-detail__section-title">Применение</h3>
                  <div className="icon-right"></div>
                </button>
                {expandedSection === "contentMethod" && (
                  <div className="product-detail__section-content">
                    <div>
                      <h5 className="bold-text">Способ применения: </h5>
                      {product.contentMethod}
                    </div>
                    <div>
                      <h5 className="bold-text">{product.water}</h5>
                    </div>

                    <div>
                      <h5 className="bold-text">Особенности: {product.text}</h5>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="product-detail__section">
              <button
                className={`product-detail__section-header ${
                  expandedSection === "composition" ? "active" : ""
                }`}
                onClick={() => toggleSection("composition")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Состав</h3>
                <div className="icon-right"></div>
              </button>
              {expandedSection === "composition" && (
                <div className="product-detail__section-content">
                  <div>{product.composition}</div>
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className={`product-detail__section-header ${
                  expandedSection === "contraindications" ? "active" : ""
                }`}
                onClick={() => toggleSection("contraindications")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">
                  Противопоказания
                </h3>
                <div className="icon-right"></div>
              </button>
              {expandedSection === "contraindications" && (
                <div className="product-detail__section-content">
                  <div>{product.contraindications}</div>
                </div>
              )}
            </div>
            <div className="product-detail__section">
              <button
                className={`product-detail__section-header ${
                  expandedSection === "price" ? "active" : ""
                }`}
                onClick={() => toggleSection("price")}
                title="Нажми, чтобы узнать подробнее"
              >
                <h3 className="product-detail__section-title">Цена</h3>
                <div className="icon-right"></div>
              </button>
              {expandedSection === "price" && (
                <div className="product-detail__section-content">
                  <div>
                    <h5 className="bold-text">
                      Рекомендованная розничная цена:{" "}
                    </h5>
                    {product.price} руб.
                  </div>
                  <br />
                  <div>
                    <h5 className="bold-text">
                      Мы всегда стремимся предложить нашим клиентам
                      высококачественные продукты по справедливой цене. Однако,
                      для вашего удобства и возможной экономии, рекомендуем
                      также ознакомиться с ценами на наши продукты на различных
                      онлайн-маркетплейсах.
                    </h5>
                  </div>
                  <br />
                  <div>
                    <p className="bold-text">
                      Почему стоит проверить цены на маркетплейсах:
                    </p>
                    <br />

                    <p>Акции и скидки:</p>
                    <p>
                      Онлайн-магазины и маркетплейсы часто проводят акции и
                      предлагают скидки, которые могут сделать покупку более
                      выгодной.
                    </p>
                    <br />

                    <p>Специальные предложения:</p>
                    <p>
                      На маркетплейсах можно найти эксклюзивные предложения,
                      которых нет на нашем сайте.
                    </p>
                    <p>
                      Проверяйте цены на наших продуктах на популярных
                      маркетплейсах, чтобы найти наиболее выгодные предложения и
                      сэкономить на покупках.
                    </p>
                    <div className="product-detail__marketplace-list">
                      {cardsMarketplaces.map((marketplace) => (
                        <MarketplaceLink
                          key={marketplace.name}
                          href={marketplace.url}
                        >
                          <img
                            className="product-detail__marketplace-item"
                            src={marketplace.img}
                            alt={marketplace.name}
                            title={marketplace.name}
                          />
                        </MarketplaceLink>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <p className="medium-text">
                Данные о характеристиках, составе поставки, дизайне и цветовой
                гамме продукции приведены исключительно в информационных целях и
                основаны на актуальных сведениях, доступных на момент
                публикации.
              </p>
              <p className="medium-text">
                Описание свойств и действия препаратов не является заменой
                консультации с врачом. Возможны противопоказания, перед
                использованием рекомендуется проконсультироваться со
                специалистом.
              </p>
              <p className="medium-text">
                Фактический вес товара может незначительно варьироваться.
              </p>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <button
            className="btn__blue btn product-detail__btn"
            type="button"
            onClick={handleGoProducts}
          >
            Все продукты
          </button>

          {product.categoryName && (
            <Link
              to={`/products/${productId}`}
              className="btn__violet btn product-detail__btn"
            >
              Все {product.categoryName}
            </Link>
          )}
          {isTasteAvailable && (
            <Link
              to={`/tastes/${product.tasteEng.toLowerCase()}`}
              className="btn__blue btn product-detail__btn"
            >
              Все продукты со вкусом {product.taste}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;
