import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useSearch from "../../hooks/useSearch";
import { SlMagnifier } from "react-icons/sl";
import "./SearchBar.css";

const SearchBar = ({ headerHeight }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { searchResults, handleSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchRef = useRef(null);
  const resultsRef = useRef(null);
  const [isResultsVisible, setIsResultsVisible] = useState(false);

  const isSearchPage = location.pathname.startsWith("/search");

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.style.top = `${headerHeight}px`;
    }

    // Закрываем результаты поиска при клике вне области
    const handleClickOutside = (event) => {
      if (
        resultsRef.current &&
        !resultsRef.current.contains(event.target) &&
        !searchRef.current.contains(event.target)
      ) {
        setIsResultsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [headerHeight]);

  const handleSearchClick = () => {
    if (searchQuery.trim().length < 2) return;

    if (isSearchPage) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
    } else {
      handleSearch(searchQuery);
      setIsResultsVisible(true);
    }
  };

  // Запуск поиска при нажатии Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div className="search-bar-container" ref={searchRef}>
      <div className="search-bar__wrapper">
        <div className="search-box">
          <button
            onClick={handleSearchClick}
            style={{
              backgroundColor: "black",
              border: "none",
              cursor: "pointer",
            }}
          >
            <SlMagnifier color="white" />
          </button>

          <input
            type="text"
            placeholder="Поиск"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>

        {isResultsVisible && (
          <ul className="search-results-dropdown" ref={resultsRef}>
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <li key={index}>
                  <a href={result.url}>{result.title}</a>
                </li>
              ))
            ) : (
              <li className="no-results-dropdown">Ничего не найдено</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
