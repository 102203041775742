import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import PageHelmet from "../PageHelmet";
import PodarokStepper from "../PodarokStepper/PodarokStepper";
import ContactForm from "../ContactForm/ContactForm";
import ReviewScreens from "../ReviewScreens/ReviewScreen";
import { useSearchData } from "../../utils/searchData";

import "./Podarok.css";

import podarok from "../../images/podarok.webp";

const Podarok = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleTelegramClickSupport,
  handleTelegramClickJF,
  handleVkClick,
  handleEmailClick,
}) => {
  const { searchData, addPodarokToSearch } = useSearchData();
  const [pageText, setPageText] = useState("");
  const contactFormRef = useRef(null);
  const location = useLocation();

  const scrollToForm = () => {
    if (contactFormRef.current) {
      const formPosition = contactFormRef.current.getBoundingClientRect().top;
      const offsetPosition = window.scrollY + formPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <section
      key={location.pathname}
      className="podarok"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Как получить подарок от Fit and Joy"
        description="Получите подарок за отзыв на продукцию Fit and Joy. Оставьте отзыв и получите кешбэк!"
        keywords="подарок, отзыв, кешбэк, Fit and Joy"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/podarok/`}
      />
      <div className="podarok__wrapper">
        <div className="podarok__block">
          <div className="podarok__image-container">
            <img className="podarok__img" src={podarok} alt="продукция jf" />
          </div>
          <div className="podarok__block-text">
            <h1 className="podarok__block-title">
              ПОДАРОК от <br />
              FIT AND JOY
            </h1>

            <p className="podarok__block-subtitle">Гарантированный кешбэк</p>
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue podarok__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue podarok__btn"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <h1 className="podarok__title">Как получить подарок?</h1>
        <div className="podarok-container">
          <p className="podarok__subtitle">Следуйте этим шагам:</p>

          <PodarokStepper
            handleTelegramClickJF={handleTelegramClickJF}
            handleVkClick={handleVkClick}
            handleTelegramClickSupport={handleTelegramClickSupport}
            handleGoStore={handleGoStore}
            scrollToForm={scrollToForm}
          />
          <p className="podarok__note">
            После этого мы проверим Ваш отзыв и пришлем кэшбэк.
          </p>
        </div>

        <div className="podarok__important">
          <h3 className="podarok__important-title">Важно</h3>
          <p className="podarok__important-text">
            <br /> 1. Чтобы получить кэшбэк, необходимо оставить отзыв на
            купленный продукт. По правилам всех маркетплейсов отзыв можно
            оставить только <span>один раз</span>.
            <br />
            <br />
            2. Чтобы получить кэшбэк дважды, нужно приобрести{" "}
            <span>два разных продукта</span>. Так у вас будет возможность
            оставить отзыв о каждом из них. Либо можно купить продукт на другой
            площадке.
            <br />
            <br />
            3. <span>Получить кэшбэк за отзыв можно в течение месяца</span> со
            дня его написания. Отзывы, оставленные более месяца назад, не
            рассматриваются.
            <br />
            <br />
            4.{" "}
            <span>
              Если вы купили несколько продуктов, пишите разные отзывы!
            </span>{" "}
            Не копируйте один и тот же текст — каждый продукт индивидуальный.
            <br />
            <br />
            5. <span>Скриншот отзыва</span> нужно сделать из{" "}
            <span>личного кабинета</span>: (Пример: Ozon → «Мои отзывы»,
            Wildberries → «Мои публикации» и т. д.)
            <br />
            <br />
            6. <span>Какие скриншоты не принимаются?</span>
            <br />
            6.1. ❌ Фотографии, сделанные с экрана компьютера или через камеру
            телефона/планшета.
            <br />
            6.2. ❌ Отзывы из общего доступа, которые может скопировать каждый.
            Принимаются <b>только скриншоты из личного кабинета!</b>
            <br />
            <br />
            7. <span>Ваш отзыв должен быть в статусе "Опубликовано"</span>.
            Отзывы, которые имеют статус <b>"Изменен"</b> или <b>"Дополнен"</b>,
            не принимаются.
            <br />
            <br />❌ <b>Почему?</b> Потому что такие отзывы могли быть
            скорректированы после проверки и не гарантируют первоначальной
            достоверности.
            <br />
            <br />
          </p>

          <h3 className="podarok__important-title">Как оставить отзыв?</h3>
          <p className="podarok__important-text">
            <br />
            <b>Ozon: </b>
            Зайдите в личный кабинет → "Заказы". Выберите товар Fit and Joy →
            "Оценить товар" (именно товар, а не доставку!). Заполните отзыв,
            добавьте фото/видео → "Отправить отзыв".
            <br />
            ❗Отзывы о доставке не учитываются!
            <br />
            <br />
            <b>Детский мир: </b>
            Личный кабинет → "Мои заказы". Выберите товар → "Оставить отзыв".
            Оцените, напишите комментарий, добавьте фото/видео → "Отправить".
            <br />
            <br />
            <b> Яндекс Маркет: </b>
            Личный кабинет → "Заказы". Выберите товар Fit and Joy, поставьте
            звезды. Откроется окно → заполните отзыв, добавьте фото/видео →
            "Отправить".
            <br />
            <br />
            <b>Wildberries: </b>
            Личный кабинет → "Доставки" → "Покупки". Выберите товар, поставьте
            звезды. Откроется окно → заполните отзыв, добавьте фото/видео →
            "Отправить".
            <br />
            <br />❗{" "}
            <span>
              Для получения кэшбэка обязательно прикрепите 4 скриншота:
            </span>
            <br />
            <br /> 📌 Скриншот покупки из личного кабинета.
            <br /> 📌 Скриншот отзыва из личного кабинета.
            <br /> 📌 Живое фото продукта.
            <br /> 📌 Скриншот подписки на нашу соцсеть.
            <br />
            <br />
          </p>

          <h3 className="podarok__important-title">
            Как сделать скриншот покупки?
          </h3>
          <p className="podarok__important-text">
            <br />✅ В личном кабинете маркетплейса.
            <br />
            <br />❗{" "}
            <span>
              На Wildberries скриншот делается только через браузер (Chrome,
              Safari и т. д.), а не через приложение!
            </span>{" "}
            Перейдите в "Отзывы" → "Покупки" → сделайте скриншот.
            <br />
          </p>
        </div>

        <div className="podarok__feedback">
          <h3 className="podarok__important-title">
            Отправьте скриншоты на 📩 или в 📲 Telegram
          </h3>
          <div className="podarok__marketplace-contacts">
            <div className="podarok__text">
              <p className="podarok__subtitle-contacts"> Пришлите: </p>
              <br /> 1. фотографию купленного товара;
              <br />
              2. скриншот покупки(заказа) из ЛИЧНОГО кабинета;
              <br />
              3. скриншот отзыва из ЛИЧНОГО кабинета;
              <br />
              4. скриншот вашей подписки на любую из наших соцсетей;
              <br />
              <br />
              <p className="podarok__subtitle-contacts">
                В теме сообщения укажите:
              </p>
              <br /> 1. Ваше ФИО для перевода;
              <br />
              2. Номер телефона - если нужно будет с вами связаться;
              <br />
              3. Карта Сбербанка или Тинькофф, либо на номер мобильного
              телефона, для перевода Бонуса-кэш. <b>Внимание:</b> если будет
              указана карта другого банка, с Вашего кэш 150 рублей минус
              вычитают комиссию;
            </div>
            <div className="podarok__line"></div>
            <p className="podarok__note">
              и всё пришлите в telegram
              <br />
              ❗не забудьте прикрепить нужные фотографии/скрины
            </p>
            <button
              className="podarok__contacts-btn btn btn__blue"
              onClick={handleTelegramClickSupport}
            >
              📩 Отправить в Telegram
            </button>

            <p className="podarok__note warning">
              ❗ Важно: Без полного комплекта скринов кэшбэк не начисляется.
            </p>
            <div className="podarok__line"></div>
            <p className="podarok__note">
              ИЛИ заполните и отправьте форму, прикрепив нужные
              фотографии/скрины
            </p>
            <div ref={contactFormRef}>
              <ContactForm />
            </div>
            <div className="podarok__line"></div>
            <p className="podarok__note">
              ИЛИ всё пришлите на почту loveourclients@yandex.ru, <br />
              ❗не забудьте прикрепить нужные фотографии/скрины
            </p>
            <button
              className="podarok__contacts-btn btn btn__blue"
              onClick={handleEmailClick}
            >
              Email
            </button>
            <div className="podarok__line"></div>
            <p className="podarok__note">
              После этого мы проверим Ваш отзыв и пришлем кэшбэк.</p>
              <p className="podarok__text">
              
              <br />⏳ <b>Срок начисления бонуса — до 3 недель</b>, так как мы
              вручную проверяем каждый скриншот, чтобы убедиться в его
              соответствии правилам.
              <br /><br />
              Мы стараемся обработать заявки как можно быстрее, но иногда
              процесс может занять немного больше времени в зависимости от
              количества обращений.
            </p>
          </div>
        </div>

        <ReviewScreens />
      </div>
    </section>
  );
};

export default Podarok;
