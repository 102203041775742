import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import PageHelmet from "../PageHelmet";

import "./ArticleProducts.css";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { articlesProducts } from "../../utils/articlesProducts";
import productDatabases from "../../utils/productDatabases";

const ArticleProducts = ({ headerHeight, handleGoProducts, handleGoBack }) => {
  const { productId } = useParams();
  const article = articlesProducts[productId];
  const [products, setProducts] = useState({});

  if (!article) {
    return (
      <NotFoundPage
        headerHeight={headerHeight}
        handleGoMain={handleGoProducts}
        handleGoBack={handleGoBack}
      />
    );
  }

  useEffect(() => {
    if (productId && productDatabases[productId]) {
      setProducts(productDatabases[productId]);
    }
  }, [productId]);

  if (!article) {
    return <div>Информация не найдена</div>;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      className="article-products"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title={`${article.title} | Продукты Fit and Joy`}
        description={
          typeof article.content === "string"
            ? article.content.slice(0, 150)
            : ""
        }
        keywords={`${article.title}, спортивное питание, БАДы, витамины, протеин, коллаген`}
        canonicalUrl={`https://usafitandjoy.com/products/${productId}/`}
        image={`https://usafitandjoy.com/images/articles/${article.img}`}
      />

      <div className="article-products__wrapper">
        <div className="about-us__button-block">
          <button
            className="article-list__btn btn__blue btn"
            type="button"
            onClick={handleGoProducts}
          >
            ВСЕ ПРОДУКТЫ
          </button>
          <button
            className="btn btn__violet article-list__btn"
            type="button"
            onClick={handleGoBack}
          >
            Назад
          </button>
        </div>
        <div className="article-products__block-text">
          <h1 className="products__title">{article.title}</h1>
          <div className="article-products__text">{article.content}</div>
        </div>
        <div className="article-products__block-text">
          <h1 className="article-products__title">{article.titleMethod}</h1>

          <div className="article-products__text">{article.contentMethod}</div>
        </div>

        <div className="article-products__block-container">
          <ul className="article-products__container">
            {Object.keys(products).map((key) => {
              const product = products[key];
              const image = product.img;

              return (
                <li key={key} className="article-products__card">
                  <Link
                    to={`/products/${productId}/${key}`}
                    className="article-products__card-link"
                  >
                    <div className="image-container">
                      {image && (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/products/${product.img}`}
                          alt={product.title}
                          className="article-products__card-img"
                        />
                      )}
                    </div>
                    <div className="article-products__card-content">
                      <h3 className="article-products__card-title">
                        {product.title}
                      </h3>
                      <div className="article-products__card-text">
                        {product.taste}
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <button
          className="article-list__btn btn__blue btn"
          type="button"
          onClick={handleGoProducts}
        >
          ВСЕ ПРОДУКТЫ
        </button>
      </div>
    </div>
  );
};

export default ArticleProducts;
