import React from "react";
import { Link } from "react-router-dom";
import { articlesData } from "../../utils/articlesData";
import "./ArticleList.css";

const categoryTitles = {
  "health-blog": "Здоровье",
  nutrition: "Питание",
  training: "Тренировки",
};

const ArticleList = ({ category, headerHeight, handleGoBlog }) => {
  const articles = articlesData[category];

  if (!articles) {
    return <div>Статьи для этой категории не найдены</div>;
  }

  return (
    <div className="article-list" style={{ paddingTop: `${headerHeight}px` }}>
      <div className="article-list__wrapper">
        <h1 className="article-list__title">
          {categoryTitles[category] || "Статьи"}
        </h1>
        <div className="about-us__button-block">
          <button
            className="btn__blue btn article-list__btn"
            type="button"
            onClick={handleGoBlog}
          >
            К выбору темы
          </button>
        </div>
        <div className="article-list__container">
          {Object.entries(articles).map(([articleId, article]) => (
            <div key={articleId} className="article-list__block">
              <h2>{article.title}</h2>
              <p>{article.content.slice(0, 100)}...</p>
              <Link
                to={`/${category}/${articleId}`}
                className="article-list__read-more"
              >
                Подробнее о {article.btn}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticleList;
