export const proteinSlimEffectProducts = {
    'frappuccino-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Протеиновый коктейль для похудения Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Насыщенный вкус фрапучино и мокко без лишних калорий. Этот коктейль придаст бодрость, зарядит энергией и поможет поддерживать фигуру.</p>
                <p>Идеально подходит для утреннего приема пищи или после тренировки.</p>
            </>
        ),
        price: '530',
        tasteEng: 'coffee frappuccino',
        taste: 'Фрапучино Мокко',
        textPurpose: 'Похудение, Восстановление, Энергия',
        purposes: ['контроль аппетита', 'стройность', 'энергия'],
        composition: 'Мальтодекстрин, молоко сухое, изолят соевого белка, ароматизатор, таурин, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Энергетическая ценность калории (на 100 гр.)	396 ккал
                <br /> Пищевая ценность белки	12.5 г/100г
                <br /> Пищевая ценность жиры	5.2 г/100г
                <br /> Пищевая ценность углеводы	74.5 г/100г</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'В сухом прохладном месте, вдали от прямых солнечных лучей',
        gender: 'Подходит для мужчин и женщин',
        images: ['SEF1.webp', 'SEF2.webp', 'SEF3.webp', 'SE.webp'],
        img: 'SEF1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при заболеваниях почек и печени.</p>
                <p>При беременности и кормлении грудью проконсультируйтесь с врачом.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в 200 мл воды, молока или растительного напитка.</p>
                <p>Тщательно взболтайте в шейкере или перемешайте до однородной консистенции.</p>
                <p>Принимайте 1-2 раза в день, за 30 минут до еды или после тренировки.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'blackberry-currant-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Фито-протеиновый коктейль Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Ягодный микс с ежевикой и черной смородиной – насыщенный вкус и антиоксидантная поддержка организма.</p>
                <p>Содержит витамин C и натуральные экстракты ягод для укрепления иммунитета и снижения воспалительных процессов.</p>
            </>
        ),
        price: '530',
        tasteEng: 'blackberry-currant',
        taste: 'Ежевика-Смородина',
        textPurpose: 'Антиоксидантная защита, Энергия, Восстановление',
        purposes: ['поддержка иммунитета', 'энергия', 'стройность'],
        composition: 'Мальтодекстрин, изолят соевого белка, экстракт ежевики, экстракт черной смородины, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Вид протеина -
                Многокомпонентный</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['SEBerry1.webp', 'SEBerry2.webp', 'SEBerry3.webp', 'SE.webp'],
        img: 'SEBerry1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Может содержать следы косточек ягод.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Смешайте 2 мерные ложки (5 гр) с 200 мл воды, молока или растительного напитка.</p>
                <p>Тщательно размешайте или используйте шейкер.</p>
            </>
        ),
    },
    'rocky-road-cookie-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Протеиновый коктейль для похудения Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Сладкий вкус шоколадного печенья без вреда для фигуры! Помогает контролировать аппетит и поддерживать энергию.</p>
            </>
        ),
        price: '530',
        tasteEng: 'chocolate rocky-road-cookie',
        taste: 'Печенье Роки-Роад',
        textPurpose: 'Энергия, Восстановление, Поддержка метаболизма',
        purposes: ['контроль аппетита', 'энергия', 'стройность'],
        composition: 'Мальтодекстрин, молоко сухое, изолят соевого белка, ароматизатор, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Энергетическая ценность калории (на 100 гр.)	396 ккал
                <br /> Пищевая ценность белки	12.5 г/100г
                <br /> Пищевая ценность жиры	5.2 г/100г
                <br /> Пищевая ценность углеводы	74.5 г/100г</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'В сухом прохладном месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['SERR1.webp', 'SERR2.webp', 'SERR3.webp', 'SE.webp'],
        img: 'SERR1.webp',
        contraindications: (
            <>
                <p>Может содержать следы орехов.</p>
                <p>Не рекомендуется при сахарном диабете.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в 200 мл воды, молока или растительного напитка.</p>
                <p>Тщательно перемешайте или взболтайте.</p>
                <p>Принимать 1-2 раза в день.</p>
            </>
        ),
    },
    'mango-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Протеиновый коктейль для похудения Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Тропический вкус манго, заряжающий бодростью. Отличный выбор для поддержания формы и иммунитета.</p>
            </>
        ),
        price: '530',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: 'Похудение, Витамины, Заряд энергии',
        purposes: ['укрепление иммунитета', 'поддержка обмена веществ', 'энергия'],
        composition: 'Мальтодекстрин, молоко сухое, изолят соевого белка, ароматизатор, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Энергетическая ценность калории (на 100 гр.)	396 ккал
                <br /> Пищевая ценность белки	12.5 г/100г
                <br /> Пищевая ценность жиры	5.2 г/100г
                <br /> Пищевая ценность углеводы	74.5 г/100г</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'В сухом прохладном месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['SEM1.webp', 'SEM2.webp', 'SEM3.webp', 'SE.webp'],
        img: 'SEM1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Добавьте две мерных ложки (5 гр) в 200 мл жидкости.</p>
                <p>Взбейте в шейкере или перемешайте.</p>
            </>
        ),
    },
    'latte-macchiato-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Протеиновый коктейль для похудения Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Для всех любителей кофе! Протеиновый коктейль со вкусом латте-макиато помогает поддерживать уровень энергии и улучшает концентрацию.</p>
                <p>Содержит качественный белок, витамины группы B и натуральные экстракты для бодрости на весь день.</p>
            </>
        ),
        price: '530',
        tasteEng: 'coffee latte-macchiato',
        taste: 'Латте-макиато',
        textPurpose: 'Энергия, Восстановление, Похудение',
        purposes: ['поддержка обмена веществ', 'для мужчин', 'контроль веса'],
        composition: 'Соевый белок, сухое молоко, натуральный кофе, витамины группы B, подсластитель.',
        text: <>
            <p>Энергетическая ценность калории (на 100 гр.)	396 ккал
                <br /> Пищевая ценность белки	12.5 г/100г
                <br /> Пищевая ценность жиры	5.2 г/100г
                <br /> Пищевая ценность углеводы	74.5 г/100г</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте, избегать прямых солнечных лучей',
        gender: 'Универсальный',
        images: ['SEMccht1.webp', 'SEMccht2.webp', 'SEMccht3.webp', 'SE.webp'],
        img: 'SEMccht1.webp',
        contraindications: (
            <>
                <p>Не рекомендуется при индивидуальной непереносимости кофеина.</p>
                <p>При беременности и кормлении грудью перед употреблением проконсультируйтесь с врачом.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Добавьте 2 мерные ложки порошка в 200 мл холодного молока или воды.</p>
                <p>Хорошо взболтайте или перемешайте.</p>
                <p>Принимайте 1-2 раза в день.</p>
            </>
        ),
    },
    'cherry-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Фито-протеиновый коктейль Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Насыщенный ягодный вкус с натуральным экстрактом вишни. Коктейль помогает поддерживать энергию, укрепляет иммунитет и способствует мягкому очищению организма.</p>
                <p>Содержит комплекс витаминов группы B, улучшающий метаболизм.</p>
            </>
        ),
        price: '530',
        tasteEng: 'cherry',
        taste: 'Вишня',
        textPurpose: 'Энергия, Витамины, Контроль аппетита',
        purposes: ['поддержка работы ЖКТ', 'энергия', 'стройность'],
        composition: 'Мальтодекстрин, изолят соевого белка, натуральный экстракт вишни, сухое молоко, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Вид протеина -
                Многокомпонентный</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в темном прохладном месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['SECherry1.webp', 'SECherry2.webp', 'SECherry3.webp', 'SE.webp'],
        img: 'SECherry1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при гастрите с повышенной кислотностью.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Добавьте 2 мерные ложки (5 гр) в 200 мл воды, молока или сока.</p>
                <p>Перемешайте или используйте шейкер.</p>
                <p>Принимайте 1-2 раза в день.</p>
            </>
        ),
    },
    'coffee-latte-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Протеиновый коктейль для похудения Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Классический кофейный вкус с нежной молочной текстурой. Подходит для бодрого утра и активного дня, обеспечивая организм белком и полезными микроэлементами.</p>
                <p>Содержит витамины группы B и натуральный кофеин для поддержания работоспособности.</p>
            </>
        ),
        price: '530',
        tasteEng: 'coffee latte',
        taste: 'Кофе-латте',
        textPurpose: 'Энергия, Концентрация, Контроль аппетита',
        purposes: ['энергия', 'восстановление после тренировки', 'улучшение обмена веществ'],
        composition: 'Соевый белок, натуральный кофе, сухое молоко, витамины группы B, подсластитель.',
        text: <>
            <p>Энергетическая ценность калории (на 100 гр.)	396 ккал
                <br /> Пищевая ценность белки	12.5 г/100г
                <br /> Пищевая ценность жиры	5.2 г/100г
                <br /> Пищевая ценность углеводы	74.6 г/100г</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в прохладном, темном месте',
        gender: 'Подходит для мужчин и женщин',
        images: ['SELtt1.webp', 'SELtt2.webp', 'SELtt3.webp', 'SE.webp'],
        img: 'SELtt1.webp',
        contraindications: (
            <>
                <p>Не рекомендуется людям с повышенной чувствительностью к кофеину.</p>
                <p>Ограничьте употребление при проблемах с сердечно-сосудистой системой.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте 2 мерные ложки (5 г) в 200 мл холодного молока или воды.</p>
                <p>Тщательно перемешайте или используйте шейкер.</p>
                <p>Принимайте 1-2 раза в день.</p>
            </>
        ),
    },
    'strawberries-cream-shakes': {
        categoryName: "протеиновые коктейли",
        title: 'Фито-протеиновый коктейль Slim Effect, без сахара',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p>Slim Effect от Fit and Joy это белковый протеиновый фитококтейль.</p>
                <p>Легкий и вкусный коктейль с нежным сливочным оттенком. Помогает контролировать аппетит, поддерживать стройность и заряжает энергией.</p>
                <p>Обогащен витаминами и антиоксидантами, которые способствуют укреплению иммунитета.</p>
            </>
        ),
        price: '530',
        tasteEng: 'strawberries-cream',
        taste: 'Клубника со сливками',
        textPurpose: 'Похудение, Восстановление, Энергия',
        purposes: ['контроль аппетита', 'здоровье кожи, волос, ногтей', 'укрепление иммунитета'],
        composition: 'Мальтодекстрин, изолят соевого белка, сухое молоко, натуральный ароматизатор, экстракт клубники, витаминный комплекс, подсластитель стевиозид.',
        text: <>
            <p>Вид протеина -
                Многокомпонентный</p>
        </>,
        water: <>
            <p>Не растворять в горячей воде</p>
        </>,
        country: 'Россия',
        expirationDate: '24 месяца',
        storageConditions: 'Хранить в сухом прохладном месте, избегая попадания прямых солнечных лучей',
        gender: 'Подходит для мужчин и женщин',
        images: ['SEStrawberry1.webp', 'SEStrawberry2.webp', 'SEStrawberry3.webp', 'SE.webp'],
        img: 'SEStrawberry1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Аллергия на молочные продукты или клубнику.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в 200 мл холодной воды, молока или растительного напитка.</p>
                <p>Тщательно взболтайте в шейкере или перемешайте.</p>
                <p>Принимайте 1-2 раза в день, за 30 минут до еды или после тренировки.</p>
            </>
        ),
    },
};


