import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./CookieConsent.css";

const CookieConsent = ({ setCookieConsent }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "accepted") {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [setCookieConsent]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
    setCookieConsent("accepted");
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false);
    navigate("/no-access/");
  };

  const handleInfo = () => {
    localStorage.setItem("cookieConsent", "declined");
    localStorage.setItem("allowPrivacyPolicy", "true");
    setIsVisible(false);
    navigate("/about-cookies/");
  };

  if (!isVisible) {
    return null;
  }

  return (
    <section className="cookie-consent">
      <div className="cookie-consent__container">
        <p className="cookie-consent__text">
          Мы используем cookies/куки для улучшения работы сайта. Продолжая
          пользоваться сайтом, вы соглашаетесь с их использованием
        </p>

        <button
          className="cookie-consent__button-agree no-access__link"
          onClick={handleAccept}
        >
          Принять
        </button>
        <div className="cookie-consent__block">
          <button
            className="cookie-consent__button hover"
            onClick={handleDecline}
          >
            Отклонить
          </button>

          <button className="cookie-consent__button hover" onClick={handleInfo}>
            Узнай подробнее
          </button>
        </div>
      </div>
    </section>
  );
};

export default CookieConsent;
