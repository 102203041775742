import React, { useEffect } from "react";
import PageHelmet from "../PageHelmet";
import MarketplaceLink from "../MarketplaceLink";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";
import ImageGrid from "../ImageGrid/ImageGrid";

import "./Store.css";

const Store = ({ headerHeight, handleGoPodarok, handleGoMain }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="store"
      id="store"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Где купить продукцию Fit and Joy — Маркетплейсы и магазины"
        description="Продукция Fit and Joy доступна для покупки на популярных маркетплейсах. Выберите удобную платформу и приобретите спортивное питание и БАДы с гарантией качества."
        keywords="где купить, спортивное питание, БАДы, протеиновые батончики, протеиновые коктейли, маркетплейсы, интернет-магазины"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/buy`}
        image={`https://usafitandjoy.com/images/buy.webp`}
      />

      <div className="store__wrapper">
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={`${process.env.PUBLIC_URL}/images/buy.webp`}
              alt="вкусы продуктов Fit and Joy"
            />
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoPodarok}
          >
            получить подарок
          </button>
        </div>
        <div className="store__container">
          <h1 className="about-us__title">Где купить продукцию Fit and Joy?</h1>

          <p className="about-us__text">
            Мы сделали процесс покупки максимально удобным для вас! Вы можете
            приобрести нашу продукцию на популярных маркетплейсах, где
            гарантировано качество и удобные условия доставки.
          </p>
        </div>

        <div className="store__marketplace-list">
          {cardsMarketplaces.map((marketplace) => (
            <MarketplaceLink key={marketplace.name} href={marketplace.url}>
              <img
                className="store__marketplace-item"
                src={marketplace.img}
                alt={`Купить на сайте ${marketplace.alt}`}
                title={marketplace.name}
              />
            </MarketplaceLink>
          ))}
        </div>

        <p className="about-us__text store__text">
          Выбирайте маркетплейс, которым пользуетесь чаще всего. Просто нажмите
          на логотип магазина и оформите заказ в несколько кликов!
        </p>
        <ImageGrid/>
      </div>

    </section>
  );
};

export default Store;
