import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHelmet from "../PageHelmet";
import "./ChooseTaste.css";

import productDatabases from "../../utils/productDatabases";
import { cardsTastes } from "../../utils/cardsTastes";

const ChooseTaste = ({
  headerHeight,
  handleGoTastes,
  handleGoStore,
  handleGoBack,
}) => {
  const { taste } = useParams();
  const navigate = useNavigate();

  const filteredProducts = Object.values(productDatabases)
    .flatMap((db) => Object.values(db))
    .filter(
      (product) =>
        product.tasteEng &&
        product.tasteEng.toLowerCase().includes(taste.toLowerCase())
    );

  const getTasteInRussian = (taste) => {
    if (!taste) return "Неизвестный вкус";

    // 🟢 Сначала ищем перевод во `cardsTastes`
    const tasteObj = cardsTastes.find(
      (t) => t.tasteEng.toLowerCase() === taste.toLowerCase()
    );
    if (tasteObj) return tasteObj.nameRus;

    // 🟢 Если не нашли в `cardsTastes`, ищем в `productDatabases`
    const productWithThisTaste = Object.values(productDatabases)
      .flatMap((db) => Object.values(db))
      .find(
        (product) =>
          product.tasteEng &&
          product.tasteEng.toLowerCase().includes(taste.toLowerCase())
      );

    return productWithThisTaste
      ? productWithThisTaste.taste
      : "Неизвестный вкус";
  };

  const formattedTaste = getTasteInRussian(taste);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleProductClick = (productId, productKey) => {
    navigate(`/products/${productId}/${productKey}`);
  };

  const pageTitle = `Fit and Joy – Продукты со вкусом ${formattedTaste}`;
  const pageDescription = `Выберите продукты со вкусом ${formattedTaste} из ассортимента Fit and Joy: протеиновые батончики, коктейли, витамины, коллаген, детокс, жиросжигатели и многое другое.`;

  return (
    <div className="choose-taste" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title={`Fit and Joy – Продукты со вкусом ${formattedTaste}`}
        description={`Выберите продукты со вкусом ${formattedTaste} из ассортимента Fit and Joy: протеиновые батончики, коктейли, витамины, коллаген, детокс, жиросжигатели и многое другое.`}
        canonicalUrl={`https://usafitandjoy.com/tastes/${taste}/`}
      />

      <div className="choose-taste__wrapper">
        <h1 className="choose-taste__title">Вкус {formattedTaste}</h1>
        <div className="about-us__button-block">
          <div className="choose-taste__btn-group">
            <button
              className="btn btn__blue"
              type="button"
              onClick={handleGoTastes}
            >
              Все вкусы
            </button>
            <button
              className="btn btn__violet"
              type="button"
              onClick={handleGoBack}
            >
              Назад
            </button>
          </div>
          <button
            className="btn btn__blue"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <p className="choose-taste__description">
          Откройте для себя разнообразие продуктов со вкусом {formattedTaste},
          созданных для здоровья и активного образа жизни.
        </p>

        <div className="choose-taste__block-container">
          <ul className="choose-taste__list">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => {
                const productId = Object.keys(productDatabases).find((key) =>
                  Object.values(productDatabases[key]).includes(product)
                );
                const productKey = Object.keys(
                  productDatabases[productId]
                ).find((key) => productDatabases[productId][key] === product);

                return (
                  <li
                    key={index}
                    className="choose-taste__item"
                    onClick={() => handleProductClick(productId, productKey)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/products/${product.img}`}
                      alt={product.title}
                      className="choose-taste__image"
                    />
                    <div className="choose-taste__product-content">
                      <h2 className="article-products__card-title">
                        {product.title}
                      </h2>
                      <span className="article-products__card-text">
                        {product.taste}
                      </span>
                    </div>
                  </li>
                );
              })
            ) : (
              <p className="article-products__card-title">
                К сожалению, у нас пока нет продуктов с таким вкусом.
              </p>
            )}
          </ul>
        </div>

        <div className="about-us__button-block">
          <button
            className="btn btn__blue"
            type="button"
            onClick={handleGoTastes}
          >
            Все вкусы
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseTaste;
