export const categoriesDataBlog = [
  {
    icon: "💙",
    title: "Здоровье",
    description: "Полезные статьи о здоровье, поддержании иммунитета и спортивном образе жизни.",
    link: "/health-blog/"
  },
  {
    icon: "🥗",
    title: "Питание",
    description: "Все о правильном питании, баланс макронутриентов и лучшие диеты.",
    link: "/nutrition/"
  },
  {
    icon: "🏋️",
    title: "Тренировки",
    description: "Эффективные тренировки, советы по фитнесу и улучшению физической формы.",
    link: "/training/"
  }
];
