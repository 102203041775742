export const ketoBoosterProducts = {
    'lychee-day-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Мощный жиросжигатель для похудения с L-карнитином, ДНЕВНОЙ ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Личи день  от Fit and Joy – это активный жиросжигатель с натуральными экстрактами, который способствует ускорению метаболизма и повышению энергии в течение дня.</p>
                <p>Продукт разработан для тех, кто стремится к идеальной форме и максимальной активности.</p>
            </>
        ),
        price: '650',
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'Мальтодекстрин, Л-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, стевия, ананаса экстракт, хрома пиколинат, зелёного чая экстракт, краситель пищевой',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: ['lycheeday1.webp', 'lycheeday2.webp', 'lycheeday3.webp', 'Day.webp'],
        img: 'lycheeday1.webp',
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> Смещать 2 полные мерные ложки (5 гр) в стакане воды (200 мл)либо в любой другой жидкости
                    <br /> принимать 1-2 раза во первой половине дня.
                    <br />Перед применением баночку рекомендуется встряхнуть</p>
                <p> Одна банка рассчитана на 28 дней
                    <br />
                    Минимальный курс приема 90 дней</p>

            </>
        ),
    },
    'lychee-night-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Жиросжигатель для похудения с L карнитином, НОЧНОЙ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Личи ночь от Fit and Joy – это специально разработанный ночной комплекс, который помогает сжигать жир во время сна.</p>
                <p>Его формула поддерживает обмен веществ, способствует снижению аппетита и помогает организму восстанавливаться после физической нагрузки.</p>
            </>
        ),
        price: '600 ',
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'Мальтодекстрин, L-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, растительный сухой экстракт гарцинии, стевиозид, хрома пиколинат, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, краситель пищевой.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: ['lycheeNight1.webp', 'lycheeNight2.webp', 'lycheeNight3.webp', 'Day.webp'],
        img: 'lycheeNight1.webp',
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> Смещать 2 полные мерные ложки (5 гр) в стакане воды (200 мл)либо в    любой другой жидкости
                    <br /> принимать 1-2 раза во второй половине дня.
                    <br />Перед применением баночку рекомендуется встряхнуть</p>
                <p> Одна банка рассчитана на 28 дней
                    <br />
                    Минимальный курс приема 90 дней</p>

            </>
        ),
    },
    'raspberry-day-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Мощный жиросжигатель для похудения с L-карнитином, ДНЕВНОЙ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Малина день от Fit and Joy – это активный жиросжигатель с натуральными экстрактами, который способствует ускорению метаболизма и повышению энергии в течение дня.</p>
                <p>Продукт разработан для тех, кто стремится к идеальной форме и максимальной активности.</p>
            </>
        ),
        price: '650',
        tasteEng: 'raspberry',
        taste: 'Малина',
        textPurpose: 'Восстановление, Выносливость, Похудение, Ускорение метаболизма, Энергия',
        composition: 'Мальтодекстрин, Л-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, стевия, ананаса экстракт, хрома пиколинат, зелёного чая экстракт, краситель пищевой',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['энергия', 'жиросжигание', 'стройность', 'восстановление', 'поддержка метаболизма'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от солнца месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['raspberryDay1.webp', 'raspberryDay2.webp', 'raspberryDay3.webp', 'Day.webp'],
        img: 'raspberryDay1.webp',
        contraindications: (
            <>
                <p>Содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
                <p>При гипервитаминозе рекомендуется консультация врача.</p>
                <p>Содержит экстракт зелёного кофе – людям с нервными расстройствами перед применением необходима консультация специалиста.</p>
                <p>Не предназначен для детей младше 3 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Смешать 2 полные мерные ложки (5 г) в стакане воды (200 мл) или другой жидкости.</p>
                <p>Принимать 1-2 раза в первой половине дня.</p>
                <p>Перед применением баночку рекомендуется встряхнуть.</p>
                <p>Одна банка рассчитана на 28 дней.</p>
                <p>Минимальный курс приема – 90 дней.</p>
            </>
        ),
    },
    'raspberry-night-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Жиросжигатель для похудения с L-карнитином, НОЧНОЙ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Малина ночь от Fit and Joy – это специально разработанный ночной комплекс, который помогает сжигать жир во время сна.</p>
                <p>Его формула поддерживает обмен веществ, способствует снижению аппетита и помогает организму восстанавливаться после физической нагрузки.</p>
            </>
        ),
        price: '600',
        tasteEng: 'raspberry',
        taste: 'Малина',
        textPurpose: 'Ночной жиросжигатель, Восстановление, Улучшение обмена веществ, Контроль аппетита',
        composition: 'Мальтодекстрин, L-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, растительный сухой экстракт гарцинии, стевиозид, хрома пиколинат, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, краситель пищевой.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['сжигание жира', 'поддержка обмена веществ', 'восстановление во сне'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от солнца месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['raspberryNight1.webp', 'raspberryNight2.webp', 'raspberryNight3.webp', 'Day.webp'],
        img: 'raspberryNight1.webp',
        contraindications: (
            <>
                <p>Содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
                <p>При гипервитаминозе рекомендуется консультация врача.</p>
                <p>Содержит экстракт зелёного кофе – людям с нервными расстройствами перед применением необходима консультация специалиста.</p>
                <p>Не предназначен для детей младше 3 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Смешать 2 полные мерные ложки (5 г) в стакане воды (200 мл) или другой жидкости.</p>
                <p>Принимать 1-2 раза во второй половине дня.</p>
                <p>Перед применением баночку рекомендуется встряхнуть.</p>
                <p>Одна банка рассчитана на 28 дней.</p>
                <p>Минимальный курс приема – 90 дней.</p>
            </>
        ),
    },
    'mango-day-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Мощный жиросжигатель для похудения с L-карнитином, ДНЕВНОЙ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Манго день от Fit and Joy – это инновационная формула, созданная для ускоренного расщепления жиров, поддержания энергии и выносливости.</p>
                <p>Подходит для людей, ведущих активный образ жизни и следящих за фигурой.</p>
            </>
        ),
        price: '650',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: 'Восстановление, Выносливость, Похудение, Ускорение метаболизма, Энергия',
        composition: 'Мальтодекстрин, Л-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, стевия, ананаса экстракт, хрома пиколинат, зелёного чая экстракт, краситель пищевой',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['энергия', 'жиросжигание', 'стройность', 'восстановление', 'поддержка метаболизма'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от солнца месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['mangoDay1.webp', 'mangoDay2.webp', 'mangoDay3.webp', 'Day.webp'],
        img: 'mangoDay1.webp',
        contraindications: (
            <>
                <p>Содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
                <p>При гипервитаминозе рекомендуется консультация врача.</p>
                <p>Содержит экстракт зелёного кофе – людям с нервными расстройствами перед применением необходима консультация специалиста.</p>
                <p>Не предназначен для детей младше 3 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Смешать 2 полные мерные ложки (5 г) в стакане воды (200 мл) или другой жидкости.</p>
                <p>Принимать 1-2 раза в первой половине дня.</p>
                <p>Перед применением баночку рекомендуется встряхнуть.</p>
                <p>Одна банка рассчитана на 28 дней.</p>
                <p>Минимальный курс приема – 90 дней.</p>
            </>
        ),
    },
    'mango-night-keto-booster': {
        categoryName: "жиросжигатели",
        title: 'Жиросжигатель для похудения с L-карнитином, НОЧНОЙ',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Манго ночь от Fit and Joy – это формула для эффективного сжигания жира во время сна, поддержания обмена веществ и ускоренного восстановления организма.</p>
                <p>Помогает снижать вес даже ночью, обеспечивая мягкое действие без стимуляторов.</p>
            </>
        ),
        price: '600',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: 'Ночной жиросжигатель, Восстановление, Улучшение обмена веществ, Контроль аппетита',
        composition: 'Мальтодекстрин, L-карнитин тартрат, ароматизатор пищевой, регулятор кислотности лимонная кислота, растительный сухой экстракт гарцинии, стевиозид, хрома пиколинат, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, краситель пищевой.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        purposes: ['сжигание жира', 'поддержка обмена веществ', 'восстановление во сне'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от солнца месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['mangoNight1.webp', 'mangoNight2.webp', 'mangoNight3.webp', 'Day.webp'],
        img: 'mangoNight1.webp',
        contraindications: (
            <>
                <p>Содержит лимонную кислоту и ароматизаторы, которые могут вызвать аллергические реакции.</p>
                <p>При гипервитаминозе рекомендуется консультация врача.</p>
                <p>Содержит экстракт зелёного кофе – людям с нервными расстройствами перед применением необходима консультация специалиста.</p>
                <p>Не предназначен для детей младше 3 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Смешать 2 полные мерные ложки (5 г) в стакане воды (200 мл) или другой жидкости.</p>
                <p>Принимать 1-2 раза во второй половине дня.</p>
                <p>Перед применением баночку рекомендуется встряхнуть.</p>
                <p>Одна банка рассчитана на 28 дней.</p>
                <p>Минимальный курс приема – 90 дней.</p>
            </>
        ),
    },
    'fat-burner': {
        categoryName: "витамины и минералы",
        title: 'Жиросжигатель в капсулах – Активное снижение веса',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Жиросжигатель от Fit and Joy – это мощный комплекс, который помогает ускорить обмен веществ и повысить эффективность тренировок.</p>
                <p>Подходит для тех, кто хочет быстрее добиться стройного тела.</p>
            </>
        ),
        price: '890',
        taste: 'Без вкуса',
        textPurpose: 'Снижение веса, Ускорение метаболизма, Повышение энергии',
        purposes: ['жиросжигание', 'стройность', 'ускорение обмена веществ', 'повышение выносливости'],
        composition: 'Л-карнитин, стеарат магния, мальтодекстрин,кальций карбонат, аскорбиновая кислота,; экстракт ананаса, экстракт гуараны, экстракт зеленого чая; экстракт гарцинии, альфа-липоевая кислота,; желатиновая капсула (желатин, загуститель, вода, краситель пищевой).',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'Хранить в защищенном от света месте.',
        gender: 'Подходит для мужчин и женщин',
        images: ['FatCapsules1.webp', 'FatCapsules2.webp', 'FatCapsules3.webp'],
        img: 'FatCapsules1.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется при сердечно-сосудистых заболеваниях.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Взрослым по одной капсуле 3 раза в день во время еды</p>
                <p>Курс – 1 месяц.</p>
            </>
        ),
    },
}