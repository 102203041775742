export const categoriesData = [
  {
    icon: "🍫",
    title: "Протеиновые батончики",
    description: "Насыщенные белками батончики для быстрой энергии и вкусного перекуса. Идеально для тех, кто хочет победить голод и оставаться в форме, даже если утренний подъём напоминает борьбу с медведем.",
    link: "/products/protein-bars/",
  },
  {
    icon: "🥣",
    title: "Супы",
    description: "Питательные и ароматные супы для полноценного питания. Горячий комфорт в каждой ложке, который согреет душу и тело в холодные дни.",
    link: "/products/protein-soup/",
  },
  {
    icon: "🥤",
    title: "Протеиновые коктейли",
    description: "Смешанные коктейли, наполненные белком и вкусом, чтобы каждый глоток превращался в мини-праздник для вашего организма. Идеально для восстановления после тренировок и не только!",
    link: "/products/slim-effect/",
  },
  {
    icon: "🍃",
    title: "Детокс",
    description: "Легкие и эффективные программы очищения, которые помогут вам почувствовать себя обновленной. Время избавиться от лишнего и позволить организму насладиться 'новой жизнью'.",
    link: "/products/detox/",
  },
  {
    icon: "💊",
    title: "Витамины и минералы",
    description: "Комплекс необходимых микроэлементов для поддержания вашего здоровья. Настоящий эликсир для тех, кто ценит каждую каплю энергии!",
    link: "/products/vitamins/",
  },
  {
    icon: "🔥",
    title: "Жиросжигатели",
    description: "Суперсредства для ускорения обмена веществ и борьбы с лишними калориями. Если ваш гардероб мечтает о меньших размерах, этот раздел для вас!",
    link: "/products/keto-booster/",
  },
  {
    icon: "💧",
    title: "Коллаген",
    description: "Средства для поддержания упругости кожи и здоровья суставов. Ваша секретная формула молодости, чтобы выглядеть так, как будто время остановилось.",
    link: "/products/collagen/",
  },
  {
    icon: "🥥",
    title: "Кокосовые батончики",
    description: "Вкусный и полезный перекус без сахара! С его помощью вы без проблем восполните дневную норму белка, а также сократите количество приемов пищи и эффективно сбросите лишний вес",
    link: "/products/coconut-bars/",
  },
  {
    icon: "🥜",
    title: "ореховые батончики",
    description: "Ореховые спортивные батончики в шоколаде от FIT AND JOY это диетическое низкокалорийное питание без глютена для тех, кто занимается спортом и следит за своим весом.",
    link: "/products/nut-bars/",
  },
];


