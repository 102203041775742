import React, { useEffect, useState } from "react";

import "./MainSlider.css";

import Slider1 from "../../images/MainSlider1.webp";
import Slider2 from "../../images/MainSlider2.webp";
import Slider3 from "../../images/MainSlider3.webp";
import arrowBig from "../../images/arrowBig.webp";

const imgData = [
  {
    img: Slider1,
    alt: "Натуральные добавки для здоровья и красивого тела — протеины, витамины и БАДы",
  },
  {
    img: Slider2,
    alt: "Растительные детокс-коктейли для очищения организма — натуральные витамины и добавки",
  },
  {
    img: Slider3,
    alt: "Лучшие продукты для здоровья и спорта: коктейли, протеины, БАДы и витамины",
  },
];

const MainSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imgData.length);
    } else if (direction === "prev") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + imgData.length) % imgData.length
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imgData.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <section className="main-slider" id="main-slider">
      <div className="main-slider__wrapper">
        <div className="main-slider__container">
          <img
            className="main-slider__container-img"
            src={imgData[currentIndex].img}
            alt={imgData[currentIndex].alt}
          />
          <img
            className="main-slider__arrow main-slider__left-arrow hover"
            src={arrowBig}
            alt="Влево"
            onClick={() => handleArrowClick("prev")}
          />
          <img
            className="main-slider__arrow hover"
            src={arrowBig}
            alt="Вправо"
            onClick={() => handleArrowClick("next")}
          />
        </div>
      </div>
    </section>
  );
};

export default MainSlider;
