import React, { useState } from "react";

import "./Menu.css";
import burgermenu from "../../images/menu.webp";

import BurgerMenu from "../BurgerMenu/BurgerMenu";

const Menu = ({ isMobile, headerHeight }) => {
  if (!isMobile) {
    return null;
  }

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerButtonClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <section className="menu">
      <button
        type="button"
        className="menu__close"
        onClick={handleBurgerButtonClick}
      >
        <img src={burgermenu} alt="Иконка открытия бургер меню" />
      </button>
      {isBurgerMenuOpen && (
        <BurgerMenu
          handleBurgerButtonClick={handleBurgerButtonClick}
          isMobile={isMobile}
          headerHeight={headerHeight}
        />
      )}
    </section>
  );
};
export default Menu;
