import React from "react";
import "./ReviewScreens.css";

const correctReviewScreens = [
  {
    image: "/images/correct-review-1.webp",
    description:
      "Отзыв о купленном товаре взят из личного кабинета пользователя.",
  },
  { image: "/images/correct-review-2.webp" },
];

const incorrectReviewScreens = [
  {
    image: "/images/wrong-review-1.webp",
    description:
      "Отзыв взят из общих комментариев к товару, и подлежит сомнению о покупке и его индивидуальности.",
  },
  {
    image: "/images/wrong-review-2.webp",
    description: " Отзыв не был опубликован.",
  },
];

const correctOrderScreens = [
  { image: "/images/correct-order-1.webp" },
  { image: "/images/correct-order-2.webp" },
];

const incorrectOrderScreens = [
  { image: "/images/wrong-order-1.webp" },
  { image: "/images/wrong-order-2.webp" },
];

const ReviewScreens = () => {
  return (
    <section className="review-section">
      <h3 className="review__important-title">Обратите внимание</h3>
      <div className="review-container">
        <div className="review-container__block">
          {correctReviewScreens.map((screen, index) => (
            <div className="review-item correct" key={index}>
              <h2 className="review-title">Верный вариант скрина отзыва</h2>
              <img
                src={screen.image}
                alt="Верный скрин"
                className="review-image"
              />
              <p className="review-text">{screen.description}</p>
            </div>
          ))}
        </div>

        <div className="review-container__block">
          {incorrectReviewScreens.map((screen, index) => (
            <div className="review-item incorrect" key={index}>
              <h2 className="review-title">Неверный вариант скрина отзыва</h2>
              <img
                src={screen.image}
                alt="Неверный скрин"
                className="review-image"
              />
              <p className="review-text">{screen.description}</p>
            </div>
          ))}
        </div>

        <div className="review-container__block">
          {correctOrderScreens.map((screen, index) => (
            <div className="review-item correct" key={index}>
              <h2 className="review-title">Верный вариант скрина заказа</h2>
              <img
                src={screen.image}
                alt="Верный скрин заказа"
                className="review-image"
              />
              <p className="review-text">{screen.description}</p>
            </div>
          ))}
        </div>

        <div className="review-container__block">
          {incorrectOrderScreens.map((screen, index) => (
            <div className="review-item incorrect" key={index}>
              <h2 className="review-title">Неверный вариант скрина заказа</h2>
              <img
                src={screen.image}
                alt="Неверный скрин заказа"
                className="review-image"
              />
              <p className="review-text">{screen.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReviewScreens;
