import React from "react";

import "./TermsAndConditions.css";

const TermsAndConditions = ({
  headerHeight,
  handleGoBack,
  handlePrivacyPolicyClick,
}) => (
  <section
    className="terms-and-conditions"
    style={{ paddingTop: `${headerHeight}px` }}
  >
    <div className="terms-and-conditions__wrapper">
      <button className="btn btn__violet" onClick={handleGoBack}>
        Назад
      </button>
      <h2 className="terms-and-conditions__title">Правила и условия</h2>

      <h3 className="terms-and-conditions__subtitle">
        1. Согласие с условиями
      </h3>
      <p className="terms-and-conditions__text">
        Отправляя форму, вы подтверждаете, что прочитали, поняли и согласны с
        этими правилами и условиями.
      </p>
      <h3 className="terms-and-conditions__subtitle">2. Конфиденциальность</h3>
      <p className="terms-and-conditions__text">
        Ваша информация будет использоваться в соответствии с нашей{" "}
        <span className="link-like" onClick={handlePrivacyPolicyClick}>
          Политикой конфиденциальности
        </span>
        . Мы обязуемся защищать вашу личную информацию и использовать ее только
        для указанных целей.
      </p>

      <h3 className="terms-and-conditions__subtitle">
        3. Сбор и использование данных
      </h3>
      <p className="terms-and-conditions__text">
        Мы можем собирать и использовать вашу информацию для улучшения наших
        услуг, связи с вами, обработки ваших запросов и других соответствующих
        целей. Мы не будем передавать ваши данные третьим лицам без вашего
        согласия, за исключением случаев, предусмотренных законом.
      </p>
      <h3 className="terms-and-conditions__subtitle">
        4. Ограничение ответственности
      </h3>
      <p className="terms-and-conditions__text">
        Компания не несет ответственности за любые прямые или косвенные убытки,
        возникшие в результате использования или невозможности использования
        предоставленной информации или услуг.
      </p>
      <h3 className="terms-and-conditions__subtitle">
        5. Изменения в правилах и условиях
      </h3>
      <p className="terms-and-conditions__text">
        Мы оставляем за собой право изменять эти правила и условия в любое время
        без предварительного уведомления. Обновленные условия будут опубликованы
        на нашем веб-сайте.
      </p>
      <h3 className="terms-and-conditions__subtitle">6. Связь с нами</h3>
      <p className="terms-and-conditions__text">
        Если у вас есть вопросы или предложения по поводу наших правил и
        условий, пожалуйста, свяжитесь с нами: loveourclients@yandex.ru
      </p>
      <button className="btn btn__violet" onClick={handleGoBack}>
        Назад
      </button>
    </div>
  </section>
);

export default TermsAndConditions;
