import { useEffect, useCallback } from "react";

export default function useHighlight(isHighlightPage) {
  const highlightText = useCallback((query) => {
    if (!query) return;

    const elements = document.querySelectorAll("p, h1, h2, h3, h4, h5, h6, span");
    const regex = new RegExp(`(${query})`, "gi");

    elements.forEach((el) => {
      if (regex.test(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace(regex, '<span class="highlighted">$1</span>');
      }
    });
  }, []);

  const clearHighlights = useCallback(() => {
    document.querySelectorAll(".highlighted").forEach((el) => {
      el.outerHTML = el.innerHTML;
    });
  }, []);

  useEffect(() => {
    const query = localStorage.getItem("searchQuery");

    if (isHighlightPage && query) {

      highlightText(query);

      localStorage.removeItem("searchQuery");
    } else if (!isHighlightPage) {

      clearHighlights();
    }
  }, [isHighlightPage, highlightText, clearHighlights]);

  return { highlightText, clearHighlights };
}
