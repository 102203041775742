export const detoxProducts = {
    'detox-cherry': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Detox Вишня — это дренажный напиток, разработанный для мягкого очищения организма, выведения лишней жидкости и поддержания водного баланса. Комплекс активных растительных экстрактов способствует ускорению обмена веществ, улучшает самочувствие и помогает контролировать вес. Напиток обогащен витаминами и антиоксидантами, поддерживающими здоровье кожи, иммунную систему и общее состояние организма.</p>
                <br />
                <p>Гарциния камбоджийская и гуарана помогают регулировать аппетит, а зелёный чай оказывает тонизирующее и антиоксидантное действие. Ананасовый экстракт способствует пищеварению, а комплекс витаминов группы B поддерживает энергообмен.</p>
            </>
        ),
        tasteEng: 'cherry',
        taste: 'Вишня',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        price: '400',
        purposes: ['стройность', 'детокс', 'здоровье'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом темном месте',
        gender: 'Женский, Мужской',
        images: ['DChrr1.webp', 'DChrr2.webp', 'DChrr3.webp', 'Dtx.webp'],
        img: 'DChrr1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'detox-lychee': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Дренажный напиток Личи является отличным средством для мягкого очищения организма, которое необходимо каждому человеку. Данный напиток разработан с целью борьбы с водным весом, а также для очищения организма от шлаков и токсинов.Это мягкое, но надежное очищение организма. </p>
                <br />
                <p>Гарциния камбоджийская и гуарана помогают регулировать аппетит, а зелёный чай оказывает тонизирующее и антиоксидантное действие. Ананасовый экстракт способствует пищеварению, а комплекс витаминов группы B поддерживает энергообмен.</p>
            </>
        ),
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '350',
        purposes: ['стройность', 'детокс', 'здоровье',],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: ['DLch1.webp', 'DLch2.webp', 'DLch3.webp', 'Dtx.webp'],
        img: 'DLch1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'detox-mango': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Detox Манго — это дренажный напиток, разработанный для эффективного выведения лишней жидкости из организма, поддержки обменных процессов и улучшения самочувствия. Благодаря сбалансированному составу с растительными экстрактами и витаминами, напиток способствует очищению организма, нормализации пищеварения и контролю веса.</p>
                <br />
                <p>Экстракты гарцинии камбоджийской и гуараны помогают снизить чувство голода, зелёный чай поддерживает обмен веществ и обладает антиоксидантным эффектом, а ананасовый экстракт стимулирует пищеварение. Комплекс витаминов группы B способствует энергетическому обмену и повышению работоспособности.</p>
            </>
        ),
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '390',
        purposes: ['стройность', 'детокс', 'здоровье', 'стройность'
        ],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: ['DM1.webp', 'DM2.webp', 'DM3.webp', 'Dtx.webp'],
        img: 'DM1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'detox-melon': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Detox Дыня — это дренажный напиток, разработанный для мягкого очищения организма, выведения лишней жидкости и поддержания водного баланса. Комплекс активных растительных экстрактов способствует ускорению обмена веществ, улучшает самочувствие и помогает контролировать вес. Напиток обогащен витаминами и антиоксидантами, поддерживающими здоровье кожи, иммунную систему и общее состояние организма.</p>
                <br />
                <p>Гарциния камбоджийская и гуарана помогают регулировать аппетит, а зелёный чай оказывает тонизирующее и антиоксидантное действие. Ананасовый экстракт способствует пищеварению, а комплекс витаминов группы B поддерживает энергообмен.</p>
            </>
        ),
        tasteEng: 'melon',
        taste: 'Дыня',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        price: '370',
        purposes: ['стройность', 'детокс'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом темном месте',
        gender: 'Женский, Мужской',
        images: ['DMln1.webp', 'DMln2.webp', 'DMln3.webp', 'Dtx.webp'],
        img: 'DMln1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'detox-pineapple': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Detox Ананас — это дренажный напиток, разработанный для эффективного выведения лишней жидкости из организма, поддержки обменных процессов и улучшения самочувствия. Благодаря сбалансированному составу с растительными экстрактами и витаминами, напиток способствует очищению организма, нормализации пищеварения и контролю веса.</p>
                <br />
                <p>Экстракты гарцинии камбоджийской и гуараны помогают снизить чувство голода, зелёный чай поддерживает обмен веществ и обладает антиоксидантным эффектом, а ананасовый экстракт стимулирует пищеварение. Комплекс витаминов группы B способствует энергетическому обмену и повышению работоспособности.</p>
            </>
        ),
        tasteEng: 'pineapple',
        taste: 'Ананас',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p>Не растворять в горячей воде</p></>,
        price: '400',
        purposes: ['стройность', 'детокс, очищение'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом темном месте',
        gender: 'Женский, Мужской',
        images: ['DPA1.webp', 'DPA2.webp', 'DPA3.webp', 'Dtx.webp'],
        img: 'DPA1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
    'detox-strawberry-kiwi': {
        categoryName: "дренажные Напитки",
        title: 'Напиток дренажный Detox от Fit and Joy',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Detox Клубника-киви — это дренажный напиток, разработанный для эффективного выведения лишней жидкости из организма, поддержки обменных процессов и улучшения самочувствия. Благодаря сбалансированному составу с растительными экстрактами и витаминами, напиток способствует очищению организма, нормализации пищеварения и контролю веса.</p>
                <br />
                <p>Экстракты гарцинии камбоджийской и гуараны помогают снизить чувство голода, зелёный чай поддерживает обмен веществ и обладает антиоксидантным эффектом, а ананасовый экстракт стимулирует пищеварение. Комплекс витаминов группы B способствует энергетическому обмену и повышению работоспособности.</p>
            </>
        ),
        tasteEng: 'strawberry, kiwi',
        taste: 'Клубника-киви',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Мальтодекстрин, кукурузный крахмал, пищевой ароматизатор, регулятор кислотности лимонная кислота, таурин, стевиозид, витамин С, растительный сухой экстракт гарцинии камбоджийской, растительный сухой экстракт зелёного чая, растительный сухой экстракт гуараны, растительный сухой экстракт ананаса, витамин В3, витамин Е, пищевой краситель, витамин В5, витамин В6, витамин В2, витамин В1, витамин В9, витамин D3.',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '350',
        purposes: ['детокс', 'здоровье'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: ['DSK1.webp', 'DSK2.webp', 'DSK3.webp', 'Dtx.webp'],
        img: 'DSK1.webp',
        contraindications: (
            <>
                <p>Противопоказано при индивидуальной непереносимости компонентов.</p>
                <p>Не рекомендуется при беременности и в период лактации.</p>
                <p>Лицам с заболеваниями почек, сердечно-сосудистой системы и повышенной нервной возбудимостью перед применением следует проконсультироваться с врачом.</p>
                <p>Не предназначено для детей младше 18 лет.</p>
            </>
        ),
        contentMethod: (
            <>
                <p>Размешайте две мерных ложки (5 гр) в тёплой воде или любой другой жидкости.</p>
                <p>Мерная ложечка в каждой банке.</p>
                <p>Принимать 1-2 раза в день, до или после еды.</p>
                <p>Минимальный курс 60-90 дней.</p>
            </>
        ),
    },
}