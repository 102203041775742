import React from "react";
import "./ContractManufacturing.css";
import PageHelmet from "../PageHelmet";

import ProductionSteps from "../ProductionSteps/ProductionSteps";

const ContractManufacturing = ({
  headerHeight,
  handleGoBack,
  handleGoMain,
}) => {
  return (
    <section className="contract" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title="Контрактное производство | Fit and Joy"
        description="Компания Fit and Joy приглашает к сотрудничеству по контрактному производству БАДов, витаминов и спортивного питания. Надежный партнер с гарантией качества."
        keywords="контрактное производство, БАДы, сотрудничество, витамины, спортивное питание, Fit and Joy"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/kontraktnoe-proizvodstvo/`}
        image="https://usafitandjoy.com/images/contract.webp"
      />
      <div className="contract__wrapper">
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={`${process.env.PUBLIC_URL}/images/contract.webp`}
              alt="контрактное производство Fit and Joy"
            />
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue blog__btn"
            type="button"
            onClick={handleGoBack}
          >
            Назад
          </button>
        </div>

        <div className="contract__container">
          <h1 className="tastes__title">Приглашаем к сотрудничеству</h1>
          <p className="contract__subtitle">
            Fit and Joy предлагает услуги контрактного производства БАДов,
            витаминов и продуктов спортивного питания.
          </p>

          <div className="contract__benefits">
            <h2 className="contract__section-title">Что мы предлагаем:</h2>
            <ul className="contract__list">
              <li>🔬 Современное сертифицированное производство</li>
              <li>📦 Упаковка под вашим брендом</li>
              <li>🧪 Разработка формул под ключ</li>
              <li>✅ Контроль качества на каждом этапе</li>
              <li>🚚 Быстрая доставка и логистика</li>
              <li>📈 Поддержка и консультации по выводу продукта на рынок</li>
            </ul>
          </div>
          <ProductionSteps />
          <div className="contract__cta">
            <h2 className="tastes__title">Станьте нашим партнером</h2>
            <p>
              <br /> Мы открыты для сотрудничества с компаниями, стартапами и
              частными брендами.
              <br /> У вас уже есть идея или готовая формула? <br />
              Или только план?
              <br />
              <br />
              <b>Мы поможем вам в реализации.</b>
            </p>
            <p>
              {" "}
              <br />
              Оставьте заявку — и мы свяжемся с вами в ближайшее время.
              <br />
              <br />
            </p>
            <a
              href="mailto:salesmanager@usafitandjoy.com"
              className="btn btn__blue podarok__contacts-btn"
            >
              📩 Написать нам
            </a>
          </div>
        </div>
        <div className="blog__block">
          <div className="blog__image-container">
            <img
              className="blog__img"
              src={`${process.env.PUBLIC_URL}/images/contract2.webp`}
              alt="контрактное производство Fit and Joy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContractManufacturing;
