import React, { useEffect } from "react";
import "./NotFoundPage.css";

const NotFoundPage = ({ headerHeight, handleGoBack, handleGoMain }) => {
  useEffect(() => {
    document.title = "Страница не найдена - 404";
    window.history.replaceState({}, "", "/404");
  }, []);

  return (
    <main>
      <section className="not-found-page">
        <div
          className="not-found-page__wrapper"
          style={{ paddingTop: `${headerHeight}px` }}
        >
          <h1
            className="not-found-page__title"
            style={{ paddingTop: `${headerHeight}px` }}
          >
            404
          </h1>
          <span className="not-found-page__subtitle">Страница не найдена</span>
          <button
            className="not-found-page__button btn__violet btn"
            onClick={handleGoBack}
          >
            НАЗАД
          </button>
          <button
            className="not-found-page__button btn__violet btn"
            onClick={handleGoMain}
          >
            НА ГЛАВНУЮ
          </button>
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
