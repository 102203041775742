export const proteinSoupProducts = {
    'chicken-soup': {
        categoryName: "Протеиновые супы",
        title: 'Диетический протеиновый куриный суп в пакетах быстрого приготовления, без варки',
        productType: 'Суп быстрого приготовления',
        description: (
            <>
                <p>Куриный протеиновый суп — это отличное решение для тех, кто ищет быстрый и полезный способ подкрепиться. Богатый белком, он помогает поддерживать энергию и насыщение надолго.</p>
                <p>Прост в приготовлении: просто залейте кипятком, перемешайте и наслаждайтесь вкусом. Подходит для работы, путешествий и активного образа жизни.</p>
                <p>Без консервантов и искусственных добавок!</p>
            </>
        ),
        price: '1200',
        tasteEng: 'chicken',
        taste: 'Курица',
        textPurpose: 'Сбалансированное питание и поддержание энергии',
        purposes: ['Поддержание формы', 'Быстрый перекус', 'для мужчин', 'для женщин'],
        composition: 'Лук сушеный, изолят соевого белка, картофель сушеный, соль поваренная, ароматизатор пищевой, чеснок сушеный, мальтодекстрин, мука гороховая, укроп сушеный, специи (куркума, перец душистый), сельдерей сушеный, сушеное мясо курицы, концентрат молочного белка, эмульгаторы (глутамат натрия, диоксид кремния).',
        text: '16 супов в коробке',
        country: 'Россия',
        expirationDate: '12 месяцев',
        contentMethod: (
            <>
                <p>Содержимое пакета высыпать в кружку и залить 200 мл кипящей воды.</p>
                <p>Тщательно размешать до однородного состояния.</p>
                <p>Дать настояться в течение 3 минут.</p>
                <p><b>Осторожно! Используйте горячую воду с осторожностью, чтобы избежать ожогов.</b></p>
            </>
        ),
        storageConditions: (
            <>
                <p>Хранить в сухом месте, без доступа влаги, в оригинальной упаковке.</p>
                <p><b>Минимальная температура хранения:</b> +5 °C</p>
                <p><b>Максимальная температура хранения:</b> +29 °C</p>
            </>
        ),
        gender: 'Подходит для мужчин и женщин',
        images: ['chicken.webp', 'soup2.webp', 'soup3.webp', 'soup4.webp'],
        img: 'chicken.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется людям с аллергией на молочные продукты, сою или глютамат натрия.</p>
                <p>Перед употреблением проконсультируйтесь с врачом при наличии хронических заболеваний.</p>
            </>
        ),
        water: <>
            <br /> <p><b>Пищевая ценность белки:</b>	8.5 г/100г</p>
            <p><b>Пищевая ценность жиры:</b>	9.5 г/100г</p>
            <p><b>Пищевая ценность углеводы:</b>	54.5 г/100г</p>
            <p><b>Энергетическая ценность калории (на 100 гр.):</b>	337 ккал</p>
        </>,
    },

    'miso-soup': {
        categoryName: "Протеиновые супы",
        title: 'Диетический протеиновый мисо-суп с традиционным японским вкусом',
        productType: 'Суп быстрого приготовления',
        description: (
            <>
                <p>Мисо-суп — это классика японской кухни, теперь в удобном и полезном формате! Он насыщен белком и натуральными ингредиентами, сохраняя аутентичный вкус.</p>
                <p>Идеально подходит для тех, кто хочет добавить в свой рацион что-то легкое, питательное и необычное.</p>
                <p>Готовится за 3 минуты — удобно и вкусно!</p>
            </>
        ),
        water: <>
            <br /> <p><b>Пищевая ценность белки:</b>	8.5 г/100г</p>
            <p><b>Пищевая ценность жиры:</b>	9.5 г/100г</p>
            <p><b>Пищевая ценность углеводы:</b>	54.5 г/100г</p>
            <p><b>Энергетическая ценность калории (на 100 гр.):</b>	337 ккал</p>
        </>,
        price: '1200',
        tasteEng: 'miso',
        taste: 'Мисо',
        textPurpose: 'Сбалансированное питание и поддержание энергии',
        purposes: ['энергия', 'Быстрый перекус', 'для мужчин', 'энергия'],
        composition: 'Лук сушеный, изолят соевого белка, картофель сушеный, соль поваренная, ароматизатор пищевой, чеснок сушеный, мальтодекстрин, мука гороховая, укроп сушеный, специи (куркума, перец душистый), сельдерей сушеный, сушеное мясо курицы, концентрат молочного белка, эмульгаторы (глутамат натрия, диоксид кремния).',
        text: '16 супов в коробке',
        country: 'Россия',
        expirationDate: '12 месяцев',
        contentMethod: (
            <>
                <p>Содержимое пакета высыпать в кружку и залить 200 мл кипящей воды.</p>
                <p>Тщательно размешать до однородного состояния.</p>
                <p>Дать настояться в течение 3 минут.</p>
                <p><b>Осторожно! Используйте горячую воду с осторожностью, чтобы избежать ожогов.</b></p>
            </>
        ),
        storageConditions: (
            <>
                <p>Хранить в сухом месте, без доступа влаги, в оригинальной упаковке.</p>
                <p><b>Минимальная температура хранения:</b> +5 °C</p>
                <p><b>Максимальная температура хранения:</b> +29 °C</p>
            </>
        ),
        gender: 'Подходит для мужчин и женщин',
        images: ['miso.webp', 'soup2.webp', 'soup3.webp', 'soup4.webp'],
        img: 'miso.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется людям с аллергией на молочные продукты, сою или глютамат натрия.</p>
                <p>Перед употреблением проконсультируйтесь с врачом при наличии хронических заболеваний.</p>
            </>
        ),
    },

    'borscht-soup': {
        categoryName: "Протеиновые супы",
        title: 'Диетический протеиновый борщ с натуральными овощами',
        productType: 'Суп быстрого приготовления',
        description: (
            <>
                <p>Борщ — одно из самых любимых блюд! Теперь он стал еще полезнее, благодаря высокому содержанию белка и отсутствию вредных добавок.</p>
                <p>Яркий вкус натуральных овощей делает этот суп неотличимым от домашнего борща. Легкость приготовления делает его отличным вариантом для обеда или ужина.</p>
                <p>Просто добавьте кипяток — и наслаждайтесь!</p>
            </>
        ),
        water: <>
            <br /> <p><b>Пищевая ценность белки:</b>	8.5 г/100г</p>
            <p><b>Пищевая ценность жиры:</b>	9.5 г/100г</p>
            <p><b>Пищевая ценность углеводы:</b>	54.5 г/100г</p>
            <p><b>Энергетическая ценность калории (на 100 гр.):</b>	337 ккал</p>
        </>,
        price: '1100',
        tasteEng: 'borscht',
        taste: 'Борщ',
        textPurpose: 'Сбалансированное питание и поддержание энергии',
        purposes: ['Поддержание формы', 'Быстрый перекус', 'для женщин', 'энергия'],
        composition: 'Лук сушеный, изолят соевого белка, картофель сушеный, соль поваренная, ароматизатор пищевой, чеснок сушеный, мальтодекстрин, мука гороховая, укроп сушеный, специи (куркума, перец душистый), сельдерей сушеный, сушеное мясо курицы, концентрат молочного белка, эмульгаторы (глутамат натрия, диоксид кремния).',
        text: '16 супов в коробке',
        country: 'Россия',
        expirationDate: '12 месяцев',
        contentMethod: (
            <>
                <p>Содержимое пакета высыпать в кружку и залить 200 мл кипящей воды.</p>
                <p>Тщательно размешать до однородного состояния.</p>
                <p>Дать настояться в течение 3 минут.</p>
                <p><b>Осторожно! Используйте горячую воду с осторожностью, чтобы избежать ожогов.</b></p>
            </>
        ),
        storageConditions: (
            <>
                <p>Хранить в сухом месте, без доступа влаги, в оригинальной упаковке.</p>
                <p><b>Минимальная температура хранения:</b> +5 °C</p>
                <p><b>Максимальная температура хранения:</b> +29 °C</p>
            </>
        ),
        gender: 'Подходит для мужчин и женщин',
        images: ['borscht.webp', 'soup2.webp', 'soup3.webp', 'soup4.webp'],
        img: 'borscht.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется людям с аллергией на молочные продукты, сою или глютамат натрия.</p>
                <p>Перед употреблением проконсультируйтесь с врачом при наличии хронических заболеваний.</p>
            </>
        ),
    },

    'tomato-soup': {
        categoryName: "Протеиновые супы",
        title: 'Диетический протеиновый томатный суп с насыщенным вкусом',
        productType: 'Суп быстрого приготовления',
        description: (
            <>
                <p>Томатный суп — легкий, но насыщенный вариант полезного обеда! В нем много белка, натуральных специй и насыщенный вкус свежих помидоров.</p>
                <p>Идеально для тех, кто хочет согреться и насладиться нежной текстурой классического томатного супа.</p>
                <p>Легкий в приготовлении — просто залейте кипятком!</p>
            </>
        ),
        water: <>
            <br /> <p><b>Пищевая ценность белки:</b>	8.5 г/100г</p>
            <p><b>Пищевая ценность жиры:</b>	9.5 г/100г</p>
            <p><b>Пищевая ценность углеводы:</b>	54.5 г/100г</p>
            <p><b>Энергетическая ценность калории (на 100 гр.):</b>	337 ккал</p>
        </>,
        price: '1250',
        tasteEng: 'tomato',
        taste: 'Томат',
        textPurpose: 'Сбалансированное питание и поддержание энергии',
        purposes: ['Поддержание формы', 'Быстрый перекус', 'Диетическое питание'],
        composition: 'Лук сушеный, изолят соевого белка, картофель сушеный, соль поваренная, ароматизатор пищевой, чеснок сушеный, мальтодекстрин, мука гороховая, укроп сушеный, специи (куркума, перец душистый), сельдерей сушеный, сушеное мясо курицы, концентрат молочного белка, эмульгаторы (глутамат натрия, диоксид кремния).',
        text: '16 супов в коробке',
        country: 'Россия',
        expirationDate: '12 месяцев',
        contentMethod: (
            <>
                <p>Содержимое пакета высыпать в кружку и залить 200 мл кипящей воды.</p>
                <p>Тщательно размешать до однородного состояния.</p>
                <p>Дать настояться в течение 3 минут.</p>
                <p><b>Осторожно! Используйте горячую воду с осторожностью, чтобы избежать ожогов.</b></p>
            </>
        ),
        storageConditions: (
            <>
                <p>Хранить в сухом месте, без доступа влаги, в оригинальной упаковке.</p>
                <p><b>Минимальная температура хранения:</b> +5 °C</p>
                <p><b>Максимальная температура хранения:</b> +29 °C</p>
            </>
        ),
        gender: 'Подходит для мужчин и женщин',
        images: ['tomato.webp', 'soup2.webp', 'soup3.webp', 'soup4.webp'],
        img: 'tomato.webp',
        contraindications: (
            <>
                <p>Индивидуальная непереносимость компонентов.</p>
                <p>Не рекомендуется людям с аллергией на молочные продукты, сою или глютамат натрия.</p>
                <p>Перед употреблением проконсультируйтесь с врачом при наличии хронических заболеваний.</p>
            </>
        ),
    },
};
