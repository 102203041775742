import React from "react";

import PageHelmet from "../PageHelmet";

import "./Contacts.css";

import MarketplaceLink from "../MarketplaceLink";
import ImageGrid from "../ImageGrid/ImageGrid";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";

import GiveGift from "../../images/GiveGift.webp";

const Contacts = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleTelegramClickSupport,
  handleTelegramClickFJ,
  handleVkClick,
  handleGoPodarok,
}) => {
  const handleEmailClickSupport = () => {
    window.location.href = `mailto:info@usafitandjoy.com`;
  };

  return (
    <section
      className="contacts"
      id="contacts"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Контакты Fit and Joy – Свяжитесь с нами"
        description="Свяжитесь с Fit and Joy – ведущим поставщиком спортивного питания, БАДов и витаминов. Наши специалисты готовы помочь вам с любыми вопросами по заказу, доставке и продуктам."
        keywords="контакты Fit and Joy, как связаться, клиентская поддержка, БАДы, витамины, спортивное питание, протеин, коллаген"
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/contacts/`}
        image="https://usafitandjoy.com/images/contacts.webp"
      />

      <div className="podarok__wrapper">
        <div className="podarok__block">
          <div className="podarok__image-container">
            <img
              className="podarok__img"
              src={`${process.env.PUBLIC_URL}/images/contacts.webp`}
              alt="контакты fit and joy"
            />
          </div>
          <div className="contacts__block-text">
            <h1 className="contacts__block-title">CВЯЖИТЕСЬ С НАМИ</h1>

            <p className="contacts__block-subtitle">
              Используйте любой удобный способ связи с нами. Мы всегда готовы
              вам помочь, а также ответить на все ваши вопросы.
            </p>
          </div>
        </div>
        <div className="about-us__button-block">
          <button
            className="btn btn__blue podarok__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="btn btn__blue podarok__btn"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <div className="contacts__block">
          <div className="contacts__block-item">
            <p className="contacts__note">
              Написать в клиентскую службу в Telegram @loveourclients
            </p>
            <button
              className="contacts__contacts-btn btn btn__blue"
              onClick={handleTelegramClickSupport}
            >
              Telegram
            </button>
          </div>
          <div className="contacts__block-item">
            <p className="contacts__note">
              Написать на почту info@usafitandjoy.com
            </p>
            <button
              className="contacts__contacts-btn btn btn__blue"
              onClick={handleEmailClickSupport}
            >
              &nbsp; Email &nbsp;
            </button>
          </div>
        </div>
        <div className="main__block">
          <div className="main__image-container">
            <img
              className="main__container-img"
              src={GiveGift}
              alt="Подарок от Fit and Joy"
            />

            <button className="main__btn" onClick={handleGoPodarok}>
              ПОЛУЧИТЬ ПОДАРОК
            </button>
          </div>
        </div>
        <div className="main__block">
          <h2 className="main__subtitle">
            Присоединяйтесь к нашему сообществу!
          </h2>
          <p className="main__text">
            Получайте полезные советы, вдохновение и эксклюзивные предложения в
            наших соцсетях.
          </p>
          <ul className="main__list">
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                alt="Иконка телеграмм"
                onClick={handleTelegramClickFJ}
              />
            </li>
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                alt="Иконка VK"
                onClick={handleVkClick}
              />
            </li>
          </ul>
        </div>
        <div className="main__block">
          <h2 className="main__subtitle hover" onClick={handleGoStore}>
            Где купить?
          </h2>
          <p className="main__text">
            Продукция Fit and Joy доступна в ведущих магазинах и маркетплейсах.
            Найдите удобный способ покупки!
          </p>
          <div className="main__marketplace-list">
            {cardsMarketplaces.map((marketplace) => (
              <MarketplaceLink key={marketplace.name} href={marketplace.url}>
                <img
                  className="main__marketplace-item"
                  src={marketplace.img}
                  alt={marketplace.name}
                  title={marketplace.name}
                />
              </MarketplaceLink>
            ))}
          </div>
        </div>
        <ImageGrid />
      </div>
    </section>
  );
};

export default Contacts;
