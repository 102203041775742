import { proteinBarsProducts } from "../components/ProteinBars/proteinBarsProducts.js";
import { proteinSoupProducts } from "../components/ProteinSoup/proteinSoupProducts.js";
import { proteinSlimEffectProducts } from "../components/ProteinSlimEffect/proteinSlimEffectProducts.js";
import { detoxProducts } from "../components/Detox/detoxProducts.js";
import { vitaminsProducts } from "../components/Vitamins/vitaminsProducts.js";
import { ketoBoosterProducts } from "../components/KetoBooster/ketoBoosterProducts.js";
import { collagenProducts } from "../components/Collagen/collagenProducts.js";
import { coconutBarsProducts } from "../components/CoconutBars/coconutBarsProducts.js";
import { nutBarsProducts } from "../components/NutBars/nutBarsProducts.js";

const productDatabases = {
    "protein-bars": proteinBarsProducts,
    "protein-soup": proteinSoupProducts,
    "slim-effect": proteinSlimEffectProducts,
    "detox": detoxProducts,
    "vitamins": vitaminsProducts,
    "keto-booster": ketoBoosterProducts,
    "collagen": collagenProducts,
    "coconut-bars": coconutBarsProducts,
    "nut-bars": nutBarsProducts,
};

export default productDatabases;
