// src/utils/useSearchData.js
import { useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { proteinBarsProducts } from "../components/ProteinBars/proteinBarsProducts.js";
import { proteinSoupProducts } from "../components/ProteinSoup/proteinSoupProducts.js";
import { proteinSlimEffectProducts } from "../components/ProteinSlimEffect/proteinSlimEffectProducts.js";
import { detoxProducts } from "../components/Detox/detoxProducts.js";
import { vitaminsProducts } from "../components/Vitamins/vitaminsProducts.js";
import { ketoBoosterProducts } from "../components/KetoBooster/ketoBoosterProducts.js";
import { collagenProducts } from "../components/Collagen/collagenProducts.js";
import { coconutBarsProducts } from "../components/CoconutBars/coconutBarsProducts.js";
import { nutBarsProducts } from "../components/NutBars/nutBarsProducts.js";
import { articlesData } from "./articlesData";
import { articlesProducts } from "./articlesProducts.js";

const productDatabases = {
  "protein-bars": proteinBarsProducts,
  "protein-soup": proteinSoupProducts,
  "slim-effect": proteinSlimEffectProducts,
  "detox": detoxProducts,
  "vitamins": vitaminsProducts,
  "keto-booster": ketoBoosterProducts,
  "collagen": collagenProducts,
  "coconut-bars": coconutBarsProducts,
  "nut-bars": nutBarsProducts,
};

const extractTextFromJSX = (jsx) => {
  if (!jsx) return "";
  return renderToStaticMarkup(jsx)
    .replace(/<[^>]+>/g, " ")
    .replace(/\s+/g, " ")
    .trim();
};

export const useSearchData = () => {
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const initialData = [];

    // 1. Статьи (из articlesData)
    Object.entries(articlesData).forEach(([category, articles]) => {
      Object.entries(articles).forEach(([articleId, article]) => {
        initialData.push({
          url: `/${category}/${articleId}`, // например, /blog/123
          title: article.title,
          content: `${article.content} ${extractTextFromJSX(article.text)}`,
        });
      });
    });

    // 2. Товары (articlesProducts)
    Object.entries(articlesProducts).forEach(([productId, product]) => {
      initialData.push({
        url: `/products/${productId}/`,
        title: product.title,
        content: product.text,
      });
    });

    // 3. Товары из productDatabases
    Object.entries(productDatabases).forEach(([productId, products]) => {
      Object.entries(products).forEach(([key, product]) => {
        if (!product.title) return;
        initialData.push({
          url: `/products/${productId}/${key}/`,
          title: product.title,
          content: [
            product.taste,
            product.description,
            product.text,
            product.textPurpose,
            product.composition,
            product.storageConditions,
            product.purposes?.join(", "),
          ]
            .filter(Boolean)
            .join(" "),
        });
      });
    });

    setSearchData(initialData);
    // console.log("✅ Данные готовы:", initialData.length, "записей");
  }, []);

  return { searchData };
};
