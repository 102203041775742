import React from "react";
import "./BMICalculatorPage.css";
import PageHelmet from "../PageHelmet";

import BMICalculator from "../BMICalculator/BMICalculator";
import ImageGrid from "../ImageGrid/ImageGrid";

const BMICalculatorPage = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleTelegramClickFJ,
  handleVkClick,
}) => {
  return (
    <nav
      className="bmi-calculator"
      id="bmi-calculator"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Калькулятор BMI – Индекс массы тела | Fit and Joy"
        description="Рассчитайте свой индекс массы тела (BMI) с помощью удобного калькулятора от Fit and Joy. Узнайте, соответствует ли ваш вес норме, и получите рекомендации по улучшению физической формы."
        keywords="BMI, калькулятор BMI, индекс массы тела, нормальный вес, расчет веса, фитнес, здоровье, похудение, спортивное питание"
        canonicalUrl="https://usafitandjoy.com/bmi-calculator/"
        image="https://usafitandjoy.com/images/text-ingredients.webp"
      />

      <div className="bmi-calculator__wrapper">
        <div className="bmi-calculator__block">
          <img
            className="bmi-calculator__img"
            src={`${process.env.PUBLIC_URL}/images/premium-ingredients.webp`}
            alt="Занятия спортом"
          />
          <img
            className="bmi-calculator__img"
            src={`${process.env.PUBLIC_URL}/images/text-ingredients.webp`}
            alt="Занятия спортом"
          />
        </div>
        <div className="about-us__button-block">
          <button
            className="btn__blue btn about-us__btn"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="about-us__btn btn__blue btn"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <BMICalculator />
        <div className="main__block">
          <h2 className="main__subtitle">
            Присоединяйтесь к нашему сообществу!
          </h2>
          <p className="main__text">
            Получайте полезные советы, вдохновение и эксклюзивные предложения в
            наших соцсетях.
          </p>
          <ul className="main__list">
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                alt="Иконка телеграмм"
                onClick={handleTelegramClickFJ}
              />
            </li>
            <li className="main__icon hover">
              <img
                src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                alt="Иконка VK"
                onClick={handleVkClick}
              />
            </li>
          </ul>
        </div>
        <ImageGrid />
      </div>
    </nav>
  );
};

export default BMICalculatorPage;
