import React from "react";
import "./ProductionSteps.css";
import ProductionRequestForm from "../ProductionRequestForm/ProductionRequestForm";
const steps = [
  {
    number: "1",
    title: "Заявка",
    text: "Оставляете заявку на сайте",
  },
  {
    number: "2",
    title: "План и договор",
    text: "Согласовываете с экспертом план, объём и подписываете договор",
  },
  {
    number: "3",
    title: "Производство и отгрузка",
    text: "Выпускаем продукцию под вашим брендом и отправляем на маркетплейсы",
  },
  {
    number: "4",
    title: "Прибыль и стратегия",
    text: "Получаете прибыль и обсуждаем стратегию дальнейшего развития",
  },
];

const ProductionSteps = () => {
  return (
    <section className="production-steps">
      <h2 className="tastes__title">Как запустить контрактное производство</h2>
      <h3 className="contract__section-title">4 простых шага:</h3>
      <div className="production-steps__grid">
        {steps.map((step, index) => (
          <div className="step" key={index}>
            <div className="step__number">{step.number}</div>
            <div className="step__title">{step.title}</div>
            <div className="step__text">{step.text}</div>
          </div>
        ))}
      </div>
      <p className="production-steps__offer">
        🚀 Запустим ваши продажи на <b>Ozon</b> и <b>Wildberries</b> за 7 дней.
        <br />✅ Гарантия результата по договору и оплата по факту!
      </p>
      <ProductionRequestForm />
    </section>
  );
};

export default ProductionSteps;
