import detmir from "../images/childrenWorld.webp";
import ozon from "../images/ozon.webp";
import yandex from "../images/yandex.webp";
import wildberries from "../images/wb.webp";
import sportsmaster from "../images/sportsmaster.webp";

export const cardsMarketplaces = [
  { img: detmir, alt: "Детский мир", name: "За покупками в Детский мир", url: 'https://www.detmir.ru/catalog/index/name/sortforbrand/brand/24007/', text: 'Зайдите в личный кабинет в раздел "Мои заказы". Выберите товар Fit and Joy. Нажмите на кнопку "Оставить отзыв". Поставьте оценку, напишите комментарий. Дополните фото и/или видео. Отправьте отзыв.' },
  { img: ozon, alt: "Озон", name: "За покупками в Озон", url: 'https://www.ozon.ru/seller/premium-import-66726/products/', text: 'Зайдите в личный кабинет в раздел "Заказы". Выберите товар Fit and Joy. Нажмите на кнопку "Оценить товар"!!!! - отзыв о товаре, а не о качестве доставки. Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить отзыв".' },
  { img: yandex, alt: "ЯндексМаркет", name: "За покупками в ЯндексМаркет", url: 'https://market.yandex.ru/search?suggest_text=Fit%20And%20Joy&vendorId=20511170&rs=eJwzkvjEKMrBKLDwEKsEg8aBla0sGj9OsWqsPsnq1MTIJc3FxsEowavAIsAmxZmSmpZYmlMSb6TAoMFgxMClCJYUVGAFSvLDJA3jCxLTU1GVyCpwICsxQlECsYJRgRHZCkOIpACTF0eqWZJxmolBUpCRobmxpaWFmamRmbmxqb5RoqFBonmSYZKZRaKhuWliqolBYoq5UaqFkVGqgZmBgb6hviEA2kAv8g%2C%2C&glfilter=7893318%3A20511170', text: 'Зайдите в личный кабинет в раздел "Заказы". Выберите товар Fit and Joy. Поставьте оценку (количество звёзд). Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить".' },
  { img: wildberries, alt: "wildberries", name: "За покупками в Wildberries", url: 'https://www.wildberries.ru/brands/fit-and-joy', text: 'Зайдите в личный кабинет в раздел "Доставки", далее раздел "Покупки". Выберите товар Fit and Joy. Поставьте оценку (количество звёзд). Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить отзыв".' },
  { img: sportsmaster, alt: "Спортмастер", name: "За покупками в Спортмастер", url: 'https://www.sportmaster.ru/seller/108900299/', text: 'Зайдите в личный кабинет в раздел "Доставки", далее раздел "Покупки". Выберите товар Fit and Joy. Поставьте оценку (количество звёзд). Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить отзыв".' },
];


