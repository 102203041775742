import React from "react";
import "./ImageGrid.css";

const images = [
  "/images/grid1.webp",
  "/images/grid2.webp",
  "/images/grid3.webp",
  "/images/grid4.webp",
  "/images/grid5.webp",
  "/images/grid6.webp",
  "/images/grid7.webp",
  "/images/grid8.webp",
  "/images/grid9.webp",
  "/images/grid10.webp",
];

const ImageGrid = () => {
    return (
      <div className="image-grid-container">
        <div className="image-grid">
          {images.map((src, index) => (
            <div key={index} className="image-item">
              <img src={src} alt={`Product ${index + 1}`} className="image" />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageGrid;
