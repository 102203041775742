import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHelmet from "../PageHelmet";
import { articlesData } from "../../utils/articlesData";
import NotFoundPage from "./../NotFoundPage/NotFoundPage";
import "./Article.css";

const Article = ({ headerHeight, handleGoBlog }) => {
  const { category, articleId } = useParams();
  const article = articlesData[category]?.[articleId];
  const navigate = useNavigate();

  if (!article) {
    return (
      <NotFoundPage
        headerHeight={headerHeight}
        handleGoMain={handleGoBlog}
        handleGoBack={() => navigate(-1)}
      />
    );
  }

  const handleGoArticleList = () => {
    if (!category || !articleId) return;
    const categoryData = articlesData[category];

    if (categoryData && categoryData[articleId]) {
      navigate(`/${category}/`);
    } else {
      navigate("/blog");
    }
  };

  return (
    <div className="article" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title={`${article.title} | Блог Fit and Joy`}
        description={article.content.slice(0, 150)}
        keywords={`${article.title}, ${article.categoryName}, здоровье, питание, БАДы, фитнес`}
        canonicalUrl={`${process.env.REACT_APP_SITE_URL}/${category}/${articleId}/`}
        image={`https://usafitandjoy.com/images/articles/${article.img}`}
      />

      <div className="article__wrapper">
        <div className="btn-group">
          <button
            className="article-list__btn btn__blue btn"
            onClick={handleGoBlog}
          >
            К выбору темы
          </button>
          <button
            className="article-list__btn btn__violet btn"
            onClick={handleGoArticleList}
          >
            Назад к статьям про {article.categoryName}
          </button>
        </div>
        <img
          className="article__img"
          src={`${process.env.PUBLIC_URL}/images/articles/${article.img}`}
          alt={article.title}
        />
        <h1 className="article__title">{article.title}</h1>
        <div className="article__text">{article.text}</div>
        <button
          className="article-list__btn btn__blue btn"
          onClick={handleGoBlog}
        >
          К выбору темы {article.categoryName}
        </button>
      </div>
    </div>
  );
};

export default Article;
