import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHelmet from "../PageHelmet";

import productDatabases from "../../utils/productDatabases";
import { cardsGoal } from "../../utils/cardsGoal";

const ChooseGoal = ({
  headerHeight,
  handleGoGoal,
  handleGoStore,
  handleGoBack,
}) => {
  const { goal } = useParams();
  const navigate = useNavigate();

  if (!goal) {
    return <h1>Ошибка: цель не указана!</h1>;
  }

  // 🟢 Находим объект в cardsGoal по английскому purpose
  const card = cardsGoal.find((c) => c.purpose === goal);

  // Устанавливаем название цели (русское) или дефолтное значение
  const formattedGoal = card ? card.goal : "Цель не найдена";

  // Фильтруем продукты по цели
  const filteredProducts = Object.values(productDatabases)
    .flatMap((db) => Object.entries(db))
    .map(([key, product]) => ({ id: key, ...product }))
    .filter((product) =>
      product.purposes?.some(
        (p) => p.trim().toLowerCase() === formattedGoal.trim().toLowerCase()
      )
    );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleProductClick = (productId, productKey) => {
    navigate(`/products/${productId}/${productKey}`);
  };

  return (
    <div className="choose-taste" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title={`Продукты Fit and Joy для ${formattedGoal}`}
        description={`Найдите лучшие продукты Fit and Joy для достижения вашей цели: ${formattedGoal}. Выбирайте из ассортимента протеиновых батончиков, напитков, витаминов и других полезных товаров.`}
        keywords={`${formattedGoal}, спортивное питание, БАДы, витамины, протеин, энергия, здоровье, детокс`}
        canonicalUrl={`https://usafitandjoy.com/goals/${goal}/`}
      />

      <div className="choose-taste__wrapper">
        <h1 className="choose-taste__title">Цель: {formattedGoal}</h1>
        <div className="about-us__button-block">
          <div className="choose-taste__btn-group">
            <button
              className="btn btn__blue"
              type="button"
              onClick={handleGoGoal}
            >
              Все цели
            </button>
            <button
              className="btn btn__violet"
              type="button"
              onClick={handleGoBack}
            >
              Назад
            </button>
          </div>
          <button
            className="btn btn__blue"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <p className="choose-taste__description">
          Найдите лучшие продукты для достижения вашей цели: {formattedGoal}.
          Выбирайте из ассортимента протеиновых батончиков, напитков, витаминов
          и других полезных товаров.
        </p>

        <div className="choose-taste__block-container">
          <ul className="choose-taste__list">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => {
                // Получаем `productId`
                const productId = Object.keys(productDatabases).find(
                  (category) =>
                    Object.keys(productDatabases[category]).includes(product.id)
                );

                if (!productId) {
                  return null;
                }

                // Получаем `productKey`
                const productKey = Object.keys(
                  productDatabases[productId]
                ).find((key) => key === product.id);

                if (!productKey) {
                  return null;
                }

                return (
                  <li
                    key={index}
                    className="choose-taste__item"
                    onClick={() => handleProductClick(productId, productKey)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/products/${product.img}`}
                      alt={product.title}
                      className="choose-taste__image"
                    />
                    <div className="choose-taste__product-content">
                      <h2 className="article-products__card-title">
                        {product.title}
                      </h2>
                      <span className="article-products__card-text">
                        {product.textPurpose}
                      </span>
                    </div>
                  </li>
                );
              })
            ) : (
              <p className="article-products__card-title">
                К сожалению, у нас пока нет продуктов для этой цели.
              </p>
            )}
          </ul>
        </div>

        <div className="about-us__button-block">
          <button
            className="btn btn__blue"
            type="button"
            onClick={handleGoGoal}
          >
            Все цели
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseGoal;
