import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./NavTab.css";
import Dropdown from "../Dropdown/Dropdown";

import closeIcon from "../../images/closeIcon.svg";

const NavTab = ({ handleBurgerButtonClick, isMobile }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const navRef = useRef(null);
  const navigate = useNavigate();
  const navItems = [
    {
      label: "Наши продукты",
      path: "/products/",
      id: "productsnews",
      content: [
        { item: "Протеиновые батончики", path: "/products/protein-bars/" },
        { item: "Супы", path: "/products/protein-soup/" },
        { item: "Протеиновый коктейль", path: "/products/slim-effect/" },
        { item: "Детокс", path: "/products/detox/" },
        { item: "Витамины и минералы", path: "/products/vitamins/" },
        { item: "Жиросжигатели", path: "/products/keto-booster/" },
        { item: "Коллаген", path: "/products/collagen/" },
        { item: "Кокосовые батончики", path: "/products/coconut-bars/" },
        { item: "Ореховые батончики", path: "/products/nut-bars/" },
      ],
    },
    {
      label: "Вкусы",
      path: "/tastes/",
    },
    {
      label: "Цели",
      path: "/goals/",
    },
    {
      label: "Блог",
      path: "/blog/",
      id: "blog",
      content: [
        { item: "Здоровье", path: "/health-blog/", id: "health" },
        { item: "Питание", path: "/nutrition/", id: "nutrition" },
        { item: "Тренировки", path: "/training/", id: "training" },
        {
          item: "Калькулятор BMI-индекса",
          path: "/bmi-calculator/",
          id: "bmi-calculator",
        },
      ],
    },
    {
      label: "Где купить",
      path: "/buy/",
      id: "store",
    },
    {
      label: "Центр помощи",
      path: "/faq/",
      id: "faq",
      content: [
        { item: "Общие вопросы", id: "general-questions" },
        { item: "Пищевая продукция", id: "food-products" },
        { item: "Рекомендации по применению", id: "recommendations" },
        { item: "Контрактное производство", id: "contract-manufacturing" },
        { item: "Сотрудничество с блогерами", id: "influencer-collab" },
        { item: "Доставка", id: "delivery" },
      ],
    },
  ];

  useLayoutEffect(() => {
    const updateHeight = () => {
      const header = document.querySelector(".header");
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const handleMouseEnter = (index) => {
    if (!isMobile) {
      setIsDropdownOpen(true);
      setSelectedItem(navItems[index]);
    }
  };

  // Для десктопа: при уходе курсора скрываем
  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsDropdownOpen(false);
      setSelectedItem(null);
    }
  };

  const handleItemClick = (path, sectionId) => {
    setIsDropdownOpen(false);
    setSelectedItem(null);

    if (path) {
      if (window.location.pathname !== path) {
        navigate(path);

        if (
          sectionId &&
          !(
            path.includes("/health-blog/") ||
            path.includes("/nutrition/") ||
            path.includes("/training/") ||
            path.includes("/products/")
          )
        ) {
          setTimeout(() => waitForElementAndScroll(sectionId), 800);
        }
      } else if (sectionId) {
        // Находимся на нужной странице и хотим скроллиться
        waitForElementAndScroll(sectionId);
      }
    } else if (sectionId) {
      scrollToSection(sectionId);
    }
    if (isMobile && typeof handleBurgerButtonClick === "function") {
      handleBurgerButtonClick();
    }
  };

  const waitForElementAndScroll = (sectionId, attempts = 10) => {
    const element = document.getElementById(sectionId);

    if (element) {
      scrollToSection(sectionId);
    } else if (attempts > 0) {
      setTimeout(() => waitForElementAndScroll(sectionId, attempts - 1), 300);
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementOffset =
        element.getBoundingClientRect().top + window.scrollY - headerHeight;

      window.scrollTo({
        top: elementOffset,
        behavior: "smooth",
      });
    }
  };
  

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMobile && navRef.current && !navRef.current.contains(e.target)) {
        handleBurgerButtonClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, handleBurgerButtonClick]);

  return (
    <nav
      ref={navRef}
      className={`nav-tab ${isMobile ? "nav-tab--mobile" : "nav-tab--desktop"}`}
      onMouseLeave={handleMouseLeave}
    >
      <ul
        className={`nav-tab__list ${isMobile ? "nav-tab__list--mobile" : ""}`}
      >
        {navItems.map((item, index) => {
          const hasContent =
            Array.isArray(item.content) && item.content.length > 0;
          const isActive = selectedItem?.label === item.label && isDropdownOpen;

          return (
            <li
              key={index}
              className="nav-tab__item"
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <button
                type="button"
                className="nav-tab__link"
                onClick={() => {
                  const hasContent =
                    Array.isArray(item.content) && item.content.length > 0;

                  if (isMobile && hasContent) {
                    navigate(item.path);
                    setIsDropdownOpen(true);
                    setSelectedItem(item);
                  } else {
                    handleItemClick(item.path);
                  }
                }}
              >
                {item.label}
              </button>

              {hasContent && isActive && (
                <Dropdown
                  content={item.content}
                  onItemClick={(sectionId, subPath) =>
                    handleItemClick(subPath || item.path, sectionId)
                  }
                  onMouseLeave={handleMouseLeave}
                  handleBurgerButtonClick={handleBurgerButtonClick}
                  isMobile={isMobile}
                />
              )}
            </li>
          );
        })}
      </ul>

      <button
        className={`${isMobile ? "nav-tab__close-burger" : "nav-tab__hidden"}`}
      >
        <img
          src={closeIcon}
          alt="Иконка закрытия бургер меню"
          onClick={handleBurgerButtonClick}
        />
      </button>
    </nav>
  );
};

export default NavTab;
