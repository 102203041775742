import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { FaArrowUp } from "react-icons/fa";

import useHeaderHeight from "../../hooks/useHeaderHeight";
import useHighlight from "../../hooks/useHighlight";

import Main from "../Main/Main";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import About from "../About/About";
import Store from "../Store/Store";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import FAQ from "../FAQ/FAQ";
import Blog from "../Blog/Blog";
import CategoryBlog from "../CategoryBlog/CategoryBlog";
import Goal from "../Goal/Goal";
import Article from "../Article/Article";
import Products from "../Products/Products";
import ArticleProducts from "../ArticleProducts/ArticleProducts";
import ProductDetail from "../ProductDetail/ProductDetail";
import Tastes from "../Tastes/Tastes";
import ChooseTaste from "../ChooseTaste/ChooseTaste";
import ChooseGoal from "../ChooseGoal/ChooseGoal";
import CookieConsent from "../CookieConsent/CookieConsent";
import NoAccess from "../NoAccess/NoAccess";
import InfoAboutCookies from "../InfoAboutCookies/InfoAboutCookies";
import Podarok from "../Podarok/Podarok";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import BMICalculatorPage from "../BMICalculatorPage/BMICalculatorPage";
import Contacts from "../Contacts/Contacts";
import ContractManufacturing from "../ContractManufacturing/ContractManufacturing";

const phoneUserNameJF = "fitandjoy";
const phoneUserNameSupport = "loveourclients";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotFoundPage, setIsNotFoundPage] = useState(false);

  const [cookieConsent, setCookieConsent] = useState(null);
  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true);

  const [currentComponent, setCurrentComponent] = useState(null);

  const [showScrollTop, setShowScrollTop] = useState(false);

  // Следим за прокруткой и обновляем состояние
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Функция для прокрутки наверх
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isHighlightPage =
    ["/products/", "/blog/", "/search/", "/tastes/", "/goals/"].some((path) =>
      location.pathname.startsWith(path)
    ) ||
    location.pathname.match(/^\/products\/[^/]+\/?$/) ||
    location.pathname.match(/^\/products\/[^/]+\/[^/]+\/?$/) ||
    location.pathname.match(/^\/[a-zA-Z-]+\/[^/]+\/?$/);

  // const { highlightText, clearHighlights } = useHighlight(isHighlightPage);
  useHighlight(isHighlightPage);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    const allowPrivacyPolicy = localStorage.getItem("allowPrivacyPolicy");
    if (consent === "declined" && !allowPrivacyPolicy) {
      navigate("/no-access/");
    } else {
      setCookieConsent(consent);
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const { totalHeight: headerHeight, isMobile } = useHeaderHeight();
  const isMobile = windowWidth < 769;
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    document.body.setAttribute("data-page-url", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const validPaths = [
      "/",
      "/about/",
      "/buy/",
      "/podarok/",
      "/privacy-policy/",
      "/about-cookies/",
      "/no-access/",
      "/goal/",
      "/products/",
      "/terms-and-conditions/",
      "/faq/",
      "/blog/",
      "/health-blog/",
      "/training/",
      "/nutrition/",
      "/tastes/",
      "/goals/",
      "/bmi-calculator/",
      "/contacts/",
      "/kontraktnoe-proizvodstvo/",
    ];

    const isValidPath = validPaths.some((path) =>
      location.pathname.startsWith(path)
    );

    const dynamicPaths = [
      "/:category/",
      "/:category/:articleId/",
      "/tastes/:taste/",
      "/products/:productId/",
      "/products/:productId/:productKey/",
      "/goals/:goal/",
    ];

    const isDynamicPath = dynamicPaths.some((pattern) => {
      const pathParts = pattern.split("/").filter(Boolean);
      const locationParts = location.pathname.split("/").filter(Boolean);
      return (
        pathParts.length === locationParts.length &&
        pathParts.every(
          (part, index) => part.startsWith(":") || part === locationParts[index]
        )
      );
    });

    // Проверяем, если это 404, но хедер все равно должен отображаться
    const isCategoryPage =
      location.pathname.startsWith("/blog/") ||
      location.pathname.startsWith("/health-blog/");

    setIsNotFoundPage(!(isValidPath || isDynamicPath || isCategoryPage));
  }, [location.pathname]);

  const handleGoProducts = () => {
    setCurrentComponent(null);
    navigate("/products/");
  };

  const handleGoBack = () => {
    setCurrentComponent(null);
    navigate(-1);
  };

  const handleGoMain = () => {
    setCurrentComponent(null);
    navigate("/");
  };

  const handleGoStore = () => {
    setCurrentComponent(null);
    navigate("/buy/");
  };

  const handleGoPodarok = () => {
    setCurrentComponent(null);
    navigate("/podarok/");
  };

  const handleGoBlog = () => {
    navigate("/blog/");
  };

  const handleGoTastes = () => {
    navigate("/tastes/");
  };

  const handleGoGoal = () => {
    navigate("/goals/");
  };

  const handleGoContacts = () => {
    navigate("/contacts/");
  };

  const handleGoContractManufacturing = () => {
    navigate("/kontraktnoe-proizvodstvo/");
  };

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy/");
  };

  const handleTelegramClickFJ = () => {
    const telegramUrl = `https://t.me/${phoneUserNameJF}`;
    window.open(telegramUrl, "_blank");
  };

  const handleTelegramClickSupport = () => {
    const telegramUrl = `https://t.me/${phoneUserNameSupport}`;
    window.open(telegramUrl, "_blank");
  };

  const handleVkClick = () => {
    window.open("https://vk.com/usafitandjoy", "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:loveourclients@yandex.ru`;
  };

  const openComponent = (Component) => {
    setCurrentComponent(
      <Component
        handleGoMain={handleGoMain}
        handleGoBack={handleGoBack}
        headerHeight={headerHeight}
      />
    );
  };
  //Проверяем поддержку  Window Controls Overlay API:
  useEffect(() => {
    if ("windowControlsOverlay" in navigator) {
      const updateTitlebarHeight = () => {
        const titlebarRect =
          navigator.windowControlsOverlay.getTitlebarAreaRect();
        document.documentElement.style.setProperty(
          "--titlebar-height",
          `${titlebarRect.height}px`
        );
      };

      navigator.windowControlsOverlay.addEventListener(
        "geometrychange",
        updateTitlebarHeight
      );
      updateTitlebarHeight();

      return () => {
        navigator.windowControlsOverlay.removeEventListener(
          "geometrychange",
          updateTitlebarHeight
        );
      };
    }
  }, []);

  return (
    <div className="app">
      {!isNotFoundPage && (
        <Header
          isMobile={isMobile}
          handleGoMain={handleGoMain}
          headerHeight={headerHeight}
          handleGoPodarok={handleGoPodarok}
          handleTelegramClickFJ={handleTelegramClickFJ}
          handleVkClick={handleVkClick}
        />
      )}

      {currentComponent ? (
        currentComponent
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <Main
                headerHeight={headerHeight}
                isMobile={isMobile}
                openComponent={openComponent}
                handleGoPodarok={handleGoPodarok}
                handleTelegramClickFJ={handleTelegramClickFJ}
                handleVkClick={handleVkClick}
                handleGoTastes={handleGoTastes}
                handleGoGoal={handleGoGoal}
                handleGoStore={handleGoStore}
                showScrollTop={showScrollTop}
                handleScrollTop={handleScrollTop}
                handleGoContractManufacturing={handleGoContractManufacturing}
              />
            }
          />

          <Route
            path="*"
            element={
              <NotFoundPage
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleGoMain={handleGoMain}
              />
            }
          />
          <Route
            path="/about/"
            element={
              <About
                headerHeight={headerHeight}
                handleGoMain={handleGoMain}
                handleGoStore={handleGoStore}
                handleGoPodarok={handleGoPodarok}
                handleTelegramClickFJ={handleTelegramClickFJ}
                handleTelegramClickSupport={handleTelegramClickSupport}
                handleVkClick={handleVkClick}
                handleGoContractManufacturing={handleGoContractManufacturing}
              />
            }
          />
          <Route
            path="/buy/"
            element={
              <Store
                headerHeight={headerHeight}
                handleGoPodarok={handleGoPodarok}
                handleGoMain={handleGoMain}
              />
            }
          />

          <Route
            path="/faq/"
            element={
              <FAQ
                headerHeight={headerHeight}
                handleTelegramClickSupport={handleTelegramClickSupport}
              />
            }
          />
          <Route
            path="/blog/"
            element={
              <Blog
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleGoMain={handleGoMain}
              />
            }
          />

          <Route
            path="/bmi-calculator/"
            element={
              <BMICalculatorPage
                headerHeight={headerHeight}
                handleGoStore={handleGoStore}
                handleGoMain={handleGoMain}
                handleTelegramClickFJ={handleTelegramClickFJ}
                handleVkClick={handleVkClick}
              />
            }
          />

          <Route
            path="/:category/"
            element={
              <CategoryBlog
                handleGoBlog={handleGoBlog}
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
              />
            }
          />
          <Route
            path="/:category/:articleId/"
            element={
              <Article
                handleGoBlog={handleGoBlog}
                headerHeight={headerHeight}
              />
            }
          />
          <Route
            path="/tastes/"
            element={
              <Tastes
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleGoMain={handleGoMain}
              />
            }
          />
          <Route
            path="/tastes/:taste/"
            element={
              <ChooseTaste
                headerHeight={headerHeight}
                handleGoStore={handleGoStore}
                handleGoTastes={handleGoTastes}
                handleGoBack={handleGoBack}
              />
            }
          />
          <Route
            path="/podarok/"
            element={
              <Podarok
                isMobile={isMobile}
                headerHeight={headerHeight}
                handleGoMain={handleGoMain}
                handleGoStore={handleGoStore}
                handleTelegramClickSupport={handleTelegramClickSupport}
                handleTelegramClickJF={handleTelegramClickFJ}
                handleVkClick={handleVkClick}
                handleEmailClick={handleEmailClick}
              />
            }
          />
          <Route
            path="/products/"
            element={<Products headerHeight={headerHeight} />}
          />

          <Route
            path="/products/:productId/"
            element={
              <ArticleProducts
                headerHeight={headerHeight}
                handleGoProducts={handleGoProducts}
                handleGoBack={handleGoBack}
              />
            }
          />

          <Route
            path="/products/:productId/:productKey/"
            element={
              <ProductDetail
                headerHeight={headerHeight}
                handleGoProducts={handleGoProducts}
              />
            }
          />

          <Route
            path="/goals/"
            element={
              <Goal
                headerHeight={headerHeight}
                handleGoMain={handleGoMain}
                handleGoBack={handleGoBack}
              />
            }
          />

          <Route
            path="/goals/:goal/"
            element={
              <ChooseGoal
                headerHeight={headerHeight}
                handleGoGoal={handleGoGoal}
                handleGoStore={handleGoStore}
              />
            }
          />
          <Route
            path="/contacts/"
            element={
              <Contacts
                handleGoMain={handleGoMain}
                headerHeight={headerHeight}
                handleGoStore={handleGoStore}
                handleGoPodarok={handleGoPodarok}
                handleTelegramClickFJ={handleTelegramClickFJ}
                handleTelegramClickSupport={handleTelegramClickSupport}
                handleVkClick={handleVkClick}
              />
            }
          />
          <Route
            path="/kontraktnoe-proizvodstvo/"
            element={
              <ContractManufacturing
                handleGoMain={handleGoMain}
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleTelegramClickSupport={handleTelegramClickSupport}
              />
            }
          />
          <Route
            path="/privacy-policy/"
            element={
              <PrivacyPolicy
                handleGoBack={handleGoBack}
                handleGoMain={handleGoMain}
                headerHeight={headerHeight}
                isMobile={isMobile}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />
          <Route
            path="/about-cookies/"
            element={
              <InfoAboutCookies
                headerHeight={headerHeight}
                setCookieConsent={setCookieConsent}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />

          <Route
            path="/no-access/"
            element={
              <NoAccess
                headerHeight={headerHeight}
                setCookieConsent={setCookieConsent}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />

          <Route
            path="/terms-and-conditions/"
            element={
              <TermsAndConditions
                headerHeight={headerHeight}
                isMobile={isMobile}
                handleGoBack={handleGoBack}
                handlePrivacyPolicyClick={handlePrivacyPolicyClick}
              />
            }
          />
        </Routes>
      )}

      {!isNotFoundPage && (
        <Footer
          handleGoMain={handleGoMain}
          handleGoPodarok={handleGoPodarok}
          handleTelegramClickJF={handleTelegramClickFJ}
          handleTelegramClickSupport={handleTelegramClickSupport}
          handleVkClick={handleVkClick}
          handleGoStore={handleGoStore}
          handleGoContacts={handleGoContacts}
          handleGoContractManufacturing={handleGoContractManufacturing}
          handlePrivacyPolicyClick={handlePrivacyPolicyClick}
        />
      )}
      {cookieConsent !== "accepted" && isCookieConsentVisible && (
        <CookieConsent setCookieConsent={setCookieConsent} />
      )}
      {showScrollTop && (
        <button className="scroll-top-btn" onClick={handleScrollTop}>
          <FaArrowUp />
        </button>
      )}
    </div>
  );
};

export default App;
