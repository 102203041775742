export const articlesProducts = {
    'protein-bars': {
        title: 'Протеиновые батончики',
        content: (
            <>
                <p>Протеиновые батончики от Fit and Joy — это вкусный и удобный способ увеличить потребление белка без лишнего сахара.</p>
                <p>Они подходят для быстрого перекуса в течение дня, помогают восстановить мышцы после тренировки и обеспечивают организм энергией.</p>
                <p>Натуральный состав, отличные вкусовые качества и высокая пищевая ценность делают их идеальным выбором для активных людей.</p>
            </>
        ),
        text: 'Протеиновые батончики Fit and Joy — это вкусный и полезный перекус без сахара, который поддерживает вашу энергию и способствует восстановлению мышц.',
        img: 'protein-bars.webp',
        card: 'proteinBarsProducts',
    },
    'protein-soup': {
        title: 'Протеиновые супы',
        content: (
            <>
                <p>Протеиновые супы Fit and Joy — это удобное и сытное блюдо для поддержания баланса белков в рационе.</p>
                <p>Легкие в приготовлении, они подходят как для обеда, так и для перекуса, обеспечивая организм важными питательными веществами.</p>
                <p>Натуральные ингредиенты и отсутствие искусственных добавок делают их полезным выбором для правильного питания.</p>
            </>
        ),
        text: 'Протеиновые супы Fit and Joy — это питательное и полезное блюдо, содержащее необходимое количество белка для активного образа жизни.',
        img: 'soup.webp',
        card: 'proteinSoupProducts',
    },
    'slim-effect': {
        title: 'Протеиновый коктейль Slim Effect',
        content: (
            <>
                <p>Фитококтейли Slim Effect от Fit and Joy — это полезный и низкокалорийный способ поддерживать баланс белка и микроэлементов.</p>
                <p>Эти коктейли помогают контролировать аппетит, насыщают организм важными витаминами и минералами, а также способствуют восстановлению после физических нагрузок.</p>
                <p>Без добавленного сахара, богатые растительными экстрактами и белками, они подходят для тех, кто хочет следить за фигурой.</p>
            </>
        ),
        text: 'Slim Effect — это идеальный фитококтейль для поддержания формы, насыщения организма белком и контроля аппетита.',
        img: 'protein-shakes.webp',
        card: 'proteinSlimEffectProducts',
    },
    'detox': {
        title: 'Комплекс "Детокс"',
        content: (
            <>
                <p>Комплекс "Детокс" от Fit and Joy помогает мягко очистить организм от токсинов и лишней жидкости.</p>
                <p>В составе натуральные компоненты, поддерживающие работу печени, почек и желудочно-кишечного тракта.</p>
                <p>Детокс способствует улучшению обмена веществ, укрепляет иммунитет и дарит ощущение легкости.</p>
            </>
        ),
        text: 'Комплекс "Детокс" Fit and Joy поддерживает естественное очищение организма, улучшает обмен веществ и укрепляет иммунную систему.',
        img: 'detox.webp',
        card: 'detoxProducts',
    },
    'vitamins': {
        title: 'Витаминные комплексы',
        content: (
            <>
                <p>Витаминные комплексы Fit and Joy — это источник важных микроэлементов для поддержания здоровья и жизненной энергии.</p>
                <p>Они помогают укрепить иммунитет, улучшить состояние кожи, волос и ногтей, а также способствуют нормальному обмену веществ.</p>
                <p>Идеально подходят для людей с активным образом жизни, обеспечивая организм всеми необходимыми витаминами и минералами.</p>
            </>
        ),
        text: 'Витаминные комплексы Fit and Joy обеспечивают организм необходимыми микроэлементами, укрепляют иммунитет и поддерживают общее здоровье.',
        img: 'vitamins.webp',
        card: 'vitaminsProducts',
    },
    'keto-booster': {
        title: 'Жиросжигатели',
        content: (
            <>
                <p>Жиросжигатели Fit and Joy — это мощный комплекс активных компонентов, способствующих ускорению обмена веществ.</p>
                <p>Они помогают сжигать жир, повышать уровень энергии и улучшать выносливость.</p>
                <p>Формула разработана с учетом дневной и ночной активности, обеспечивая круглосуточное действие.</p>
            </>
        ),
        text: 'Жиросжигатели Fit and Joy ускоряют метаболизм, способствуют сжиганию жира и поддерживают высокий уровень энергии.',
        img: 'keto-booster.webp',
        card: 'ketoBoosterProducts',
    },
    'collagen': {
        title: 'Коллаген',
        content: (
            <>
                <p>Коллаген Fit and Joy — это источник молодости и здоровья кожи, суставов, волос и ногтей.</p>
                <p>С возрастом уровень коллагена в организме снижается, что приводит к появлению морщин, потере упругости кожи и ослаблению суставов.</p>
                <p>Регулярное употребление коллагеновых добавок помогает восстановить баланс, поддерживать эластичность тканей и улучшать общее состояние организма.</p>
            </>
        ),
        text: 'Коллаген Fit and Joy — это незаменимый компонент для сохранения молодости кожи, укрепления суставов и улучшения здоровья волос и ногтей.',
        img: 'collagen.webp',
        card: 'collagenProducts',
    },
    'coconut-bars': {
        title: 'Кокосовые батончики',
        content: (
            <>
                <p>Кокосовые протеиновые батончики Fit and Joy — это сочетание натурального кокоса и высокого содержания белка.</p>
                <p>Они не содержат сахара и идеально подходят для тех, кто следит за фигурой и ищет полезный перекус.</p>
                <p>Легкие, вкусные и питательные, эти батончики помогут восполнить энергию в любое время дня.</p>
            </>
        ),
        text: 'Кокосовые протеиновые батончики Fit and Joy — это полезный перекус с высоким содержанием белка и натуральным вкусом кокоса.',
        img: 'coconut-bars.webp',
        card: 'coconutBarsProducts',
    },
    'nut-bars': {
        title: 'Ореховые батончики',
        content: (
            <>
                <p>Ореховые протеиновые батончики Fit and Joy — это насыщенный вкус орехов в сочетании с высоким содержанием белка.</p>
                <p>Идеальный перекус для активных людей, помогает восстановить силы после тренировок и поддерживает энергию на целый день.</p>
                <p>Полезные жиры и белок в составе делают эти батончики вкусным и здоровым выбором.</p>
            </>
        ),
        text: 'Ореховые протеиновые батончики Fit and Joy — это источник энергии и белка в удобном формате для перекуса.',
        img: 'nut-bars.webp',
        card: 'nutBarsProducts',
    }
};
