import React, { useState } from "react";
import "./MainReviews.css";

import arrowBig from "../../images/arrowBig.webp";

import { cardsMainReviewsData } from "../../utils/cardsMainReviewsData";

const MainReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % cardsMainReviewsData.length
      );
    } else if (direction === "prev") {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + cardsMainReviewsData.length) %
          cardsMainReviewsData.length
      );
    }
  };

  const handlePointClick = (index) => {
    setCurrentIndex(index);
  };

  const openModal = (image) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="main-reviews" id="main-reviews">
      <div className="main-reviews__container">
        <h1 className="main__subtitle">Отзывы наших покупателей</h1>
        <div className="main-reviews__wrapper">
          <div className="main-reviews__block">
            <img
              className="product-detail__arrow product-detail__left-arrow hover"
              src={arrowBig}
              alt="значок стрелки налево"
              onClick={() => handleArrowClick("prev")}
            />
            <div className="main-reviews__img">
              <img
                src={cardsMainReviewsData[currentIndex].img}
                alt={cardsMainReviewsData[currentIndex].alt}
                title="Нажми, чтобы посмотреть"
                onClick={() =>
                  openModal(cardsMainReviewsData[currentIndex].img)
                }
                style={{ cursor: "zoom-in" }}
              />
            </div>

            <img
              className="product-detail__arrow hover"
              src={arrowBig}
              alt="значок стрелки направо"
              onClick={() => handleArrowClick("next")}
            />
          </div>

          <div className="product-detail__indicators">
            {cardsMainReviewsData.map((review, index) => (
              <span
                key={index}
                className={`main-reviews__indicator ${
                  index === currentIndex ? "active" : ""
                }`}
                alt="значок точки"
                onClick={() => handlePointClick(index)}
              ></span>
            ))}
          </div>

          {isModalOpen && (
            <div className="modal-overlay" onClick={closeModal}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="modal-close" onClick={closeModal}>
                  &times;
                </span>
                <img
                  src={cardsMainReviewsData[currentIndex].img}
                  alt={cardsMainReviewsData[currentIndex].alt}
                  className="modal-img"
                />
              </div>
            </div>
          )}
          <h2 className="main-reviews__author">
            {cardsMainReviewsData[currentIndex].text}
          </h2>
          <div className="main-reviews__content"></div>
        </div>
      </div>
    </section>
  );
};

export default MainReviews;
