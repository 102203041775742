export const cardsTastes = [
  { img: 'Strawberry.webp', alt: "клубники", tasteEng: "strawberry", name: "strawberry", nameRus: "клубника" },
  { img: 'Kiwi.webp', alt: "киви", tasteEng: "kiwi", name: "kiwi", nameRus: "киви" },
  { img: 'cherry.webp', alt: "вишни", tasteEng: "cherry", name: "cherry", nameRus: "вишня" },
  { img: 'Chocolate.webp', alt: "шоколада", tasteEng: "chocolate", name: "chocolate", nameRus: "шоколад" },
  { img: 'coffee.webp', alt: "кофе", tasteEng: "coffee", name: "coffee", nameRus: "кофе" },
  { img: 'raspberry.webp', alt: "малины", tasteEng: "raspberry", name: "raspberry", nameRus: "малина" },
  { img: 'lychee.webp', alt: "личи", tasteEng: "lychee", name: "lychee", nameRus: "личи" },
  { img: 'coconut.webp', alt: "кокоса", tasteEng: "coconut", name: "coconut", nameRus: "кокос" },
  { img: 'Mango.webp', alt: "манго", tasteEng: "mango", name: "mango", nameRus: "манго" },
  { img: 'Pineapple.webp', alt: "ананаса", tasteEng: "pineapple", name: "pineapple", nameRus: "ананас" },
  { img: 'nuts.webp', alt: "орехов", tasteEng: "nut", name: "nut", nameRus: "орех" },
  { img: 'limon.webp', alt: "лимона", tasteEng: "limon", name: "limon", nameRus: "лимон" },
];



