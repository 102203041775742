import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
import instagram from "../../images/insagram.png";

const Footer = ({
  handleGoMain,
  handleGoPodarok,
  handleTelegramClickJF,
  handleVkClick,
  handleTelegramClickSupport,
  handleGoStore,
  handleGoContacts,
  handleGoContractManufacturing,
  handlePrivacyPolicyClick,
}) => {
  const todaytYear = new Date().getFullYear();

  const navigate = useNavigate();

  const handleCoocieClick = () => {
    navigate("/about-cookies/");
  };

  const handleGoAbout = () => {
    navigate("/about/");
  };

  const handleGoBlog = () => {
    navigate("/blog/");
  };

  const handleGoProducts = () => {
    navigate("/products/");
  };

  const handleGoCollagen = () => {
    navigate("/products/collagen/");
  };

  const handleGoProteinBars = () => {
    navigate("/products/protein-bars/");
  };

  const handleGoFatBurners = () => {
    navigate("/products/fat-burners/");
  };

  const handleGoDetox = () => {
    navigate("/products/detox/");
  };

  const handleGoVitamins = () => {
    navigate("/products/vitamins/");
  };

  const handleGoCalculator = () => {
    navigate("/bmi-calculator/");
  };

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__block">
          <ul className="footer__list">
            <li className="footer__item">
              <ul
                className="footer__item-title hover"
                onClick={handleGoProducts}
              >
                каталог Fit and Joy
              </ul>

              <ul>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoCollagen}
                  >
                    коллаген
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoProteinBars}
                  >
                    протеиновые батончики
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle">протеиновые КОКТЕЙЛИ</p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoFatBurners}
                  >
                    жиросжигатели
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoDetox}>
                    детокс
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoVitamins}
                  >
                    витамины и минералы
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle">супы</p>
                </li>
              </ul>
            </li>
            <li className="footer__item">
              <ul className="footer__item-title">Ссылки</ul>
              <ul>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoMain}>
                    главная
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoBlog}>
                    блог
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoAbout}>
                    о нас
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoStore}>
                    где купить
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoCalculator}
                  >
                    Калькулятор BMI-индекса
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoContacts}
                  >
                    Контакты
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoContractManufacturing}
                  >
                    Контрактное производство
                  </p>
                </li>
              </ul>
            </li>
            <li className="footer__item">
              <ul className="footer__item-title">Поддержка</ul>
              <ul>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoPodarok}
                  >
                    подарок от команды
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handlePrivacyPolicyClick}
                  >
                    политика конфиденциальности
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleCoocieClick}
                  >
                    файлы куки
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle footer__support"
                    onClick={handleTelegramClickSupport}
                  >
                    Приглашаем контент-мейкеров к сотрудничеству! Договоримся на
                    выгодных условиях.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="footer__list-contact">
            <div>
              <li className="footer__telegram hover">
                <img
                  src={`${process.env.PUBLIC_URL}/images/telegram.webp`}
                  alt="Иконка телеграмм"
                  onClick={handleTelegramClickJF}
                />
              </li>
              <li className="footer__telegram hover">
                <img
                  src={`${process.env.PUBLIC_URL}/images/vk.webp`}
                  alt="Иконка Вконтакте"
                  onClick={handleVkClick}
                />
              </li>
            </div>
            <li className="footer__block-instagram hover">
              <div className="footer__instagram hover">
                <a
                  href="https://www.instagram.com/usafitandjoy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="логотип сайта JAPAN FORMULA" />
                </a>
                <p className="footer__asterisk">
                  *Признана экстремистской организацией и запрещена на
                  территории РФ.
                </p>
              </div>
            </li>
          </ul>
        </div>

        <hr />
        <div className="footer__block-info">
          <h1 className="footer__title">
            Fit and Joy, Inc. All rights reserved
          </h1>
          <div className="footer__copyright">{todaytYear}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
